import { axios, transformFullResponseBase } from "@/lib/axios"
import { queryClient } from "@/lib/react-query"
import { appQueryKeys } from "@/features/app/api/queryKeys/appQueryKeys"
import { useMutation } from "@tanstack/react-query"
import { PatchProfileParametersT, PatchProfileResponseT } from "@/features/users/types/userQueriesTypes"
import { toRequestBoolean } from "@/utils/requestDataConverters"
import { profileQueryKeys } from "@/features/users/api/keys/profileQueryKeys"
import { ProfileT } from "@/features/users/types/profileTypes"
import { AppDataT } from "@/features/app/types/queries"
import { fakeT } from "@/utils/fakeTranslation"
import { transformProfile } from "@/features/users/api/transformers/transformProfile"

export const patchProfile = async (parameters: PatchProfileParametersT): Promise<PatchProfileResponseT> => {
  return axios.patch(
    "/profile",
    {
      ...parameters,
      ...(parameters.tosAccepted ? { tosAccepted: toRequestBoolean(parameters.tosAccepted) } : {}),
    },
    {
      transformResponse: (...args) =>
        transformFullResponseBase(...args, "profile", transformProfile, fakeT("error.profile.patch")),
    },
  )
}

export const useUpdateProfileQ = () => {
  const updateAppDataTosAcceptance = (tosAccepted?: boolean): AppDataT | undefined => {
    let oldAppData: AppDataT | undefined
    if (tosAccepted !== undefined) {
      oldAppData = queryClient.getQueryData<AppDataT>(appQueryKeys.appData)
      queryClient.setQueryData<AppDataT>(appQueryKeys.appData, (oldData) =>
        oldData
          ? {
              ...oldData,
              user: {
                ...oldData.user,
                tosAccepted: tosAccepted!,
              },
            }
          : oldData,
      )
    }

    return oldAppData
  }

  const query = useMutation({
    mutationFn: async (params: PatchProfileParametersT) => patchProfile(params),
    onMutate: (parameters) => {
      const oldProfile = queryClient.getQueryData<ProfileT>(profileQueryKeys.profile)
      queryClient.setQueryData(profileQueryKeys.profile, (oldProfile) =>
        oldProfile
          ? {
              ...oldProfile,
              ...parameters,
            }
          : oldProfile,
      )

      const oldAppData = updateAppDataTosAcceptance(parameters.tosAccepted)

      return { oldProfile, oldAppData }
    },
    onSuccess: (newProfile) => {
      queryClient.setQueryData(profileQueryKeys.profile, newProfile)
      updateAppDataTosAcceptance(newProfile.tosAccepted)
    },
    onError: (_, __, context) => {
      if (context?.oldProfile) queryClient.setQueryData(profileQueryKeys.profile, context.oldProfile)
      if (context?.oldAppData) queryClient.setQueryData(appQueryKeys.appData, context.oldAppData)
    },
  })

  return {
    ...query,
    acceptToS: () => query.mutate({ tosAccepted: true }),
    updateProfile: query.mutate,
    updateProfileAsync: query.mutateAsync,
  }
  // await axios.post("/profile", { tosAccepted: 1 })
  // await queryClient.invalidateQueries(appQueryKeys.appData)
}
