import { axios } from "@/lib/axios"
import { useQuery } from "@tanstack/react-query"
import { GetToSResponseT, UseToSReturnT } from "@/features/app/types/queries"

export function getToS(): Promise<GetToSResponseT> {
  return axios.get("/tos")
}

export function useToS(): UseToSReturnT {
  return useQuery<GetToSResponseT>({
    queryKey: ["tos"],
    queryFn: getToS,
    staleTime: 24 * 60 * 60 * 1000, // 24 hours
  })
}
