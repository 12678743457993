import { faker } from "@faker-js/faker/locale/pl"
import { RawUserT } from "@/features/users/types/userTypes"
import { RawStudentT } from "@/features/students/types/studentsQueriesTypes"
import { EMPTY_USER } from "@/features/users/tests/mock/generators/userMockGenerator"
import { getNextId } from "@/tests/utils/getNextId"

export const generateMockStudent = ({ user }: { user?: RawUserT }, overrides?: Partial<RawStudentT>): RawStudentT => {
  return {
    id: getNextId("student"),
    isAdult: faker.datatype.boolean(),
    isActive: faker.datatype.boolean(),
    user: user ?? EMPTY_USER,
    ...overrides,
  }
}
