import { useNavigate } from "react-router"
import { ROUTES } from "@/routes"
import { clearStoresCaches } from "@/utils/clearStoresCaches"

export const useLogout = () => {
  const navigate = useNavigate()

  return () => {
    clearStoresCaches()
    navigate(ROUTES.LOGOUT)
  }
}
