import { primaryKey } from "@mswjs/data"
import { Overrides } from "../generators"
import { faker } from "@faker-js/faker/locale/pl"
import dayjs from "dayjs"
import { RawGroupT } from "@/features/groups/types/queries"
import { getNextId } from "@/tests/utils/getNextId"

export const MAPPING_SPLIT_SYMBOL = "###"

export type GradesTypeModifierT = {
  "+"?: number
  "-"?: number
}

export type GroupModelT = {
  id: number
  data: string
}

export const groupModel = {
  id: primaryKey(Number),
  data: String,
}

const LANGUAGES = ["Angielski", "Francuski", "Hiszpański", "Niemiecki", "Rosyjski", "Włoski"]

// const STATUSES: GroupStatusT[] = [
//   { label: "Zajęcia przełożone", color: "#FF6600", type: "test" },
//   { label: "Zajęcia odwołane", color: "#009900", type: "test" },
//   { label: "Zajęcia odwołane na czas", color: "#0066FF", type: "test" },
//   { label: "Zajęcia odwołane za późno", color: "#FF9933", type: "test" },
//   { label: "Pomiń datę", color: "#999999", type: "test" },
// ]
//
// const FIELDS: FieldInfoT[] = [
//   { type_id: 1, type: "Temat", studentVisible: true },
//   { type_id: 2, type: "Praca domowa", studentVisible: true },
//   { type_id: 3, type: "Zadanie dodatkowe", studentVisible: true },
//   { type_id: 4, type: "Test", studentVisible: true },
//   { type_id: 5, type: "Notatki", studentVisible: false },
//   { type_id: 6, type: "Uwagi", studentVisible: false },
// ]

const LANGUAGE_IDS: Partial<{ [key: (typeof LANGUAGES)[number]]: number }> = {}

const getLanguageID = (language: (typeof LANGUAGES)[number]) => {
  LANGUAGE_IDS[language] = (LANGUAGE_IDS[language] ?? 0) + 1
  return `${language} ${LANGUAGE_IDS[language]} / ${dayjs().format("YYYY")}`
}

export function getModifiers(modifiers: GradesTypeModifierT) {
  if (modifiers && !modifiers["+"]) {
    return null
  } else {
    return modifiers
  }
}

export const groupGenerator = ({ students = [], minLength = 1, maxLength = 3 }, overrides?: Overrides): RawGroupT => {
  return {
    id: getNextId("group"),
    name: getLanguageID(faker.helpers.arrayElement(LANGUAGES)),
    startDate: faker.date.past().toISOString(),
    endDate: faker.date.future().toISOString(),
    schoolId: 1,
    students: faker.helpers.arrayElements(
      students,
      faker.number.int({
        min: minLength,
        max: maxLength,
      }),
    ),
    isHomeworkEnabled: faker.datatype.boolean(),
    isLessonDetailsPublishingEnabled: faker.datatype.boolean(),
    isPlanned: false,
    pointSystemTypeName: faker.helpers.arrayElement(["percent", "normal"]),
    ...overrides,
  }
}
