import { axios } from "@/lib/axios"
import { useMutation } from "@tanstack/react-query"
import { DeleteProfileAvatarResponseT, GetProfileAvatarResponseT } from "@/features/users/types/userQueriesTypes"
import { queryClient } from "@/lib/react-query"
import { profileQueryKeys } from "@/features/users/api/keys/profileQueryKeys"

export const deleteProfileAvatar = (): Promise<DeleteProfileAvatarResponseT> => {
  return axios.delete("/avatar", {
    // transformResponse: (data) => transformResponseBase(data, (data) => transformFullMessage(data.message)),
  })
}

export const useRemoveProfileAvatarQ = () => {
  const queryKey = profileQueryKeys.getAvatar()

  const mutation = useMutation({
    onSuccess: () => {
      const previousAvatar = queryClient.getQueryData(queryKey)
      queryClient.setQueryData<GetProfileAvatarResponseT>(queryKey, { image: undefined })

      return { previousAvatar }
    },
    mutationFn: () => deleteProfileAvatar(),
  })

  return {
    ...mutation,
    removeProfileAvatar: mutation.mutate,
    removeProfileAvatarAsync: mutation.mutateAsync,
  }
}
