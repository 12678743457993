import { styled, Tab, Tabs, TabsProps } from "@mui/material"
import { SIZES_PX } from "@/config/sizes"

export const AppBarTabs = ({ children, sx, ...props }: TabsProps) => (
  <Tabs
    sx={[
      {
        "&.MuiTabs-root .MuiTabs-flexContainer": {
          minHeight: {
            mobile: SIZES_PX.APP_BAR_HEIGHT_MOBILE,
            tablet: SIZES_PX.APP_BAR_HEIGHT,
          },
        },
        minHeight: { mobile: SIZES_PX.APP_BAR_HEIGHT_MOBILE, tablet: SIZES_PX.APP_BAR_HEIGHT },
      },
      ...(Array.isArray(sx) ? sx : [sx]),
    ]}
    {...props}
  >
    {children}
  </Tabs>
)

export const AppBarTab = styled(Tab)({
  minWidth: "64px",
})

AppBarTab.defaultProps = {
  iconPosition: "start",
}
