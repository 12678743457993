import "@/App.scss"
import { AppProvider } from "@/providers/AppProvider"
import { AppRoutes } from "@/routes"

import "@fontsource/lato/400.css"
import "@fontsource/lato/700.css"

function App() {
  return (
    <AppProvider>
      <AppRoutes />
    </AppProvider>
  )
}

export default App
