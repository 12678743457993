export const API_URL = import.meta.env.VITE_API_URL as string
export const LOGIN_URL = import.meta.env.VITE_LOGIN_URL as string
export const MOCK_API = import.meta.env.VITE_MOCK_API === "true"
export const MOCK_API_ONLY_PREDEFINED = import.meta.env.VITE_MOCK_API_PREDEFINED === "true"
export const LOCAL_LOCALE = import.meta.env.VITE_LOCAL_LOCALE === "true"
export const DEBUG = import.meta.env.VITE_DEBUG === "true"
export const AUTH = {
  username: import.meta.env.VITE_AUTH_USERNAME as string,
  password: import.meta.env.VITE_AUTH_PASSWORD as string,
}

export const LOCAL_API_VERSION = import.meta.env.VITE_API_VERSION as number
export const API_WEB_VERSION = import.meta.env.VITE_API_WEB_VERSION as number
export const APP_VERSION = import.meta.env.VITE_APP_VERSION as string

export const STORE_APP_ID = {
  ios: import.meta.env.VITE_APP_ID_IOS as string,
  android: import.meta.env.VITE_APP_ID_ANDROID as string,
}

export const IS_MOBILE_APP = import.meta.env.VITE_MOBILE === "true"
export const PLATFORM = typeof device !== "undefined" ? device.platform : "web"
