import { Avatar, Skeleton, Stack, styled, Typography } from "@mui/material"
import { useGetProfileAvatarQ } from "@/features/users/api/getProfileAvatar"

export const ProfileBarBox = styled(Stack)(({ theme }) => ({
  padding: theme.spacing(1.5),
  borderBottom: `solid 1px ${theme.palette.grey[50]}`,
}))

ProfileBarBox.defaultProps = {
  spacing: 1,
  direction: "row",
}

function ProfileBarSkeleton() {
  return (
    <ProfileBarBox>
      <Skeleton variant={"rounded"} sx={{ flexShrink: 0 }}>
        <Avatar variant="rounded" />
      </Skeleton>
      <Skeleton sx={{ ml: 2, flexGrow: 1, width: "100%", maxWidth: "none" }}>
        <Typography>Użytkownik</Typography>
      </Skeleton>
    </ProfileBarBox>
  )
}

export interface ProfileBarProps {
  firstname?: string
  lastname?: string
  username?: string
}

export function ProfileBar({ firstname, lastname, username }: ProfileBarProps) {
  const { avatar } = useGetProfileAvatarQ()

  if (!firstname || !lastname) {
    return <ProfileBarSkeleton />
  }
  const initials = firstname[0] + lastname[0]

  return (
    <ProfileBarBox>
      <Avatar variant="rounded">
        {avatar?.image ? <img src={avatar.image} alt="avatar" style={{ width: "100%" }} /> : initials}
      </Avatar>
      <Stack justifyContent="center" fontSize="14px">
        <Typography display={"flex"} alignItems={"center"} fontWeight={700} fontSize="1.1em">
          {firstname} {lastname}
        </Typography>
        <Typography variant="subtitle">{username}</Typography>
      </Stack>
    </ProfileBarBox>
  )
}
