import { primaryKey } from "@mswjs/data"

export type BaseModelT<T> =
  | {
      id: number
      initialized: false
      data: string
    }
  | {
      id: number
      initialized: true
      data: T
    }

export const baseModel = {
  id: primaryKey(Number),
  data: String,
}

export const serializeBaseModel = (model: BaseModelT<any>) => ({
  ...model,
  initialized: false,
  data: JSON.stringify(model.data),
})

export const initBaseModel = (model: BaseModelT<any>) => ({
  ...model,
  initialized: true,
  data: JSON.parse(model.data),
})

export const initBaseModels = (models: BaseModelT<any>[]) => models.map(initBaseModel)
