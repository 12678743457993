import { StoreTypeEnum } from "@/features/app/types/store-type"
import { proxy, subscribe, useSnapshot } from "valtio"
import { DatesT } from "@/components/Elements/LLDateRangePicker"
import { useGroupStore } from "@/features/groups/stores/group-store"
import dayjs from "@/lib/dayjs"
import { useGroupsDuration } from "@/features/groups/api/getGroups"
import mapValues from "lodash/mapValues"

export enum PredefinedRangesEnum {
  today = "today",
  lastMonth = "lastMonth",
  currentMonth = "currentMonth",
  nextMonth = "nextMonth",
  lastWeek = "lastWeek",
  thisWeek = "thisWeek",
  thisYear = "thisYear",
  classesDuration = "classesDuration",
}

const calculatePredefinedRange = (range: PredefinedRangesEnum): DatesT => {
  switch (range) {
    case PredefinedRangesEnum.lastMonth:
      return {
        from: dayjs().subtract(1, "month").startOf("month"),
        to: dayjs().subtract(1, "month").endOf("month"),
      }
    case PredefinedRangesEnum.currentMonth:
      return { from: dayjs().startOf("month"), to: dayjs().endOf("month") }
    case PredefinedRangesEnum.nextMonth:
      return {
        from: dayjs().add(1, "month").startOf("month"),
        to: dayjs().add(1, "month").endOf("month"),
      }
    case PredefinedRangesEnum.lastWeek:
      return {
        from: dayjs().subtract(1, "week").startOf("week"),
        to: dayjs().subtract(1, "week").endOf("week"),
      }
    case PredefinedRangesEnum.thisWeek:
      return { from: dayjs().startOf("week"), to: dayjs().endOf("week") }
    case PredefinedRangesEnum.thisYear:
      return { from: dayjs().startOf("year"), to: dayjs().endOf("year") }
    case PredefinedRangesEnum.today:
    default:
      return { from: dayjs(), to: dayjs() }
  }
}

export type DateRangeDataT = DatesT | PredefinedRangesEnum | undefined
export interface DateRangeTypeDataT {
  current: DateRangeDataT
}

const DATA_RANGE_STORE_TYPES = [StoreTypeEnum.Attendance, StoreTypeEnum.Classes] as const
export type DateRangeTypeT = (typeof DATA_RANGE_STORE_TYPES)[number]

export type DateRangeStoreT = Record<DateRangeTypeT, DateRangeTypeDataT>

const DEFAULT_STATE: DateRangeStoreT = {
  attendance: {
    current: undefined,
  },
  classes: {
    current: undefined,
  },
}

const storedData = localStorage.getItem("dateRangeStore")
let initialStore: DateRangeStoreT = { ...DEFAULT_STATE }

if (storedData) {
  try {
    initialStore = JSON.parse(storedData)
    for (const id of DATA_RANGE_STORE_TYPES) {
      if (!initialStore[id]) {
        initialStore[id] = DEFAULT_STATE[id]
      }
    }
  } catch (e) {
    initialStore = { ...DEFAULT_STATE }
  }
}

export const dateRangeStore = proxy<DateRangeStoreT>(initialStore)

subscribe(dateRangeStore, () => {
  localStorage.setItem("dateRangeStore", JSON.stringify(dateRangeStore))
})

export const clearDateRangeStore = () => {
  for (const id of DATA_RANGE_STORE_TYPES) {
    dateRangeStore[id] = DEFAULT_STATE[id]
  }
  localStorage.removeItem("dateRangeStore")
}

export function setDateRange(id: DateRangeTypeT, range: DatesT | PredefinedRangesEnum) {
  dateRangeStore[id].current = range
}

export function useDateRange(id: DateRangeTypeT): DatesT {
  const store = useSnapshot(dateRangeStore)
  const { group } = useGroupStore(id)
  const groupDate = useGroupsDuration({ groupId: group?.id })
  const range = store[id]?.current

  if (typeof range === "object") {
    return mapValues(range, (date) => {
      const dayjsDate = dayjs(date)
      return dayjsDate.isValid() ? dayjsDate : null
    })
  } else if (range === PredefinedRangesEnum.classesDuration) {
    return groupDate
  } else {
    return calculatePredefinedRange(range ?? PredefinedRangesEnum.today)
  }
}
