import { RawUserT, UserT } from "@/features/users/types/userTypes"
import { toString } from "@/utils/requestDataConverters"

export const transformUser = (rawUser: RawUserT): UserT => ({
  id: rawUser.id,
  firstName: rawUser.firstName ?? "",
  fullName: rawUser.fullname ?? "",
  lastName: rawUser.lastName ?? "",
  email: toString(rawUser.additionalData.email),
  phone: toString(rawUser.additionalData.phone),
})
