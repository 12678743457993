import { LoginAttemptT, RawLoginAttemptT } from "@/features/users/types/userTypes"
import { toBoolean, toDate, toString } from "@/utils/requestDataConverters"

export const transformLoginAttempt = (loginAttempt: RawLoginAttemptT): LoginAttemptT => ({
  id: loginAttempt.id,
  date: toDate(loginAttempt.date)!,
  ip: toString(loginAttempt.ip),
  wasSuccessful: toBoolean(loginAttempt.login_success),
})

export const transformLoginAttempts = (loginAttempts: RawLoginAttemptT[]): LoginAttemptT[] =>
  loginAttempts.map(transformLoginAttempt)
