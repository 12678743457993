import { useTranslation } from "react-i18next"
import { useCurrentProfileTab } from "@/features/users/hooks/useCurrentProfileTab"
import { ActionsTabsMainAppSubToolbar } from "@/components/Layout/ActionsTabsMainAppSubToolbar"
import { EditProfileTabsEnum } from "@/features/users/types/profileTypes"
import {
  UserLightIcon,
  KeyLightIcon,
  // ClockRotateLeftLightIcon,
  // GraduationCapLightIcon,
} from "@/components/Icons"
import React from "react"

export const EditProfileToolbar = () => {
  const { t } = useTranslation()
  const { currentTab, changeTab } = useCurrentProfileTab()

  return (
    <ActionsTabsMainAppSubToolbar
      tabs={[
        {
          value: EditProfileTabsEnum.profile,
          label: t("user.profile"),
          Icon: UserLightIcon,
        },
        {
          value: EditProfileTabsEnum.password,
          label: t("user.change_password"),
          Icon: KeyLightIcon,
        },
        // {
        //   value: EditProfileTabsEnum.logins,
        //   label: t("user.login_history"),
        //   Icon: ClockRotateLeftLightIcon,
        // },
        // {
        //   value: EditProfileTabsEnum.history,
        //   label: t("user.teacher_history"),
        //   Icon: GraduationCapLightIcon,
        // },
      ]}
      currentTab={currentTab}
      changeTab={changeTab}
    />
  )
}
