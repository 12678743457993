import storage from "@/utils/storage"
import { IS_CORDOVA } from "@/utils/IS_CORDOVA"
import { router } from "@/routes"
import { addPBDebug, addPBFirebaseToken, removePBFirebaseToken } from "@/utils/pbDebug"
import { axios } from "@/lib/axios"
import { AxiosError } from "axios"

const NOTIFICATION_ACTIONS: Record<string, string> = {
  "message.sent": "/messages?readMessage={id}",
  // class_cancelled: "/classes?classDetails={id}&classDetailsTab=details",
}

export const initFirebase = async () => {
  if (!IS_CORDOVA) return Promise.resolve()

  cordova.plugins.firebase.messaging.onBackgroundMessage((payload) => {
    const { event, objectId } = payload
    // TODO Remove debug
    addPBDebug({
      json: payload,
    })
    if (NOTIFICATION_ACTIONS[event]) {
      router.navigate(NOTIFICATION_ACTIONS[event].replace("{id}", objectId))
    }
  })
  cordova.plugins.firebase.messaging.onTokenRefresh(() => {
    getFirebaseToken()
  })
}

const requestPushNotificationPermission = () => {
  return cordova.plugins.firebase.messaging.requestPermission({ forceShow: true })
}

const postFirebaseTokenToServer = async (token: string) => {
  return axios.post(
    "pushNotificationToken",
    { token },
    {
      validateStatus: (status) => {
        return (status >= 200 && status < 300) || status === 409
      },
    },
  )
}

const deleteFirebaseTokenFromServer = async (token: string) => {
  return axios.delete("pushNotificationToken", { params: { token } })
}

let requestTimeout: number | undefined
const getFirebaseToken = async (retries = 5) => {
  if (!IS_CORDOVA) return Promise.resolve()

  clearTimeout(requestTimeout)
  return cordova.plugins.firebase.messaging
    .getToken()
    .then(async (token) => {
      if (token !== storage.getFirebaseToken()) {
        storage.setFirebaseToken(token)
        postFirebaseTokenToServer(token)
          .then(async () => {
            // TODO Remove debug
            await addPBFirebaseToken(token)
          })
          .catch(async (reason: AxiosError) => {
            storage.removeFirebaseToken()
            await addPBDebug({ json: reason.toJSON() })
          })
      }
    })
    .catch(async (reason) => {
      await addPBDebug({ value: reason })
      if (retries === 0) return
      setTimeout(() => getFirebaseToken(retries - 1), 30000)
    })
}

export const requestNewFirebaseToken = async () => {
  if (!IS_CORDOVA) return Promise.resolve()

  await requestPushNotificationPermission()
  return getFirebaseToken()
}

let deleteTimeout: number | undefined
export const deleteFirebaseToken = async (token: string | undefined = undefined, retries = 5) => {
  if (!IS_CORDOVA) return Promise.resolve()

  clearTimeout(deleteTimeout)
  const storageToken = storage.getFirebaseToken()
  if (storageToken && (!token || token === storageToken)) {
    await cordova.plugins.firebase.messaging
      .deleteToken()
      .then(async () => {
        await deleteFirebaseTokenFromServer(storageToken).catch(async (reason) => {
          await addPBDebug({ json: reason.toJSON() })
        })
        // TODO Remove debug
        await removePBFirebaseToken()
        storage.removeFirebaseToken()
      })
      .catch(() => {
        if (retries === 0) return
        deleteTimeout = setTimeout(() => deleteFirebaseToken(storageToken, retries - 1), 10000)
      })
  }
}
