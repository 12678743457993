export const LANGUAGES_LONG = ["pl_PL", "en_GB"]
export const LANGUAGES_SHORT = ["pl", "en"]

type ShortToLongCodesT = {
  [key in (typeof LANGUAGES_LONG)[number]]: (typeof LANGUAGES_SHORT)[number]
}

export const LANGUAGE_CODE_LONG_TO_SHORT: ShortToLongCodesT = {
  pl_PL: "pl",
  en_GB: "en",
}

// Add labels, so string extractor gets them
// t("language.pl_PL")
// t("language.en_GB")
