import { ThemeOptions } from "@mui/material/styles/createTheme"
import { blueGrey } from "@mui/material/colors"

export const typography: ThemeOptions["typography"] = {
  subtitle: {
    color: blueGrey[400],
    fontSize: ".9em",
  },
  "section-header": {
    color: blueGrey[700],
    fontSize: "1rem",
    fontWeight: 700,
    textTransform: "uppercase",
  },
}
