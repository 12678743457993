export enum NavigationParametersEnum {
  messageMailbox = "messageMailbox",
  editProfileTab = "editProfileTab",
  studentTab = "studentTab",
}

export enum URLDialogParametersEnums {
  readMessage = "readMessage",
  composeMessage = "composeMessage",
  readDocument = "readDocument",
  showStudent = "showStudent",
  addStudentNoteSchool = "addStudentNoteSchool",
  editStudentNote = "editStudentNote",
  editProfileAvatar = "editProfileAvatar",
  classDetails = "classDetails",
  classDetailsTab = "classDetailsTab",
  messageRecipientId = "messageRecipientId",
}
