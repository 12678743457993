import { URLDialogParametersEnums } from "@/types/enums/SearchParametersEnums"
import { useUpdateSearchParams } from "@/utils/searchParams"
export const useEditProfileAvatarDialog = () => {
  const { params, updateParams } = useUpdateSearchParams()
  const editProfileAvatar = () => updateParams({ [URLDialogParametersEnums.editProfileAvatar]: "1" })
  const closeEditProfileAvatar = () => updateParams({ [URLDialogParametersEnums.editProfileAvatar]: null })

  const editProfileAvatarParameter = params.get(URLDialogParametersEnums.editProfileAvatar)
  const isEditingProfileAvatar = !!editProfileAvatarParameter

  return {
    editProfileAvatar,
    closeEditProfileAvatar,
    isEditingProfileAvatar,
  }
}
