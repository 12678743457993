import React from "react"
import ReactDOM from "react-dom/client"
import App from "./App"
import { initMocks } from "@/tests/server"
import "@/lib/i18n"
import { initI18n } from "@/lib/i18n"
import dayjs from "@/lib/dayjs"
import isToday from "dayjs/plugin/isToday"
import "@/types/valtio"
import { reportBug } from "@/utils/reportBug"
import { checkVersionBump } from "@/utils/clearStoresCaches"
import { initCordova } from "@/lib/cordova"

dayjs.extend(isToday)

window.onerror = reportBug

async function start() {
  checkVersionBump()
  await initMocks()
  await initI18n()
  initCordova()
  ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
  )
}

start()
