import { TFunction } from "i18next"
import { PasswordValidatorsT } from "@/features/app/types/queries"
import { PasswordValidationErrorsT, UseValidatePasswordReturnT } from "@/features/users/types/profileTypes"
import { useTranslation } from "react-i18next"
import { useMap } from "react-use"
import { useEffect } from "react"

function containsSpecialChars(password: string): boolean {
  return password.match(/[^a-zA-Z0-9żźćńółęąśŻŹĆĄŚĘŁÓŃ]/) !== null
}

function containsNumeric(password: string): boolean {
  return password.match(/[0-9]/) !== null
}

function containsUpperCase(password: string): boolean {
  return password.match(/[A-ZŻŹĆĄŚĘŁÓŃ]/) !== null
}

function containsLowerCase(password: string): boolean {
  return password.match(/[a-zżźćńółęąś]/) !== null
}

export function validatePassword(
  t: TFunction,
  password: string,
  validators: PasswordValidatorsT,
): UseValidatePasswordReturnT {
  const errors: PasswordValidationErrorsT = {}
  for (const [type, value] of Object.entries(validators)) {
    if (value) {
      switch (type) {
        case "minLength":
          if (password.length < (value as number)) errors.minLength = t("form.validators.minLength", { value })
          break
        case "specialChar":
          if (!containsSpecialChars(password)) errors.specialChar = t("form.validators.specialChar")
          break
        case "numeric":
          if (!containsNumeric(password)) errors.numeric = t("form.validators.numeric")
          break
        case "upperCase":
          if (!containsUpperCase(password)) errors.upperCase = t("form.validators.upperCase")
          break
        case "lowerCase":
          if (!containsLowerCase(password)) errors.lowerCase = t("form.validators.lowerCase")
          break
      }
    }
  }

  const firstError = Object.values(errors)[0] ?? ""

  return { errors, firstError }
}

export function useValidatePassword(password: string, validators: PasswordValidatorsT): UseValidatePasswordReturnT {
  const { t } = useTranslation()
  const [values, { setAll }] = useMap<UseValidatePasswordReturnT>({
    errors: {},
    firstError: "",
  })

  useEffect(() => {
    setAll(validatePassword(t, password, validators))
  }, [t, setAll, password, validators])

  return values
}
