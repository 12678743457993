import { useApiVersion } from "@/features/app/api/getApiVersion"
import { useCallback, useState } from "react"

export const useHasCorrectInstance = ({
  enabled = true,
}: {
  enabled?: boolean
} = {}) => {
  const { data, isFetching, isFetchedAfterMount, isSuccess, refetch } = useApiVersion({
    enabled,
    refetchOnMount: "always",
    useErrorBoundary: false,
  })
  const [hasCorrectInstance, setHasCorrectInstance] = useState(false)

  if (isSuccess && data?.version !== undefined) {
    if (!hasCorrectInstance) setHasCorrectInstance(true)
  } else {
    if (hasCorrectInstance) setHasCorrectInstance(false)
  }

  const recheck = useCallback(() => {
    return refetch()
  }, [refetch])

  return {
    hasCorrectInstance,
    isChecked: isFetchedAfterMount,
    isChecking: isFetching,
    recheck,
  }
}
