import { Card, CardContent, CardHeader, Checkbox, FormControlLabel, Skeleton, Stack, TextField } from "@mui/material"
import { useTranslation } from "react-i18next"
import { ShowToSButton } from "@/features/app/components/ToSAcceptance"
import { useProfileQ } from "@/features/users/api/getProfile"
import { ProfileT } from "@/features/users/types/profileTypes"
import { useMap } from "react-use"
import { isValidEmail, isValidPhone } from "@/utils/formValidation"
import { useUpdateProfileQ } from "@/features/users/api/patchProfile"
import { enqueueSnack } from "@/lib/notistack"
import { AppVersion } from "@/features/app/components/AppVersion"
import { ConsentT } from "@/features/users/types/userTypes"
import { useConsentsQ } from "@/features/users/api/getConsents"
import { ProfileAvatarSettings } from "@/features/users/components/profile/avatar/ProfileAvatarSettings"

export const EditProfileFormSkeleton = () => (
  <Stack spacing={2}>
    <Skeleton variant="rounded" width="100%" height={56} />
    <Skeleton variant="rounded" width="100%" height={56} />
    <Skeleton variant="rounded" width="100%" height={56} />
    <Skeleton variant="rounded" width="100%" height={56} />
    <Skeleton variant="rounded" width="100%" height={56} />
    <Skeleton variant="rounded" width="100%" height={102} />
  </Stack>
)

type ProfileFieldsT = "phone" | "email"

export const EditProfileForm = ({ profile }: { profile: ProfileT }) => {
  const { t } = useTranslation()
  const { updateProfileAsync } = useUpdateProfileQ()
  const [values, { set }] = useMap({
    phone: profile.phone,
    email: profile.email,
    "error.phone": "",
    "error.email": "",
  })

  const checkError = (field: ProfileFieldsT) => {
    switch (field) {
      case "email":
        if (values.email) {
          if (!isValidEmail(values.email)) return t("profile.errors.invalid_email")
        }
        return ""
      case "phone":
        if (values.phone) {
          if (!isValidPhone(values.phone)) return t("profile.errors.invalid_phone")
        }
        return ""
      default:
        return ""
    }
  }

  const updateValue = (field: ProfileFieldsT, value: string) => {
    set(field, value)
    set(`error.${field}`, checkError(field))
  }

  const updateField = (field: ProfileFieldsT) => {
    const error = checkError(field)
    set(`error.${field}`, error)

    if (values[field] !== profile[field]) {
      if (!error) {
        updateProfileAsync({ [field]: values[field] })
          .then(() => {
            enqueueSnack(t("profile.update_success"), { variant: "success" })
          })
          .catch((e) => {
            const data = e?.response?.data ? e.response.data : JSON.parse(e?.request?.response)
            const code = data.error.code
            if (code === "invalidPhoneNumber") {
              set("error.phone", t("profile.errors.invalid_phone"))
            }
          })
      }
    }
  }

  return (
    <Stack direction={{ mobile: "column", tablet: "row" }} gap={2} justifyContent="space-between">
      <ProfileAvatarSettings />
      <Stack spacing={2} flexGrow={1}>
        <TextField value={profile.login} disabled label={t("profile.login.label")} />
        <TextField value={profile.firstName} disabled label={t("profile.firstName")} />
        <TextField value={profile.lastName} disabled label={t("profile.lastName")} />
        <TextField
          label={t("profile.phone")}
          value={values.phone}
          type="tel"
          onChange={(e) => updateValue("phone", e.target.value)}
          onBlur={() => updateField("phone")}
          error={!!values["error.phone"]}
          helperText={values["error.phone"]}
        />
        <TextField
          label={t("profile.email")}
          value={values.email}
          type="email"
          onChange={(e) => updateValue("email", e.target.value)}
          onBlur={() => updateField("email")}
          error={!!values["error.email"]}
          helperText={values["error.email"]}
        />
      </Stack>
    </Stack>
  )
}

export const ConsentCheckbox = ({ consent }: { consent: ConsentT }) => {
  const setConsent = (value: boolean) => {
    console.log(value)
  }

  return consent.type.isHidden ? null : (
    <FormControlLabel
      control={
        <Checkbox
          value={consent.value}
          onChange={(e) => setConsent(e.target.checked)}
          disabled={consent.type.isIrrevocable && consent.value}
        />
      }
      label={consent.type.content}
    />
  )
}

export const EditProfileConsents = ({ consents }: { consents: ConsentT[] }) => {
  return (
    <Stack>
      {consents.map((consent) => (
        <ConsentCheckbox consent={consent} />
      ))}
    </Stack>
  )
}

export const BasicProfileInfoSection = () => {
  const { t } = useTranslation()
  const { profile, isFetched: isProfileFetched } = useProfileQ({ keepPreviousData: true })
  const { consents, areConsentsFetched } = useConsentsQ()

  return (
    <Stack spacing={2}>
      <Card>
        <CardHeader title={t("profile.basic_info")} />
        <CardContent>
          {isProfileFetched ? <EditProfileForm profile={profile} /> : <EditProfileFormSkeleton />}
        </CardContent>
      </Card>
      {areConsentsFetched && consents.length > 0 && (
        <Card>
          <CardHeader title={t("profile.consents")} />
          <CardContent>
            <EditProfileConsents consents={consents} />
          </CardContent>
        </Card>
      )}
      <Card>
        <CardHeader title={t("profile.tos")} />
        <CardContent>
          <ShowToSButton variant="contained" />
        </CardContent>
      </Card>
      <AppVersion />
    </Stack>
  )
}
