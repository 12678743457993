import { GetAppDataResponseT, PasswordValidatorsT, UseAppDataReturnI } from "../types/queries"
import { axios, transformFullResponseBase } from "@/lib/axios"
import { useQuery, UseQueryOptions } from "@tanstack/react-query"
import { useMemo } from "react"
import { appQueryKeys } from "@/features/app/api/queryKeys/appQueryKeys"
import { transformAppData } from "@/features/app/api/transformers/transformAppData"
import keyBy from "lodash/keyBy"

const DEFAULT_OPTIONS = {
  queryKey: appQueryKeys.appData,
  queryFn: getApp,
  staleTime: 60 * 60 * 1000, // 1 hour
}

export function getApp(): Promise<GetAppDataResponseT> {
  return axios.get("/appData", {
    transformResponse: (...args) => transformFullResponseBase(...args, false, transformAppData),
  })
}

export function useAppQ(options: UseQueryOptions<GetAppDataResponseT> = {}): UseAppDataReturnI {
  const query = useQuery<GetAppDataResponseT>({
    ...DEFAULT_OPTIONS,
    ...options,
  })

  return { ...query }
}

export function useSchoolsQ() {
  const query = useAppQ()

  const schools = useMemo(() => query.data?.schools ?? [], [query.data?.schools])
  const schoolsById = useMemo(() => keyBy(schools, "id"), [schools])

  return {
    ...query,
    schools,
    schoolsById,
    areSchoolsFetched: query.isFetched,
  }
}

export const usePasswordValidatorsQ = () => {
  const { data } = useQuery<GetAppDataResponseT>(DEFAULT_OPTIONS)

  return useMemo<PasswordValidatorsT>(
    () =>
      data?.user.password.validators ?? {
        minLength: 0,
        specialChar: false,
        numeric: false,
        upperCase: false,
        lowerCase: false,
      },
    [data?.user.password.validators],
  )
}
