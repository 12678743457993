import { Box, ButtonGroup, Divider, IconButton } from "@mui/material"
import AvatarEditor from "react-avatar-editor"
import { TRANSPARENT_PIXEL } from "@/features/users/components/profile/avatar/_config"
import { MinusSolidIcon, PlusSolidIcon, XmarkSolidIcon } from "@/components/Icons"
import { useRef, useState } from "react"

export const ProfileAvatarEditor = ({
  image,
  setImage,
  setModifiedImage,
  max,
}: {
  image: string | null
  setImage: (image: string | null) => void
  setModifiedImage: (image: string | null) => void
  max: number
}) => {
  const editor = useRef<AvatarEditor | null>(null)
  const [scale, setScale] = useState(1)

  const changeScale = (direction: "up" | "down") => {
    if (direction === "up") {
      setScale(scale + 0.2 <= max ? scale + 0.2 : max)
    } else {
      setScale(scale - 0.2 >= 1 ? scale - 0.2 : 1)
    }
  }

  return (
    <Box overflow="auto" position="relative" flexShrink={0}>
      <AvatarEditor
        ref={editor}
        onImageReady={() => {
          setScale(1)
          setModifiedImage(image ? editor.current?.getImageScaledToCanvas().toDataURL() ?? null : null)
        }}
        onImageChange={() => {
          setModifiedImage(image ? editor.current?.getImageScaledToCanvas().toDataURL() ?? null : null)
        }}
        image={image ?? TRANSPARENT_PIXEL}
        width={200}
        height={200}
        border={0}
        color={[255, 255, 255, 0.6]}
        scale={scale}
        rotate={0}
      />
      {image && (
        <Box position="absolute" right={5} top={5}>
          <ButtonGroup
            sx={{
              background: "white",
            }}
            variant="contained"
          >
            <IconButton size="small" onClick={() => setImage(null)}>
              <XmarkSolidIcon />
            </IconButton>
          </ButtonGroup>
        </Box>
      )}
      {image && (
        <Box position="absolute" right={5} bottom={10}>
          <ButtonGroup
            sx={{
              background: "white",
            }}
            variant="contained"
          >
            <IconButton disabled={scale >= max} size="small" onClick={() => changeScale("up")}>
              <PlusSolidIcon />
            </IconButton>
            <Divider orientation="vertical" flexItem />
            <IconButton disabled={scale <= 1} size="small" onClick={() => changeScale("down")}>
              <MinusSolidIcon />
            </IconButton>
          </ButtonGroup>
        </Box>
      )}
    </Box>
  )
}
