import { faker } from "@faker-js/faker/locale/pl"
import { RawUserT } from "@/features/users/types/userTypes"
import { getNextId } from "@/tests/utils/getNextId"

export const EMPTY_USER: RawUserT = {
  id: 0,
  firstName: null,
  fullname: null,
  lastName: null,
  additionalData: {
    email: null,
    phone: null,
  },
  parentUser: null,
}

export const generateMockUser = (
  {
    canHaveParent = true,
    parentUsers = [],
  }: {
    canHaveParent?: boolean
    parentUsers?: RawUserT[]
  },
  overrides?: Partial<RawUserT>,
): RawUserT => {
  const [firstName, lastName] = [
    overrides?.firstName ?? faker.person.firstName(),
    overrides?.lastName ?? faker.person.lastName(),
  ]

  return {
    id: getNextId("user"),
    firstName,
    lastName,
    fullname: `${firstName} ${lastName}`,
    additionalData: {
      phone: faker.datatype.boolean(0.6) ? faker.phone.number() : null,
      email: faker.datatype.boolean(0.6)
        ? faker.internet.email({ firstName, lastName, allowSpecialCharacters: true })
        : null,
    },
    parentUser: faker.datatype.boolean(canHaveParent ? 0.3 : 0)
      ? faker.helpers.arrayElement(parentUsers.length > 0 ? parentUsers : [EMPTY_USER])
      : EMPTY_USER,
    ...overrides,
  }
}
