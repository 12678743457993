import { BooleanLikeT, ColorLikeT, DateLikeT, NumberLikeT, StringLikeT } from "@/types/query"
import dayjs from "@/lib/dayjs"
import { Dayjs } from "dayjs"

export const toNumber = (value: NumberLikeT): number => {
  return Number(value)
}

export const toBoolean = (value: BooleanLikeT): boolean => {
  return value === true || value === "true" || value === 1 || value === "1"
}

export const toDate = (value: DateLikeT, useDefault = false): Dayjs | undefined => {
  if (value === null || value === "") {
    return useDefault ? dayjs() : undefined
  } else if (typeof value === "string") {
    return dayjs(value)
  } else {
    reportError(new Error("Encountered date in object format"))
    return dayjs(value.date)
  }
}

export const toColor = (value: ColorLikeT, defaultColor = ""): string => {
  return value ? `#${value}` : defaultColor
}

export const toString = (value: StringLikeT): string => {
  return value ?? ""
}

export const toRequestBoolean = (value: boolean): BooleanLikeT => {
  return value ? 1 : 0
}
