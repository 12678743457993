import { axios, transformFullResponseBase } from "@/lib/axios"
import { fakeT } from "@/utils/fakeTranslation"
// import { useQuery } from "@tanstack/react-query"
// import { UseQueryFunctionOptionsT } from "@/types/query"
import { GetConsentsResponseT } from "@/features/users/types/userQueriesTypes"
// import { profileQueryKeys } from "@/features/user/api/keys/profileQueryKeys"
import { transformConsents } from "@/features/users/api/transformers/transformConsent"

export const getConsents = async (): Promise<GetConsentsResponseT> => {
  return axios.get("/userConsents", {
    transformResponse: (...args) =>
      transformFullResponseBase(...args, "userConsents", transformConsents, fakeT("error.userConsents.get")),
  })
}

export const useConsentsQ = (/*options: UseQueryFunctionOptionsT<GetConsentsResponseT> = {}*/) => {
  // const query = useQuery<GetConsentsResponseT>({
  //   queryKey: [profileQueryKeys.consents],
  //   queryFn: () => getConsents(),
  //   ...options,
  // })
  //
  // return { ...query, consents: query.data ?? [], areConsentsFetched: query.isFetched }
  return { consents: [], areConsentsFetched: true }
}
