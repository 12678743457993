import { axios } from "@/lib/axios"
import { useQuery, UseQueryOptions } from "@tanstack/react-query"
import { appQueryKeys } from "@/features/app/api/queryKeys/appQueryKeys"
import { GetApiVersionResponseRawT, GetApiVersionResponseT } from "@/features/app/types/queries"

export const getApiVersion = (): Promise<GetApiVersionResponseT> => {
  return axios.get("", {
    transformResponse: (data): GetApiVersionResponseT => {
      const rawResponse: GetApiVersionResponseRawT = JSON.parse(data).data

      return {
        version: rawResponse.api.api_version,
      }
    },
  })
}

export const useApiVersion = (options: UseQueryOptions<GetApiVersionResponseT> = {}) => {
  return useQuery<GetApiVersionResponseT>({
    queryKey: [appQueryKeys.apiVersion],
    queryFn: getApiVersion,
    staleTime: 24 * 60 * 60 * 1000, // 24 hours
    ...options,
  })
}
