import i18n, { InitOptions } from "i18next"
import { initReactI18next } from "react-i18next"
import Backend, { ChainedBackendOptions } from "i18next-chained-backend"
import HttpBackend from "i18next-http-backend"
import LanguageDetector from "i18next-browser-languagedetector"
import resourcesToBackend from "i18next-resources-to-backend"
import { getApiUrl } from "@/lib/axios"
import storage from "@/utils/storage"
import plL from "../../locales/json/pl_PL/translation.json"
import enL from "../../locales/json/en_GB/translation.json"

declare module "i18next" {
  interface CustomTypeOptions {
    returnNull: false
  }
}

const LOCAL_LOCALES: Record<string, any> = {
  pl_PL: plL,
  en_GB: enL,
}

const HTTPBackendOptions: HttpBackend["options"] = {
  loadPath: () => (storage.getInstance() ? `${getApiUrl()}/translator?language={{lng}}` : ""),
  parse: (data) => {
    const parsedData: { id: string; target: string }[] = JSON.parse(data).data["translator"]
    const translation: Record<string, string> = {}
    parsedData.forEach(({ id, target }) => (translation[id] = target))

    return translation
  },
}

export async function initI18n() {
  const resources = {
    pl_PL: { translation: plL },
    en_GB: { translation: enL },
  }

  const options: InitOptions<ChainedBackendOptions> = {
    fallbackLng: { en_US: ["en_GB"], default: ["pl_PL"] },
    debug: true,
    load: "currentOnly",
    returnNull: false,
    keySeparator: false,
    parseMissingKeyHandler: (missingKey) => LOCAL_LOCALES[i18n.language]?.[missingKey] ?? missingKey,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    backend: {
      backends: [HttpBackend, resourcesToBackend(resources)],
      backendOptions: [HTTPBackendOptions],
    },
    detection: {
      convertDetectedLanguage: "Iso15897",
    },
  }

  return (
    i18n
      // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
      // learn more: https://github.com/i18next/i18next-http-backend
      // want your translations to be loaded from a professional CDN? => https://github.com/locize/react-tutorial#step-2---use-the-locize-cdn
      .use(Backend)
      // detect app language
      // learn more: https://github.com/i18next/i18next-browser-languageDetector
      .use(LanguageDetector)
      // pass the i18n instance to react-i18next.
      .use(initReactI18next)
      // init i18next
      // for all options read: https://www.i18next.com/overview/configuration-options
      .init(options)
  )
}

export default i18n
