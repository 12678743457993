// t("api.error.default")
// t("api.error.invalidRequest")
// t("api.error.markNotSupported")
// t("api.error.dbError")
// t("api.error.alreadySigned")
// t("api.error.notAdult")
// t("api.error.notEdocument")
export const KNOWN_ERRORS = [
  "invalidRequest",
  "markNotSupported",
  "dbError",
  "alreadySigned",
  "notAdult",
  "notEdocument",
]
