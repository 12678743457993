import { Outlet, useMatches } from "react-router-dom"
import { MainLayout } from "@/components/Layout/MainLayout"
import { ProfileBarProps } from "@/components/Layout/ProfileBar"
import { Suspense, useMemo } from "react"
import * as React from "react"
import { useAppQ } from "@/features/app/api/getApp"
import { BOTTOM_BAR_ITEMS, generateMenu, TOP_MENU_ITEMS } from "@/routes/menu"
import { MenuItemT } from "@/types/menu"
import { GlobalAlertDialog } from "@/features/app/components/GlobalAlertDialog"
import { LinearProgressFallback } from "@/components/Elements/Fallbacks/LinearProgress"

function getPageTitle(routes?: { handle: any }[]) {
  if (routes) {
    for (const route of routes) {
      if (route.handle?.title) {
        return route.handle.title
      }
    }
  } else {
    return ""
  }
}

export function RouterMainApp() {
  const matches = useMatches()
  const title = getPageTitle(matches)

  return (
    <MainApp title={title}>
      <Outlet />
    </MainApp>
  )
}

export function MainApp({ title, children }: { title: string; children: React.ReactNode }) {
  const { data, isLoading } = useAppQ()

  const mainMenuItems: MenuItemT[] = useMemo(() => {
    return data?.credentials ? generateMenu(data.credentials) : []
  }, [data?.credentials])

  const user: ProfileBarProps = {
    firstname: data?.user.firstname,
    lastname: data?.user.lastname,
    username: data?.user.login,
  }

  if (isLoading) return <LinearProgressFallback />

  return (
    <MainLayout
      title={title}
      bottomMenuItems={BOTTOM_BAR_ITEMS}
      topMenuItems={TOP_MENU_ITEMS}
      mainMenuItems={mainMenuItems}
      profile={user}
    >
      <Suspense fallback={<LinearProgressFallback />}>
        <GlobalAlertDialog />
        {children}
      </Suspense>
    </MainLayout>
  )
}
