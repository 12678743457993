import { useLoginHistoryQ } from "@/features/users/api/getLoginHistory"
import { Card, CardContent, Chip, Skeleton, Stack, Typography } from "@mui/material"
import { LoginAttemptT } from "@/features/users/types/userTypes"
import { useTranslation } from "react-i18next"

export const LoginAttemptCardT = ({ loginAttempt: { date, wasSuccessful } }: { loginAttempt: LoginAttemptT }) => {
  const { t } = useTranslation()

  return (
    <Card>
      <CardContent>
        <Stack direction="row" spacing={1} justifyContent="space-between" alignItems="center">
          <Stack direction="column">
            <Typography fontWeight="bold">{date.format("LLL")}</Typography>
            <Typography variant="subtitle2">{date.fromNow()}</Typography>
          </Stack>
          {wasSuccessful ? (
            <Chip label={t("profile.login.successful")} color="success" />
          ) : (
            <Chip label={t("profile.login.failed")} color="error" />
          )}
        </Stack>
      </CardContent>
    </Card>
  )
}

export const LoginHistorySectionSkeleton = () => (
  <Stack spacing={1}>
    <Skeleton variant="rounded" width="100%" height="40px" />
    <Skeleton variant="rounded" width="100%" height="40px" />
    <Skeleton variant="rounded" width="100%" height="40px" />
  </Stack>
)

export const LoginHistorySection = () => {
  const { loginHistory, isLoginHistoryFetched } = useLoginHistoryQ()

  if (!isLoginHistoryFetched) return <LoginHistorySectionSkeleton />

  return (
    <Stack spacing={1}>
      {loginHistory.map((loginAttempt) => (
        <LoginAttemptCardT key={loginAttempt.id} loginAttempt={loginAttempt} />
      ))}
    </Stack>
  )
}
