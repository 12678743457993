import { axios, transformFullResponseBase } from "@/lib/axios"
import { fakeT } from "@/utils/fakeTranslation"
import { GetLoginHistoryResponseT } from "@/features/users/types/userQueriesTypes"
import { UseQueryFunctionOptionsT } from "@/types/query"
import { transformLoginAttempts } from "@/features/users/api/transformers/transformLoginAttempts"
import { useQuery } from "@tanstack/react-query"
import { profileQueryKeys } from "@/features/users/api/keys/profileQueryKeys"

export const getLoginHistory = async (): Promise<GetLoginHistoryResponseT> => {
  return axios.get("/loginHistory", {
    transformResponse: (...args) =>
      transformFullResponseBase(...args, "loginHistory", transformLoginAttempts, fakeT("error.loginHistory.get")),
  })
}

export const useLoginHistoryQ = (options: UseQueryFunctionOptionsT<GetLoginHistoryResponseT> = {}) => {
  const query = useQuery<GetLoginHistoryResponseT>({
    queryKey: [profileQueryKeys.loginHistory],
    queryFn: () => getLoginHistory(),
    ...options,
  })

  return { ...query, loginHistory: query.data ?? [], isLoginHistoryFetched: query.isFetched }
}
