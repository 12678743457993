import { AlertDialog } from "@/components/Dialogs/AlertDialog"
import { useSnapshot } from "valtio"
import { alertStore, hideAlert } from "@/features/app/stores/alert-store"

export function GlobalAlertDialog() {
  const {
    visible,
    title,
    description,
    canCancel,
    isDangerous,
    acceptLabel,
    onAccept,
    onReject,
  } = useSnapshot(alertStore)

  return (
    <AlertDialog
      title={title}
      description={description}
      isOpen={visible}
      close={hideAlert}
      canCancel={canCancel}
      acceptLabel={acceptLabel}
      isDangerous={isDangerous}
      onAccept={onAccept}
      onReject={onReject}
    />
  )
}
