import {
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  OutlinedInputProps,
} from "@mui/material"
import { useBoolean } from "react-use"
import { EyeSlashSolidIcon, EyeSolidIcon } from "@/components/Icons"
import { forwardRef } from "react"

export type PasswordFieldProps = { helperText?: string } & OutlinedInputProps

export const PasswordField = forwardRef(({ helperText, ...props }: PasswordFieldProps, ref) => {
  const [showPassword, toggleShowPassword] = useBoolean(false)

  return (
    <FormControl fullWidth={props.fullWidth} variant="outlined">
      {props.label && <InputLabel error={props.error}>{props.label}</InputLabel>}
      <OutlinedInput
        ref={ref}
        type={showPassword ? "text" : "password"}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={() => toggleShowPassword()}
              onMouseDown={(e) => e.preventDefault()}
              edge="end"
            >
              {showPassword ? <EyeSlashSolidIcon /> : <EyeSolidIcon />}
            </IconButton>
          </InputAdornment>
        }
        {...props}
      />
      {helperText && <FormHelperText error={props.error}>{helperText}</FormHelperText>}
    </FormControl>
  )
})
