import axios from "axios"
import { APP_VERSION, DEBUG } from "@/config"
import { getBaseApiUrl } from "@/lib/axios"

let lastError = ""

export const postBug = (content: string) => {
  // We use basic axios lib here, to make request to non-teacher specific endpoint
  return axios.post(
    getBaseApiUrl("api/v2/bug"),
    {
      subject: `[New Teacher App][${APP_VERSION}] JS Error`,
      content,
    },
    { headers: { "Content-Type": "multipart/form-data" } },
  )
}

export const reportBug: typeof window.onerror = (msg: string | Event | Error, source, lineno, colno, error) => {
  let title, stack

  if (typeof msg === "string") {
    title = msg
    stack = error?.stack
  } else {
    title = (msg as ErrorEvent)?.message
    stack = (msg as Error)?.stack ?? (msg as ErrorEvent)?.error?.stack ?? ""
  }

  const errorMessage = `${title}\n\n${stack}`

  if (lastError !== title) {
    lastError = title
    if (!DEBUG) {
      console.info("Sending error: ", errorMessage)
      postBug(errorMessage).then()
    } else {
      console.error("Should send error: ", errorMessage)
    }
  }

  return false
}
