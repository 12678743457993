import { StudentDetailsT, StudentT } from "@/features/students/types/studentsTypes"
import { RawStudentDetailsT, RawStudentT } from "@/features/students/types/studentsQueriesTypes"
import { transformUser } from "@/features/users/api/transformers/transformUser"
import { toBoolean, toColor } from "@/utils/requestDataConverters"

export const transformStudent = (rawStudent: RawStudentT): StudentT => ({
  id: rawStudent.id,
  isAdult: toBoolean(rawStudent.isAdult),
  user: transformUser(rawStudent.user),
  parent: rawStudent.user.parentUser ? transformUser(rawStudent.user.parentUser) : undefined,
})

const shortNameFromName = (name: string): string =>
  name
    .split(" ")
    .map((word) => word[0].toUpperCase() + (word[1]?.toLowerCase() ?? ""))
    .join("")

export const transformStudentDetails = (rawStudentDetails: RawStudentDetailsT): StudentDetailsT => ({
  noHomeworkCount: rawStudentDetails.homework.isHomeworkEnabled ? rawStudentDetails.homework.noHomework : -1,
  averageGrade: parseFloat(rawStudentDetails.marks.average.toFixed(2)),
  attendanceStatuses: rawStudentDetails.attendance.status.map((status) => ({
    name: status.name,
    shortName: status.shortName ? status.shortName : shortNameFromName(status.name),
    color: toColor(status.color),
    count: status.value,
  })),
  attendancePercentage: parseFloat(rawStudentDetails.attendance.percentage.toFixed(2)),
})

export const transformStudents = (rawStudents: RawStudentT[]): StudentT[] => rawStudents.map(transformStudent)
