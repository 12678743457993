import { FontAwesomeIconComponent } from "@/components/Icons/FontAwesomeSVGIcon"
import { useDeviceSize } from "@/hooks/useDeviceSize"
import { MainAppSubToolbar } from "@/components/Layout/MainAppBar"
import { Button, IconButton, Stack } from "@mui/material"
import { AppBarTab, AppBarTabs } from "@/components/Elements/AppBarTabs/AppBarTabs"
import React from "react"

export function ActionsTabsMainAppSubToolbar<T>({
  actions = [],
  tabs = [],
  currentTab = "" as T,
  changeTab = () => {},
}: {
  actions?: {
    Icon: FontAwesomeIconComponent
    label: string
    onClick: () => void
  }[]
  tabs?: {
    Icon: FontAwesomeIconComponent
    label: string
    value: string
  }[]
  currentTab?: T
  changeTab?: (tab: T) => void
}) {
  const { isMobile } = useDeviceSize()

  return (
    <MainAppSubToolbar>
      <Stack direction="row" alignItems="center" spacing={2} width="100%">
        {actions.map(({ Icon, label, onClick }, index) =>
          isMobile ? (
            <IconButton key={index} color="primary" onClick={onClick}>
              <Icon />
            </IconButton>
          ) : (
            <Button key={index} variant="contained" startIcon={<Icon />} onClick={onClick}>
              {label}
            </Button>
          )
        )}
        <AppBarTabs value={currentTab} variant="scrollable" onChange={(e, tab) => changeTab(tab)}>
          {tabs.map(({ Icon, label, value }, index) => (
            <AppBarTab key={index} value={value} label={!isMobile && label} icon={<Icon fontSize="small" />} />
            // <AppBarTab key={index} value={value} label={label} icon={<Icon fontSize="small" />} />
          ))}
        </AppBarTabs>
      </Stack>
    </MainAppSubToolbar>
  )
}
