export const MODULES = ["grades", "attendance", "classes", "groups", "messages", "documents", "settlements", "students"]
export const CREDENTIALS = ["module_online_teaching", "teacher_settlements"]
// Add labels, so string extractor gets them
// t("grades.label")
// t("classes.label")
// t("groups.label")
// t("attendance.label")
// t("messages.label")
// t("documents.label")
// t("settlements.label")
// t("students.label")
