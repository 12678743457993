import { axios, transformFullResponseBase } from "@/lib/axios"
import { transformProfile } from "@/features/users/api/transformers/transformProfile"
import { fakeT } from "@/utils/fakeTranslation"
import { useQuery } from "@tanstack/react-query"
import { UseQueryFunctionOptionsT } from "@/types/query"
import { GetProfileResponseT } from "@/features/users/types/userQueriesTypes"
import { profileQueryKeys } from "@/features/users/api/keys/profileQueryKeys"
import { ProfileT } from "@/features/users/types/profileTypes"

const DEFAULT_PROFILE: ProfileT = {
  login: "",
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
  tosAccepted: false,
}

export const getProfile = async (): Promise<GetProfileResponseT> => {
  return axios.get("/profile", {
    transformResponse: (...args) =>
      transformFullResponseBase(...args, "profile", transformProfile, fakeT("error.profile.get")),
  })
}

export const useProfileQ = (options: UseQueryFunctionOptionsT<GetProfileResponseT> = {}) => {
  const query = useQuery<GetProfileResponseT>({
    queryKey: profileQueryKeys.profile,
    queryFn: () => getProfile(),
    ...options,
  })

  return { ...query, profile: query.data ?? DEFAULT_PROFILE }
}
