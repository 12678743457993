import { axios } from "@/lib/axios"
import { useMutation } from "@tanstack/react-query"
import {
  GetProfileAvatarResponseT,
  PostProfileAvatarParametersT,
  PostProfileAvatarResponseT,
} from "@/features/users/types/userQueriesTypes"
import { profileQueryKeys } from "@/features/users/api/keys/profileQueryKeys"
import { queryClient } from "@/lib/react-query"

export const postProfileAvatar = ({ photo }: PostProfileAvatarParametersT): Promise<PostProfileAvatarResponseT> => {
  const formData = new FormData()
  formData.append("data", photo.split(",")[1])

  return axios.post("/avatar", formData, {
    // transformResponse: (data) => transformResponseBase(data, (data) => transformFullMessage(data.message)),
  })
}

export const useUpdateProfileAvatarQ = () => {
  const queryKey = profileQueryKeys.getAvatar()

  const mutation = useMutation({
    onSuccess: (data, photo) => {
      const previousAvatar = queryClient.getQueryData(queryKey)
      queryClient.setQueryData<GetProfileAvatarResponseT>(queryKey, { image: photo })

      return { previousAvatar }
    },
    mutationFn: (photo: PostProfileAvatarParametersT["photo"]) => postProfileAvatar({ photo }),
  })

  return {
    ...mutation,
    updateProfileAvatar: mutation.mutate,
    updateProfileAvatarAsync: mutation.mutateAsync,
  }
}
