import { BooleanLikeT } from "@/types/query"
import { PasswordValidatorTypesT } from "@/features/app/types/queries"

export enum EditProfileTabsEnum {
  profile = "profile",
  password = "password",
  logins = "logins",
  history = "history",
}

export type RawProfileT = {
  login: string
  firstName: string
  lastName: string
  tosAccepted: BooleanLikeT
  mail: string | undefined
  phone: string | undefined
}

export type ProfileT = {
  login: string
  firstName: string
  lastName: string
  email: string
  phone: string
  tosAccepted: boolean
}

export type PasswordValidationErrorsT = Partial<Record<PasswordValidatorTypesT, string>>
export type UseValidatePasswordReturnT = { errors: PasswordValidationErrorsT; firstError: string }
export type ChangePasswordFormT = {
  old: string
  new: string
  repeat: string
}

export type AvatarT = { image: string | undefined }

export type RawAvatarT = {
  data: string | null
  size: number
}
