import { primaryKey } from "@mswjs/data"
import { Overrides } from "@/tests/server/db/generators"
import { faker } from "@faker-js/faker/locale/pl"
import { getNextId } from "@/tests/utils/getNextId"

export type TeacherModelT = {
  id: number
  name: string
}

export const teacherModel = {
  id: primaryKey(Number),
  name: String,
}

export const teacherGenerator = (overrides?: Overrides) => ({
  id: getNextId("teacher"),
  name: `${faker.person.firstName()} ${faker.person.lastName()}`,
  ...overrides,
})
