import dayjs from "dayjs"
import i18next from "i18next"
import { LANGUAGE_CODE_LONG_TO_SHORT } from "@/types/enums/LANGUAGES_LONG"

import "dayjs/locale/en"
import "dayjs/locale/pl"
import relativeTime from "dayjs/plugin/relativeTime"

dayjs.extend(relativeTime)

const updateDayJSLocale = (language: string) => {
  const shortLanguage = LANGUAGE_CODE_LONG_TO_SHORT[language]
  dayjs.locale(shortLanguage)
}

i18next.on("languageChanged", function (language) {
  updateDayJSLocale(language)
})

i18next.on("onInitialized", function (language) {
  updateDayJSLocale(language)
})

export default dayjs
