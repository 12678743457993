import { useTranslation } from "react-i18next"
import { MAX_SCALE } from "@/features/users/components/profile/avatar/_config"
import { Button, Stack, Typography } from "@mui/material"
import { ProfileAvatarDropzone } from "@/features/users/components/profile/avatar/ProfileAvatarDropzone"
import { ProfileAvatarChooser } from "@/features/users/components/profile/avatar/ProfileAvatarChooser"
import { ProfileAvatarEditor } from "@/features/users/components/profile/avatar/ProfileAvatarEditor"

export const EditProfileAvatarSettings = ({
  image,
  setImageAndMaxScale,
  setModifiedImage,
  maxScale,
  takePhoto,
}: {
  image: string | null
  setImageAndMaxScale: (image: string | null, maxScale: number) => void
  setModifiedImage: (image: string | null) => void
  maxScale: number
  takePhoto: () => void
}) => {
  const { t } = useTranslation()

  return (
    <Stack
      alignItems={{ mobile: "center", tablet: "flex-start" }}
      gap={4}
      direction={{ mobile: "column", tablet: "row" }}
    >
      <ProfileAvatarEditor
        image={image}
        setImage={(image) => setImageAndMaxScale(image, 1)}
        setModifiedImage={setModifiedImage}
        max={maxScale}
      />
      <Stack alignItems="center" gap={1}>
        <Button variant="contained" color="primary" onClick={() => takePhoto()}>
          {t("profile.useWebcam")}
        </Button>
        <Typography color={"subtle.main"}>{t("common.or")}</Typography>
        <ProfileAvatarDropzone setImage={(image: string) => setImageAndMaxScale(image, MAX_SCALE)} />
        <Typography color={"subtle.main"}>{t("common.or")}</Typography>
        <ProfileAvatarChooser choose={(image) => setImageAndMaxScale(image, 1)} />
      </Stack>
    </Stack>
  )
}
