import { axios, transformResponseBase } from "@/lib/axios"
import { useQuery } from "@tanstack/react-query"
import { appQueryKeys } from "@/features/app/api/queryKeys/appQueryKeys"
import { GetNewCountResponseT } from "@/features/app/types/queries"

export const getNewCounts = async (): Promise<GetNewCountResponseT> => {
  return axios.get("/newCounters", {
    transformResponse: (data) => transformResponseBase(data, (data) => data.newCounters),
  })
}

export const useNewCountsQ = () => {
  const query = useQuery<GetNewCountResponseT>({
    queryKey: appQueryKeys.newCounts,
    queryFn: getNewCounts,
    refetchInterval: 60 * 1000, // 1 minute
  })

  return {
    ...query,
    counts: query.data ?? { messages: 0 },
  }
}
