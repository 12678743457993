import { RawUserT } from "@/features/users/types/userTypes"
import { EMPTY_USER, generateMockUser } from "@/features/users/tests/mock/generators/userMockGenerator"
import keyBy from "lodash/keyBy"
import mapValues from "lodash/mapValues"
import { range } from "lodash"
import { PredefinedMockStudentTypesT } from "@/features/students/tests/studentsMockData"
import { MOCK_API_ONLY_PREDEFINED } from "@/config"

const generateUser = (
  firstName: string,
  lastName: string,
  hasEmail: boolean,
  hasPhone: boolean,
  parentUser = EMPTY_USER,
): Partial<RawUserT> => ({
  firstName,
  lastName,
  fullname: `${firstName} ${lastName}`,
  additionalData: {
    phone: hasPhone ? "123-4567-890" : null,
    email: hasEmail ? `${firstName.toLowerCase()}@${lastName.toLowerCase()}.pl` : null,
  },
  parentUser,
})

export const usersParentMockData: RawUserT[] = [
  generateMockUser({ canHaveParent: false }, generateUser("Edyta", "Moczulska", true, true)),
  ...range(50).map(() => generateMockUser({ canHaveParent: false })),
]

const usersStudentPredefinedMockDataOverrides: Record<PredefinedMockStudentTypesT, Partial<RawUserT>> = {
  noPhoneNoEmail: generateUser("Dominik", "Kalkowski", false, false),
  hasEmailNoPhone: generateUser("Marlena", "Dardzińska", true, false),
  hasPhoneNoEmail: generateUser("Rafał", "Patynowski", false, true),
  hasPhoneHasEmail: generateUser("Renata", "Borowiecka", true, true),
  isAdult: generateUser("Olga", "Makowska", false, false),
  isNotAdultNoParent: generateUser("Eugeniusz", "Nitka", false, false),
  isNotAdultHasParent: generateUser("Zofia", "Ambrozik", false, false, usersParentMockData[0]),
  isInactive: generateUser("Katarzyna", "Zaborowska", false, false),
}

export const usersStudentPredefinedMockData: Record<PredefinedMockStudentTypesT, RawUserT> = mapValues(
  usersStudentPredefinedMockDataOverrides,
  (overrides) => generateMockUser({ parentUsers: usersParentMockData }, overrides),
)

export const usersStudentGeneratedMockData: RawUserT[] = MOCK_API_ONLY_PREDEFINED
  ? []
  : range(300).map(() =>
      generateMockUser({
        canHaveParent: true,
        parentUsers: usersParentMockData,
      }),
    )

export const usersStudentMockData: RawUserT[] = [
  ...Object.values(usersStudentPredefinedMockData),
  ...usersStudentGeneratedMockData,
]

export const usersStudentMockDataById: Record<number, RawUserT> = keyBy(usersStudentMockData, "id")
