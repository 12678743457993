import * as React from "react"
import { ErrorBoundary } from "react-error-boundary"
import { Helmet, HelmetProvider } from "react-helmet-async"
import { QueryClientProvider } from "@tanstack/react-query"
import { ReactQueryDevtools } from "@tanstack/react-query-devtools"
import { Box, Button, Card, CardActions, CardContent, CardHeader, CssBaseline, Typography } from "@mui/material"
import { queryClient } from "@/lib/react-query"
import { Spinner } from "@/components/Elements/Spinner"
import { AppThemeProvider } from "@/theme/theme"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { LocalizationProvider } from "@mui/x-date-pickers"
import { useTranslation } from "react-i18next"
import { useRouteError } from "react-router"
import { RedirectToLogin } from "@/features/auth/components/RedirectToLogin"
import { SnackbarProvider } from "notistack"
import Container from "@mui/material/Container"
import { DEBUG, IS_MOBILE_APP } from "@/config"
import { MobileUpdateCheck } from "@/features/app/components/MobileUpdateCheck"
import { useDeviceSize } from "@/hooks/useDeviceSize"

export const RouteErrorFallback = () => {
  const error = useRouteError()

  // @ts-ignore
  if ([401, 403].includes(error?.response?.status) || error?.cause === "langlion_no_instance")
    return <RedirectToLogin />

  return <ErrorFallback error={error} />
}

export const ErrorFallback = ({ error }: { error: unknown }) => {
  const { t, i18n } = useTranslation()
  console.error(error)

  return (
    <AppThemeProvider>
      <LocalizationProvider adapterLocale={i18n.language.slice(0, 2)} dateAdapter={AdapterDayjs}>
        <Box minHeight="100vh">
          <Helmet>
            <title>{t("error.title")}</title>
          </Helmet>
          <CssBaseline />
          {IS_MOBILE_APP && <MobileUpdateCheck />}
          <Box
            sx={{
              mt: "env(safe-area-inset-top)",
            }}
          >
            <Container
              sx={{
                pl: { mobile: 1, tablet: 2 },
                pr: { mobile: 1, tablet: 2 },
                pt: { mobile: 4 },
                pb: {
                  mobile: `calc(56px + env(safe-area-inset-bottom))`,
                  tablet: 0,
                },
              }}
            >
              <Card>
                <CardHeader title={t("error.title")} />
                <CardContent>
                  <Typography variant="body1">{t("error.retry_or_contact_support")}</Typography>
                </CardContent>
                <CardActions>
                  <Button onClick={() => window.location.assign(window.location.origin)}>{t("error.reload")}</Button>
                </CardActions>
              </Card>
            </Container>
          </Box>
        </Box>
      </LocalizationProvider>
    </AppThemeProvider>
  )
}

type AppProviderProps = {
  children: React.ReactNode
}

export const AppProvider = ({ children }: AppProviderProps) => {
  const { i18n } = useTranslation()
  const { isMobile } = useDeviceSize()

  return (
    <React.Suspense fallback={<Spinner />}>
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <HelmetProvider>
          <QueryClientProvider client={queryClient}>
            <AppThemeProvider>
              <LocalizationProvider adapterLocale={i18n.language.slice(0, 2)} dateAdapter={AdapterDayjs}>
                <SnackbarProvider
                  maxSnack={3}
                  style={{
                    whiteSpace: "pre-line",
                    fontFamily: "Lato,sans-serif",
                  }}
                  anchorOrigin={
                    isMobile ? { horizontal: "center", vertical: "top" } : { horizontal: "left", vertical: "bottom" }
                  }
                  autoHideDuration={isMobile ? 2500 : 5000}
                >
                  {DEBUG && <ReactQueryDevtools />}
                  {children}
                </SnackbarProvider>
              </LocalizationProvider>
            </AppThemeProvider>
          </QueryClientProvider>
        </HelmetProvider>
      </ErrorBoundary>
    </React.Suspense>
  )
}
