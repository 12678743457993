import { STORE_TYPE_IDS, StoreTypeIdT } from "@/features/app/types/store-type"
import { proxy, subscribe, useSnapshot } from "valtio"
import { GroupT } from "../types/group"
import { SchoolT } from "@/types/schools"

export interface GroupDataT {
  group?: GroupT
  school?: SchoolT
  onlyMine?: boolean
}

type GroupStoreT = Record<StoreTypeIdT, GroupDataT>
const DEFAULT_GROUP_DATA = {
  onlyMine: true,
}
const DEFAULT_STATE: GroupStoreT = {
  grades: {
    ...DEFAULT_GROUP_DATA,
  },
  attendance: { ...DEFAULT_GROUP_DATA },
  classes: { ...DEFAULT_GROUP_DATA },
  students: { ...DEFAULT_GROUP_DATA },
}

const storedData = localStorage.getItem("groupStore")
let initialStore: GroupStoreT = { ...DEFAULT_STATE }

if (storedData) {
  try {
    initialStore = JSON.parse(storedData)
    for (const id of STORE_TYPE_IDS) {
      if (!initialStore[id]) {
        initialStore[id] = DEFAULT_STATE[id]
      }
    }
  } catch (e) {
    initialStore = { ...DEFAULT_STATE }
  }
}

export const groupStore = proxy<GroupStoreT>(initialStore)

subscribe(groupStore, () => {
  localStorage.setItem("groupStore", JSON.stringify(groupStore))
})

export const clearGroupStore = () => {
  for (const id of STORE_TYPE_IDS) {
    groupStore[id] = DEFAULT_STATE[id]
  }
  localStorage.removeItem("groupStore")
}

export function setCurrentGroup(group: GroupT | undefined, id: StoreTypeIdT) {
  groupStore[id] = { ...groupStore[id], group, school: undefined }
}

export const setCurrentGroupForId$ = (id: StoreTypeIdT) => (group: GroupT | undefined) => setCurrentGroup(group, id)

export function setCurrentSchool(school: SchoolT | undefined, id: StoreTypeIdT) {
  groupStore[id] = { ...groupStore[id], group: undefined, school }
}

export const setCurrentSchoolForId$ = (id: StoreTypeIdT) => (school: SchoolT | undefined) =>
  setCurrentSchool(school, id)

export const setOnlyMine = (value: boolean, id: StoreTypeIdT) => {
  groupStore[id] = { ...groupStore[id], onlyMine: value }
}

export function useGroupStore(id: StoreTypeIdT): GroupDataT {
  const store = useSnapshot(groupStore)

  return store[id]
}
