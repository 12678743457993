import { useState } from "react"

export function useTrueFalse(
  initialValue = false
): [boolean, () => void, () => void, () => void] {
  const [isTrue, setIsTrue] = useState(initialValue)
  const setTrue = () => setIsTrue(true)
  const setFalse = () => setIsTrue(false)
  const toggle = () => setIsTrue(!isTrue)

  return [isTrue, setTrue, setFalse, toggle]
}
