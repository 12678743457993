import { LinearProgress, Portal, styled } from "@mui/material"
import React from "react"

const LinearProgressFallbackBase = styled(LinearProgress)({
  width: "100vw",
  position: "fixed",
  top: 0,
  left: 0,
  zIndex: 100000,
})

export const LinearProgressTop = styled(LinearProgress)({
  position: "absolute",
  width: "100%",
  top: 0,
  left: 0,
})

export const LinearProgressFallback = () => (
  <Portal>
    <LinearProgressFallbackBase />
  </Portal>
)
