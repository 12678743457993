import * as React from "react"
import { FormEvent, useCallback } from "react"
import TextField from "@mui/material/TextField"
import Stack from "@mui/material/Stack"
import { useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { InstanceFormI } from "@/features/auth/types/login"
import storage from "@/utils/storage"
import LoadingButton from "@mui/lab/LoadingButton"
import { RightToBracketSolidIcon } from "@/components/Icons"
import { LoginBase } from "@/features/auth/components/LoginBase"
import { RedirectToLogin } from "@/features/auth/components/RedirectToLogin"
import { updateApiInstance } from "@/lib/axios"
import { useHasCorrectInstance } from "@/features/auth/hooks/useHasCorrectInstance"
import { InputAdornment } from "@mui/material"

export const Instance = () => {
  const { t, i18n } = useTranslation()
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors, isSubmitted },
  } = useForm<InstanceFormI>({
    defaultValues: {
      instance: storage.getInstance() ?? "",
    },
  })
  const { hasCorrectInstance, isChecking, recheck } = useHasCorrectInstance({
    enabled: isSubmitted,
  })

  if (isSubmitted && !isChecking && !hasCorrectInstance && !errors.instance) {
    setError("instance", {
      message: t("auth.form.invalidInstance"),
    })
  }
  const selectInstance = useCallback(
    (e: FormEvent) => {
      e.preventDefault()
      return handleSubmit(
        (data) => {
          updateApiInstance(data.instance)
          recheck()
        },
        (errors) => {
          console.error(errors)
        },
      )()
    },
    [handleSubmit, recheck],
  )

  if (hasCorrectInstance) {
    i18n.reloadResources()
    return <RedirectToLogin />
  }

  return (
    <LoginBase title={t("auth.login")}>
      <Stack width="100%" component="form" onSubmit={selectInstance} spacing={2} noValidate>
        <TextField
          required
          fullWidth
          autoFocus
          inputProps={{ autoCapitalize: "none" }}
          error={!!errors.instance}
          helperText={errors.instance?.message ?? t("auth.form.instanceHelp")}
          label={t("auth.form.instance")}
          InputProps={{
            endAdornment: <InputAdornment position="end">.langlion.com</InputAdornment>,
          }}
          {...register("instance", {
            required: t("form.required", {
              field: t("auth.form.instance"),
            }) as string,
          })}
        />
        <LoadingButton
          type="submit"
          fullWidth
          variant="contained"
          size="large"
          startIcon={<RightToBracketSolidIcon />}
          disabled={isChecking}
          loadingPosition="start"
        >
          {isChecking ? t("auth.verifying") : t("auth.continue")}
        </LoadingButton>
      </Stack>
    </LoginBase>
  )
}

export default Instance
