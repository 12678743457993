import { motion } from "framer-motion"
import { styled } from "@mui/material"

export * from "./fa/duotone"
export * from "./fa/solid"
export * from "./fa/light"

export const IconMotionDiv = styled(motion.div)(() => ({
  display: "inline-flex",
}))
