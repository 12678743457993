import { closeSnackbar, enqueueSnackbar, OptionsObject } from "notistack"
import i18n from "@/lib/i18n"
import { TOptions } from "i18next"
import { isArray } from "lodash"
const t = i18n.t

export const enqueueSnack = (message: string, options?: OptionsObject) => {
  const key: string | number = enqueueSnackbar(message, {
    ...options,
    SnackbarProps: {
      onClick: () => closeSnackbar(key),
      ...options?.SnackbarProps,
    },
  })

  return key
}

export const enqueueSuccessSnack = (message: string, options?: OptionsObject) => {
  return enqueueSnack(message, {
    variant: "success",
    ...options,
  })
}

export const enqueueErrorSnack = (message: string, options?: OptionsObject) => {
  return enqueueSnack(message, {
    variant: "error",
    ...options,
  })
}

export const enqueueTranslatedSnack = (
  key: string | string[],
  translationOptions: TOptions = {},
  options?: OptionsObject,
) => {
  const description = isArray(key)
    ? key.map((key) => t(key, translationOptions)).join("\n")
    : t(key, translationOptions)

  return enqueueSnack(description, options)
}

export const enqueueTranslatedSuccessSnack = (
  key: string | string[],
  translationOptions: TOptions = {},
  options?: OptionsObject,
) => {
  return enqueueTranslatedSnack(key, translationOptions, {
    variant: "success",
    ...options,
  })
}

export const enqueueTranslatedErrorSnack = (
  key: string | string[],
  translationOptions: TOptions = {},
  options?: OptionsObject,
) => {
  return enqueueTranslatedSnack(key, translationOptions, {
    variant: "error",
    ...options,
  })
}
