import * as React from "react"
import { useLocation, useOutlet } from "react-router-dom"
import { useEffect, useState } from "react"
import { AnimatePresence, motion } from "framer-motion"

export const AnimatedOutlet: React.FC = () => {
  const o = useOutlet()
  const [outlet] = useState(o)

  return <>{outlet}</>
}

export function RouteWithAnimatedOutlet({ element }: { element: React.ReactNode }) {
  const location = useLocation()

  return (
    <>
      {element}
      <AnimateEntranceSlide motionKey={location.key}>
        <AnimatedOutlet />
      </AnimateEntranceSlide>
    </>
  )
}

export const AnimateEntranceSlide = ({
  motionKey,
  duration = 0.2,
  simultaneous = true,
  moveForward = true,
  distance = 50,
  animateOnMount = false,
  children,
}: {
  motionKey: string
  duration?: number
  simultaneous?: boolean
  moveForward?: boolean
  animateOnMount?: boolean
  distance?: number
  children: React.ReactNode
}) => {
  const [innerMoveForward, setInnerMoveForward] = useState(moveForward)
  useEffect(() => {
    setTimeout(() => setInnerMoveForward(moveForward), duration * 1000 + 100)
  }, [duration, moveForward])

  return (
    <AnimatePresence initial={animateOnMount} mode={simultaneous ? "popLayout" : "wait"}>
      <motion.div
        key={motionKey}
        transition={{ duration }}
        initial={{ opacity: 0, x: (innerMoveForward ? 1 : -1) * distance }}
        animate={{ opacity: 1, x: 0 }}
        exit={{ opacity: 0, x: (innerMoveForward ? -1 : 1) * distance }}
        style={{ overflow: "hidden" }}
      >
        {children}
      </motion.div>
    </AnimatePresence>
  )
}
