import { Button, Card, CardContent, CardHeader, Stack } from "@mui/material"
import { PasswordField } from "@/components/Elements/PasswordField/PasswordField"
import { useTranslation } from "react-i18next"
import { usePasswordValidatorsQ } from "@/features/app/api/getApp"
import { PasswordValidatorsT } from "@/features/app/types/queries"
import * as yup from "yup"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { TFunction } from "i18next"
import { FormEvent, useCallback } from "react"
import { fakeT } from "@/utils/fakeTranslation"
import { useChangePasswordQ } from "@/features/users/api/patchUser"
import { enqueueSnack } from "@/lib/notistack"
import { ChangePasswordFormT } from "@/features/users/types/profileTypes"
import { validatePassword } from "@/features/users/hooks/useValidatePassword"
import { PasswordRequirements } from "@/features/users/components/profile/PasswordRequirements"

const changePasswordYupSchema = yup.object({
  old: yup.string().required("form.required"),
  new: yup
    .string()
    .required("form.required")
    .test("password-validators", "", (password, context) => {
      const { t, validators } = context.options.context as { t: TFunction; validators: PasswordValidatorsT }
      const { firstError } = validatePassword(t, password, validators)
      if (firstError) return context.createError({ message: firstError, type: "password-validators" })
      return true
    }),
  repeat: yup
    .string()
    .required("form.required")
    .oneOf([yup.ref("new")], fakeT("profile.password.errors.noMatch")),
})

export const ChangePasswordSection = () => {
  const { t } = useTranslation()
  const validators = usePasswordValidatorsQ()
  const {
    register,
    watch,
    setError,
    reset,
    formState: { errors: formErrors },
    handleSubmit,
  } = useForm<ChangePasswordFormT>({
    mode: "all",
    resolver: yupResolver(changePasswordYupSchema),
    context: { t, validators },
  })
  const newPassword = watch("new", "")
  const { changePasswordAsync } = useChangePasswordQ()

  const savePassword = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault()
      handleSubmit((values) => {
        changePasswordAsync({
          oldPassword: values.old,
          newPassword: values.new,
          repeatPassword: values.repeat,
        })
          .then(() => {
            enqueueSnack(t("profile.password.changed"), { variant: "success" })
            reset()
          })
          .catch((e) => {
            const code: "samePassword" | "wrongPassword" | string = e.response.data.error.code
            switch (code) {
              case "samePassword":
                setError("new", { message: t("profile.password.errors.samePassword") })
                break
              case "wrongPassword":
                setError("old", { message: t("profile.password.errors.wrongPassword") })
                break
              default:
                setError("old", { message: t("api.error.default") })
            }
          })
      })()
    },
    [t, reset, handleSubmit, setError, changePasswordAsync],
  )

  return (
    <Card>
      <CardHeader title={t("profile.passwordChange")} />
      <CardContent>
        <form onSubmit={savePassword}>
          <Stack spacing={2}>
            <PasswordRequirements validators={validators} password={newPassword} />
            <PasswordField
              label={t("profile.password.old")}
              fullWidth={true}
              error={!!formErrors.old}
              helperText={t(formErrors.old?.message as string, { field: t("profile.password.old") })}
              {...register("old")}
            />
            <PasswordField
              label={t("profile.password.new")}
              fullWidth={true}
              error={!!formErrors.new}
              helperText={
                formErrors.new?.type === "password-validators"
                  ? formErrors.new?.message
                  : t(formErrors.new?.message as string, { field: t("profile.password.new") })
              }
              {...register("new")}
            />
            <PasswordField
              label={t("profile.password.repeat")}
              fullWidth={true}
              error={!!formErrors.repeat}
              helperText={t(formErrors.repeat?.message as string, { field: t("profile.password.repeat") })}
              {...register("repeat")}
            />
            <Button type="submit" variant="contained" title={t("common.save")} sx={{ alignSelf: "center" }}>
              {t("common.save")}
            </Button>
          </Stack>
        </form>
      </CardContent>
    </Card>
  )
}
