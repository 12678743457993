import { initFirebase } from "@/lib/push-notifications"
import { IS_CORDOVA } from "@/utils/IS_CORDOVA"

export const initCordova = () => {
  if (IS_CORDOVA) {
    document.addEventListener(
      "deviceready",
      async () => {
        await initFirebase()
      },
      false,
    )
  }
}
