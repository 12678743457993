import * as React from "react"
import { SwipeableDrawer, styled } from "@mui/material"
import { drawerWidth } from "./vars"
import { ProfileBar, ProfileBarProps } from "@/components/Layout/ProfileBar"
import { MainMenu } from "@/components/Layout/MainMenu"
import { MenuItemT } from "@/types/menu"
import { useDeviceSize } from "@/hooks/useDeviceSize"
import { IS_MOBILE_APP } from "@/config"
import { Link } from "react-router-dom"

interface SidebarProps {
  isOpen: boolean
  toggle: (value?: boolean) => void
  menuItems: MenuItemT[]
  profile: ProfileBarProps
}

const SidebarBase = styled(SwipeableDrawer)({
  width: drawerWidth,
  maxWidth: "90%",
  "& .MuiDrawer-paper": {
    width: drawerWidth,
    maxWidth: "90%",
    boxSizing: "border-box",
    border: "none",
    boxShadow: "0px 2px 4px rgb(38 50 56 / 10%)",
    paddingTop: "env(safe-area-inset-top)",
  },
})

SidebarBase.defaultProps = {
  disableSwipeToOpen:
    !IS_MOBILE_APP && typeof navigator !== "undefined" && /iPad|iPhone|iPod/.test(navigator.userAgent),
}

export function Sidebar({ isOpen, toggle, menuItems, profile }: SidebarProps) {
  const { isMobile } = useDeviceSize()

  const closeOnMobile = () => {
    if (isMobile) toggle(false)
  }

  return (
    <SidebarBase
      variant={isMobile ? "temporary" : "permanent"}
      anchor="left"
      open={isOpen}
      onOpen={() => toggle(true)}
      onClose={() => toggle(false)}
    >
      <Link to={"/profile/"} style={{ textDecoration: "none", color: "inherit" }} onClick={() => closeOnMobile()}>
        <ProfileBar {...profile} />
      </Link>
      <MainMenu items={menuItems} onClick={() => closeOnMobile()} />
    </SidebarBase>
  )
}
