import { ThemeOptions } from "@mui/material/styles/createTheme"
import { createTheme } from "@mui/material"

export const baseOptions: ThemeOptions = {
  palette: {
    primary: {
      main: "#2185D0",
    },
    neutral: {
      main: "#999",
    },
    subtle: {
      main: "#999",
    },
    primaryVariants: {
      "50": "#e1f4fe",
      "100": "#b4e4fd",
      "200": "#83d2fb",
      "300": "#54c0f8",
      "400": "#35b3f6",
      "500": "#24a5f4",
      "600": "#2397e4",
      "700": "#2185D0",
      "800": "#1e73bc",
      "900": "#1a5399",
      A100: "#b4e4fd",
      A200: "#83d2fb",
      A400: "#35b3f6",
      A700: "#2185D0",
    },
    secondary: { main: "#FFCF00" },
    contrastThreshold: 2.75,
    tonalOffset: 0.2,
  },
  typography: {
    fontFamily: ["Lato", "sans-serif"].join(","),
  },
}

// We create base theme, so we can use primary palette in additional options, like: theme.palette.primary.light
export const baseTheme = createTheme(baseOptions)

export const shadows: typeof baseTheme.shadows = [...baseTheme.shadows]
shadows[1] =
  "0 1px 3px 0 rgb(0 0 0 / 7%), 0 1px 3px 0 rgb(0 0 0 / 6%), 0 2px 1px -2px rgb(0 0 0 / 10%)"
shadows[2] =
  "0 2px 4px 0 rgb(0 0 0 / 10%), 0 2px 4px 0 rgb(0 0 0 / 8%), 0 0px 1px 2px rgb(0 0 0 / 8%)"
