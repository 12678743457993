import { useTranslation } from "react-i18next"
import { useEditProfileAvatarDialog } from "@/features/users/hooks/useEditProfileAvatarDialog"
import { useCallback, useEffect, useReducer, useState } from "react"
import { useTrueFalse } from "@/hooks/useTrueFalse"
import { useUpdateProfileAvatarQ } from "@/features/users/api/postProfileAvatar"
import { useRemoveProfileAvatarQ } from "@/features/users/api/deleteProfileAvatar"
import { FullScreenDialog } from "@/components/Layout/FullScreenDialogPage"
import { AnimateEntranceSlide } from "@/components/Navigation/AnimatedOutlet"
import { ProfileAvatarWebcam } from "@/features/users/components/profile/avatar/ProfileAvatarWebcam"
import { EditProfileAvatarSettings } from "@/features/users/components/profile/avatar/EditProfileAvatarSettings"
import { useGetProfileAvatarQ } from "@/features/users/api/getProfileAvatar"
import { Skeleton } from "@mui/material"
import { MAX_SCALE } from "@/features/users/components/profile/avatar/_config"

type EditProfileImagesT = {
  original: string | null
  modified: string | null
  isInitialised: boolean
}

export const EditProfileAvatarSettingsDialog = () => {
  const { t } = useTranslation()
  const { isEditingProfileAvatar, closeEditProfileAvatar } = useEditProfileAvatarDialog()
  const [image, setImageReducer] = useReducer(
    (state: EditProfileImagesT, values: Partial<EditProfileImagesT>): EditProfileImagesT => ({ ...state, ...values }),
    {
      original: null,
      modified: null,
      isInitialised: false,
    },
  )
  const [maxScale, setMaxScale] = useState(1)
  const [isTakingPhoto, startTakingPhoto, stopTakingPhoto] = useTrueFalse(false)
  const { avatar, isFetched, isFetching } = useGetProfileAvatarQ(undefined)
  const { updateProfileAvatarAsync, isLoading: isLoadingUpdate } = useUpdateProfileAvatarQ()
  const { removeProfileAvatarAsync, isLoading: isLoadingRemove } = useRemoveProfileAvatarQ()

  useEffect(() => {
    if (isFetched && avatar?.image && !image.isInitialised) {
      setImageReducer({ original: avatar.image, modified: avatar.image, isInitialised: true })
    }
  }, [isFetched, avatar, image.isInitialised])

  const takePhoto = useCallback(
    (photo: string | null) => {
      if (photo) {
        setImageReducer({ original: photo, modified: photo, isInitialised: true })
        setMaxScale(MAX_SCALE)
      }
      stopTakingPhoto()
    },
    [stopTakingPhoto],
  )

  const setImage = useCallback((image: string | null) => {
    setImageReducer({ original: image, modified: image })
  }, [])
  const setModifiedImage = (image: string | null) => {
    setImageReducer({ modified: image })
  }
  const setImageAndMaxScale = useCallback(
    (image: string | null, maxScale = MAX_SCALE) => {
      setImage(image)
      setMaxScale(maxScale)
    },
    [setImage],
  )

  return (
    <FullScreenDialog
      maxWidth="tablet"
      open={isEditingProfileAvatar}
      onClose={() => closeEditProfileAvatar()}
      title={t("profile.changePhoto")}
      primaryAction={{
        label: t("common.save"),
        onClick: () =>
          (image.modified ? updateProfileAvatarAsync(image.modified) : removeProfileAvatarAsync()).then(() =>
            setImageAndMaxScale(image.modified, 1),
          ),
        disabled: !isFetched || avatar?.image === image.original,
      }}
      isProcessing={isFetching || isLoadingUpdate || isLoadingRemove}
    >
      {isFetched ? (
        <AnimateEntranceSlide
          motionKey={`taking-photo-${isTakingPhoto}`}
          distance={100}
          duration={0.15}
          simultaneous={false}
          moveForward={!isTakingPhoto}
        >
          {isTakingPhoto ? (
            <ProfileAvatarWebcam cancel={() => stopTakingPhoto()} takePhoto={takePhoto} />
          ) : (
            <EditProfileAvatarSettings
              image={image.original}
              setImageAndMaxScale={setImageAndMaxScale}
              setModifiedImage={setModifiedImage}
              maxScale={maxScale}
              takePhoto={startTakingPhoto}
            />
          )}
        </AnimateEntranceSlide>
      ) : (
        <Skeleton variant="rectangular" width="100%" height="350px" />
      )}
    </FullScreenDialog>
  )
}
