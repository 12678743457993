import { ElementColorT } from "@/types/enums/ELEMENT_COLORS"

export type ElementPaletteT = {
  icon: string
  label: string
  bg: string
}

export type ElementColorsPaletteT = {
  [key in ElementColorT]: ElementPaletteT
}

export const THEME_ELEMENT_COLORS: ElementColorsPaletteT = {
  blue: {
    label: "#0277bd",
    icon: "#2185D0",
    bg: "#b3e5fc",
  },
  red: {
    label: "#c62828",
    icon: "#DB2828",
    bg: "#ffcdd2",
  },
  orange: {
    label: "#ff8f00",
    icon: "#F2711C",
    bg: "#ffecb3",
  },
  yellow: {
    label: "#FFFFFF",
    icon: "#FBBD08",
    bg: "#FBBD08",
  },
  olive: {
    label: "#FFFFFF",
    icon: "#B5CC18",
    bg: "#B5CC18",
  },
  green: {
    label: "#2e7d32",
    icon: "#21BA45",
    bg: "#c8e6c9",
  },
  teal: {
    label: "#00695c",
    icon: "#00B5AD",
    bg: "#b2dfdb",
  },
  violet: {
    label: "#4527a0",
    icon: "#6435C9",
    bg: "#d1c4e9",
  },
  purple: {
    label: "#6a1b9a",
    icon: "#A333C8",
    bg: "#e1bee7",
  },
  pink: {
    label: "#ad1457",
    icon: "#E03997",
    bg: "#f8bbd0",
  },
  brown: {
    label: "#FFFFFF",
    icon: "#A5673F",
    bg: "#A5673F",
  },
  grey: {
    label: "#FFFFFF",
    icon: "#767676",
    bg: "#767676",
  },
  black: {
    label: "#FFFFFF",
    icon: "#1B1C1D",
    bg: "#1B1C1D",
  },
  white: {
    label: "#1B1C1D",
    icon: "#FFFFFF",
    bg: "#FFFFFF",
  },
}
