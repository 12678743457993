import React from "react"
import { Page } from "@/components/Layout/Page"
import { EditProfileToolbar } from "@/features/users/components/profile/EditProfileToolbar"
import { useCurrentProfileTab } from "@/features/users/hooks/useCurrentProfileTab"
import { EditProfileTabsEnum } from "@/features/users/types/profileTypes"
import { BasicProfileInfoSection } from "@/features/users/components/profile/BasicProfileInfoSection"
import { ChangePasswordSection } from "@/features/users/components/profile/ChangePasswordSection"
import { LoginHistorySection } from "@/features/users/components/profile/LoginHistorySection"
import { TeachingHistorySection } from "@/features/users/components/profile/TeachingHistorySection"

const EditProfileSections = ({ section }: { section: EditProfileTabsEnum }) => {
  switch (section) {
    case EditProfileTabsEnum.profile:
      return <BasicProfileInfoSection />
    case EditProfileTabsEnum.password:
      return <ChangePasswordSection />
    case EditProfileTabsEnum.logins:
      return <LoginHistorySection />
    case EditProfileTabsEnum.history:
      return <TeachingHistorySection />
  }
}

export const EditProfile = () => {
  const { currentTab } = useCurrentProfileTab()
  return (
    <>
      <EditProfileToolbar />
      <Page hasSubtoolbar>
        <EditProfileSections section={currentTab} />
      </Page>
    </>
  )
}
