import { initializeDb } from "@/tests/server/db"
import { MOCK_API } from "@/config"

export async function initMocks() {
  const inProduction = import.meta.env.MODE === "production"
  const shouldMock = !inProduction && MOCK_API

  if (shouldMock) {
    console.log("Initializing mocks")
    // const { initializeDb } = await import("@/tests/server/db")
    initializeDb()

    if (typeof window === "undefined") {
      console.error("Mocking API is only available in the browser")
      return Promise.resolve()
    } else {
      const { getWorker, workerOptions } = await import("./browser")
      const worker = getWorker() //: SetupWorkerApi | undefined
      if (worker) {
        return worker.start(workerOptions)
      } else return Promise.reject("Worker not found")
    }
  } else {
    return Promise.resolve()
  }
}
