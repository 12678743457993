import { createBrowserRouter, Navigate, NonIndexRouteObject, RouteObject, RouterProvider } from "react-router-dom"

import { ModulesRouteT, ModulesT } from "@/types/modules"
import * as React from "react"
import { RouterMainApp } from "@/features/app/components/MainApp"
import { getModuleLabel, MODULE_PATHS } from "./modules"
import { RouteWithAnimatedOutlet } from "@/components/Navigation/AnimatedOutlet"
import { attendanceRouteChildren } from "@/features/attendance/routes/attendance-route-children"
import { classesRouteChildren } from "@/features/classes/routes/classes-route-children"
import { gradesRouteChildren } from "@/features/grades/routes/grades-route-children"
import { RouteErrorFallback } from "@/providers/AppProvider"
import { loadableWithSpinner } from "@/lib/loadable"
import Instance from "@/features/auth/routes/Instance"
import { messagesRouteChildren } from "@/features/messages/routes/messages-route-children"
import { EditProfile } from "@/features/users/routes/EditProfile"

export const ROUTES = {
  LOGOUT: "/logout/",
}

const Login = loadableWithSpinner(() => import("@/features/auth/routes/Login"))
const Logout = loadableWithSpinner(() => import("@/features/auth/routes/Logout"))
const Dashboard = loadableWithSpinner(() => import("@/features/app/routes/Dashboard"))
const Attendance = loadableWithSpinner(() => import("@/features/attendance/routes/Attendance"))
const Classes = loadableWithSpinner(() => import("@/features/classes/routes/Classes"))
const Grades = loadableWithSpinner(() => import("@/features/grades/routes/Grades"))
const Messages = loadableWithSpinner(() => import("@/features/messages/routes/Messages"))
const Documents = loadableWithSpinner(() => import("@/features/documents/routes/Documents"))
const Settlements = loadableWithSpinner(() => import("@/features/settlements/routes/Settlements"))
const Students = loadableWithSpinner(() => import("@/features/students/routes/Students"))

function generateRouteBasicsForModule(module: ModulesT, path = ""): NonIndexRouteObject {
  return {
    id: module,
    path: `${MODULE_PATHS[module]}${path}`,
    handle: { title: getModuleLabel(module) },
    index: false,
  }
}

const MODULES: ModulesRouteT = {
  groups: {
    ...generateRouteBasicsForModule("groups"),
    element: <div>Groups</div>,
  },
  grades: {
    ...generateRouteBasicsForModule("grades"),
    element: <RouteWithAnimatedOutlet element={<Grades />} />,
    children: gradesRouteChildren,
  },
  attendance: {
    ...generateRouteBasicsForModule("attendance"),
    element: <RouteWithAnimatedOutlet element={<Attendance />} />,
    children: attendanceRouteChildren,
  },
  classes: {
    ...generateRouteBasicsForModule("classes"),
    element: <RouteWithAnimatedOutlet element={<Classes />} />,
    children: classesRouteChildren,
  },
  messages: {
    ...generateRouteBasicsForModule("messages"),
    element: <RouteWithAnimatedOutlet element={<Messages />} />,
    children: messagesRouteChildren,
  },
  documents: {
    ...generateRouteBasicsForModule("documents"),
    element: <Documents />,
    children: [],
  },
  settlements: {
    ...generateRouteBasicsForModule("settlements"),
    element: <Settlements />,
    children: [],
  },
  students: {
    ...generateRouteBasicsForModule("students"),
    element: <Students />,
    children: [],
  },
}

const MODULE_ROUTES_LIST = Object.values(MODULES).flat()

const routes: RouteObject[] = [
  {
    id: "index",
    path: "/index.html",
    element: <Navigate to="/" />,
  },
  {
    id: "instance",
    path: "/instance/",
    element: <Instance />,
  },
  {
    id: "login",
    path: "/login/",
    element: <Login />,
  },
  {
    id: "logout",
    path: ROUTES.LOGOUT,
    element: <Logout />,
  },
  {
    id: "home",
    path: "/",
    element: <RouterMainApp />,
    errorElement: <RouteErrorFallback />,
    children: [
      // t("common.home")
      { path: "/", element: <Dashboard />, handle: { title: "common.home" } },
      {
        id: "edit_profile",
        path: "/profile/",
        element: <EditProfile />,
        // t("user.profile")
        handle: { title: "user.profile" },
      },
      ...MODULE_ROUTES_LIST,
    ],
  },
]

export const router = createBrowserRouter(routes)

export const AppRoutes = () => {
  return <RouterProvider router={router} />
}
