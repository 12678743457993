import { Stack, StackProps } from "@mui/material"
import React from "react"
import { SIZES_PX } from "@/config/sizes"

interface PageProps extends StackProps {
  children: React.ReactNode
  hasSubtoolbar?: boolean
  small?: boolean
}

export function Page({ children, hasSubtoolbar = false, small = false, ...pageProps }: PageProps) {
  return (
    <Stack
      width={small ? { md: "80%", lg: "66%" } : {}}
      m={small ? "auto" : undefined}
      mt={
        hasSubtoolbar
          ? {
              mobile: SIZES_PX.APP_BAR_HEIGHT_MOBILE,
              tablet: SIZES_PX.APP_BAR_HEIGHT,
            }
          : 0
      }
      spacing={2}
      direction="column"
      alignSelf="center"
      {...pageProps}
    >
      {children}
    </Stack>
  )
}
