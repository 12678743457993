import { primaryKey } from "@mswjs/data"
import { Overrides } from "@/tests/server/db/generators"
import { faker } from "@faker-js/faker/locale/pl"
import { COLUMN_COLORS } from "@/features/grades/types/grades"
import { RawGradesColumnT } from "@/features/grades/types/grades-queries"
import { RawGroupT } from "@/features/groups/types/queries"
import { getCustomNextId, getNextId } from "@/tests/utils/getNextId"

export type GradesColumnModelT = {
  id: number
  groupId: number
  data: string | RawGradesColumnT
}

export const gradesColumnModel = {
  id: primaryKey(Number),
  groupId: Number,
  data: String,
}

const columnNames = ["test", "quiz", "homework", "project", "exam", "final exam"]

export const gradeColumnGenerator = ({ group }: { group: RawGroupT }, overrides?: Overrides): RawGradesColumnT => {
  const id = getNextId("grades_column")
  const type = faker.helpers.arrayElement(columnNames)
  const name = `${type} ${getCustomNextId(`grades_column_${type}_${group.id}`)}`
  return {
    id,
    name,
    shortName: name,
    position: 1,
    color: faker.helpers.arrayElement(COLUMN_COLORS),
    importance: faker.number.float({ min: 1, max: 3, precision: 0.5 }),
    max: faker.helpers.arrayElement([20, 50, 80, 100, 100, 100]),
    ...overrides,
  }
}
