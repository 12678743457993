import { useTranslation } from "react-i18next"
import {
  Box,
  Dialog,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Typography,
} from "@mui/material"
import { LANGUAGES_LONG } from "@/types/enums/LANGUAGES_LONG"
import { LanguagesLongT } from "@/types/languages"
import { FLAG_COMPONENTS } from "@/components/Icons/Flags"

export function LanguageItem({
  code,
  close,
}: {
  code: LanguagesLongT
  close: () => void
}) {
  const { i18n } = useTranslation()
  const { t } = useTranslation()

  const Flag = FLAG_COMPONENTS[code]

  return (
    <MenuItem
      onClick={() => {
        i18n.changeLanguage(code).then(() => {
          close()
        })
      }}
    >
      <ListItemIcon>
        <Flag />
      </ListItemIcon>
      <ListItemText sx={{ pl: 2 }}>
        <Typography
          variant="h5"
          color={i18n.language === code ? "primary" : undefined}
        >
          {t(`language.${code}`)}
        </Typography>
      </ListItemText>
    </MenuItem>
  )
}

export function ChangeLanguageMenu({ close }: { close: () => void }) {
  return (
    <Box>
      {LANGUAGES_LONG.map((code) => (
        <LanguageItem key={code} code={code} close={close} />
      ))}
    </Box>
  )
}

export function ChangeLanguageModal({
  open,
  onClose,
}: {
  open: boolean
  onClose: () => void
}) {
  return (
    <Dialog open={open} onClose={onClose}>
      <Box p={2}>
        <ChangeLanguageMenu close={onClose} />
      </Box>
    </Dialog>
  )
}
