import { CredentialsT, ModuleGroupsT, ModulesT, ModulesToMenuItemsT } from "@/types/modules"
import { MenuItemLinkPropsT, MenuItemT } from "@/types/menu"
import flow from "lodash/flow"
import keyBy from "lodash/fp/keyBy"
import mapValues from "lodash/fp/mapValues"
import {
  DEFAULT_APP_MODULES,
  getModuleLabel,
  MODULE_ICONS,
  MODULE_PATHS,
  SYSTEM_MODULES_APP_MODULES,
} from "@/routes/modules"
import { MODULES } from "@/types/enums/modules"
import { HouseChimneyLightIcon, RightFromBracketDuotoneIcon, UserDuotoneIcon } from "@/components/Icons"
import { fakeT } from "@/utils/fakeTranslation"

function generateModuleMenuItem(module: ModulesT): MenuItemT {
  return {
    type: "nav",
    label: getModuleLabel(module),
    to: MODULE_PATHS[module],
    icon: MODULE_ICONS[module],
    id: module,
  }
}

const MODULES_TO_MENU_ITEMS: ModulesToMenuItemsT = flow(
  keyBy((module) => module),
  mapValues(generateModuleMenuItem),
)(MODULES)

const COMMON_MENU_ITEMS: MenuItemT[] = [
  {
    type: "nav",
    label: fakeT("common.home"),
    to: "/",
    icon: HouseChimneyLightIcon,
  },
]

export function generateMenuForCredentials(credentials: CredentialsT[]): MenuItemT[] {
  return [
    ...DEFAULT_APP_MODULES,
    ...credentials
      .map((systemModule) => {
        return SYSTEM_MODULES_APP_MODULES[systemModule as keyof ModuleGroupsT] ?? []
      })
      .flat(),
  ].map((appModule) => MODULES_TO_MENU_ITEMS[appModule])
}

export function generateMenu(credentials: CredentialsT[]): MenuItemT[] {
  return [...COMMON_MENU_ITEMS, ...generateMenuForCredentials(credentials)]
}

export const TOP_MENU_ITEMS: MenuItemT[] = [
  {
    type: "link",
    label: fakeT("user.edit_profile"),
    to: "/profile/",
    icon: UserDuotoneIcon,
  },
  {
    type: "language",
  },
  {
    type: "link",
    label: fakeT("common.logout"),
    to: "/logout",
    icon: RightFromBracketDuotoneIcon,
  },
]

export const BOTTOM_BAR_ITEMS: MenuItemLinkPropsT[] = [
  {
    label: getModuleLabel("classes"),
    to: MODULE_PATHS.classes,
    icon: MODULE_ICONS.classes,
  },
  {
    label: getModuleLabel("grades"),
    to: MODULE_PATHS.grades,
    icon: MODULE_ICONS.grades,
  },
]
