import React from "react"
import { loadableWithLinearProgress } from "@/lib/loadable"

const ClassDetailsDialog = loadableWithLinearProgress(
  () => import("@/features/classes/components/ClassDetails/ClassDetailsDialog")
)

export const classesRouteChildren = [
  {
    id: "classes--for-dates",
    path: "from/:startDate/to/:endDate",
    element: null,
  },
  {
    id: "classes--for-dates-groupModel",
    path: "from/:startDate/to/:endDate/groupModel/:groupId",
    element: null,
  },
  {
    id: "classes--details",
    path: "details/:classId",
    element: <ClassDetailsDialog />,
  },
]

export default classesRouteChildren
