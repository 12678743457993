import { Overrides } from "../generators"
import { faker } from "@faker-js/faker/locale/pl"
import { baseModel, BaseModelT } from "@/tests/server/db/models/baseModel"
import { RawUserT } from "@/features/users/types/userTypes"
import { RawFullMessageT } from "@/features/messages/types/messagesQueriesTypes"
import { MailboxEnum } from "@/features/messages/types/enums/MailboxEnum"
import { getNextId } from "@/tests/utils/getNextId"

/* TODO: It could have groupId also, and it could be used when getting groups students, instead of saving fixed student list at start */
export type MessageModelT = {
  mailbox: MailboxEnum
} & BaseModelT<RawFullMessageT>

export const messageModel = {
  ...baseModel,
  mailbox: String,
}

export type RawModelFullMessageT = RawFullMessageT

export const serializeToMessageModel = (message: RawModelFullMessageT): MessageModelT => {
  return {
    id: message.id,
    mailbox: message.mailbox,
    initialized: false,
    data: JSON.stringify(message),
  }
}

export const messageGenerator = (users: RawUserT[], overrides?: Overrides): RawModelFullMessageT => {
  const sender = faker.helpers.arrayElement(users)
  const id = getNextId("message")

  return {
    id,
    subject: faker.lorem.sentence(),
    content: `<p>${faker.lorem.paragraph()}</p><p>${faker.lorem.paragraph()}</p><p>${faker.lorem.paragraph()}</p>`,
    date: faker.date.past().toISOString(),
    isViewed: faker.datatype.boolean(),
    recipients: faker.helpers
      .arrayElements(users, faker.number.int({ min: 1, max: 5 }))
      .map((user) => user.fullname)
      .join(", "),
    attachments: [],
    sender: sender,
    senderId: sender.id,
    mailbox: faker.helpers.arrayElement(Object.values(MailboxEnum)),
    ...overrides,
  }
}
