import { forwardRef } from "react"
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material"
import { useTranslation } from "react-i18next"

export interface AlertDialogParametersI {
  title: string
  description: string
  canCancel: boolean
  isDangerous: boolean
  acceptLabel: string
  onAccept: () => void
  onReject?: () => void
}

export type AlertDialogPropsT = {
  isOpen: boolean
  close: () => void
} & Partial<AlertDialogParametersI>

export const AlertDialog = forwardRef<HTMLDivElement, AlertDialogPropsT>(
  (
    {
      isOpen,
      close,
      title = "",
      description = "",
      // t("common.close")
      acceptLabel = "common.close",
      canCancel = false,
      isDangerous = false,
      onAccept = () => {
      },
      onReject = () => {
      }
    },
    ref
  ) => {
    const { t } = useTranslation()
    const accept = () => {
      onAccept()
      close()
    }

    const reject = () => {
      onReject()
      close()
    }

    return (
      <Dialog
        ref={ref}
        open={isOpen}
        onClose={close}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {title && <DialogTitle>{title}</DialogTitle>}
        <DialogContent>
          <DialogContentText>{description}</DialogContentText>
        </DialogContent>
        <DialogActions>
          {canCancel && (
            <Button color="subtle" onClick={reject} autoFocus>
              {t("common.cancel")}
            </Button>
          )}
          <Button
            color={isDangerous ? "error" : "primary"}
            onClick={accept}
            autoFocus
          >
            {t(acceptLabel)}
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
)
