import storage from "@/utils/storage"

const PB_DEBUG = import.meta.env.VITE_PB_DEBUG === "true"
const pbAddress = import.meta.env.VITE_PB_DEBUG_ADDRESS
const testApiUrlPrefix = `${pbAddress}/api/collections`
const testApiFBTokensUrl = `${testApiUrlPrefix}/fb_tokens/records/`
const testApiDebugUrl = `${testApiUrlPrefix}/debug/records/`

export const addPBFirebaseToken = async (token: string) => {
  if (!PB_DEBUG) return Promise.resolve()
  await fetch(testApiFBTokensUrl, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      id: token.substring(40, 55),
      token,
    }),
  })
}

export const removePBFirebaseToken = async () => {
  if (!PB_DEBUG) return Promise.resolve()
  if (storage.getFirebaseToken() !== null) {
    await fetch(testApiFBTokensUrl + storage.getFirebaseToken()!.substring(40, 55), {
      method: "DELETE",
    })
  }
}

export const addPBDebug = async ({ value, json }: { value?: string; json?: object }) => {
  if (!PB_DEBUG) return Promise.resolve()
  await fetch(testApiDebugUrl, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      value,
      json: JSON.stringify(json),
    }),
  })
}
