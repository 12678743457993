import { axios, transformFullResponseBase } from "@/lib/axios"
import { useQuery } from "@tanstack/react-query"
import {
  GetProfileAvatarParametersT,
  GetProfileAvatarResponseT,
  RawGetProfileAvatarResponseT,
} from "@/features/users/types/userQueriesTypes"
import { transformAvatar } from "@/features/users/api/transformers/transformAvatar"
import { profileQueryKeys } from "@/features/users/api/keys/profileQueryKeys"
import { UseQueryFunctionOptionsT } from "@/types/query"

export const getProfileAvatar = ({ userId }: GetProfileAvatarParametersT): Promise<GetProfileAvatarResponseT> => {
  return axios.get("/avatar", {
    params: {
      userId,
    },
    transformResponse: (data: RawGetProfileAvatarResponseT, headers, status) =>
      transformFullResponseBase(data, headers, status, "avatar", transformAvatar),
  })
}

export const useGetProfileAvatarQ = (
  params: GetProfileAvatarParametersT = {},
  options: UseQueryFunctionOptionsT = {},
) => {
  const { userId } = params

  const query = useQuery<GetProfileAvatarResponseT>({
    queryKey: profileQueryKeys.getAvatar(userId),
    queryFn: () => getProfileAvatar({ userId }),
    staleTime: 1000 * 60 * 60, // 1 hour
    ...options,
  })

  return {
    ...query,
    avatar: options.enabled || options.enabled === undefined ? query.data : undefined,
  }
}
