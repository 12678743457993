import Axios, { AxiosRequestConfig, AxiosResponseHeaders } from "axios"
import { API_URL, API_WEB_VERSION, DEBUG, MOCK_API } from "@/config"
import storage from "@/utils/storage"
import i18n from "@/lib/i18n"
import { KNOWN_ERRORS } from "@/types/api"
import { enqueueSnack, enqueueTranslatedErrorSnack } from "@/lib/notistack"
import { reportBug } from "@/utils/reportBug"
import { clearStoresCaches } from "@/utils/clearStoresCaches"
import { QUERY_THROW_ERROR_HEADER } from "@/lib/react-query"
import { isString } from "lodash"

const PARAMS_METHODS = ["get", "GET", "delete", "DELETE"]

const shouldShowNotification = (error: any): boolean => {
  return !(error.cause === "langlion_no_instance")
}

function authRequestInterceptor(config: AxiosRequestConfig) {
  const token = storage.getAccessToken()
  const instance = storage.getInstance()

  if (!instance) {
    const error = new Error("No instance")
    error.cause = "langlion_no_instance"
    throw error
  }

  if (!config.headers) {
    config.headers = {}
  }

  if (!config.params) {
    config.params = {}
  }

  if (!PARAMS_METHODS.includes(config.method ?? "")) {
    config.headers["Content-Type"] = "multipart/form-data"
  }

  if (token) {
    if (PARAMS_METHODS.includes(config.method ?? "")) {
      config.params["access_token"] = token
    } else {
      config.headers.Authorization = `Bearer ${token}`
    }
  }
  config.headers.Accept = "application/json"
  return config
}

export const updateApiInstance = (instance: string) => {
  storage.setInstance(instance)
  clearStoresCaches()
  axios.defaults.baseURL = getApiUrl()
}

export const getBaseApiUrl = (url = "") => {
  const instance = storage.getInstance() ?? "test"
  return MOCK_API ? "/" : `https://${instance}.${API_URL}/${url}`
}

export const getApiUrl = () => {
  return getBaseApiUrl(`api/teacher/${API_WEB_VERSION}`)
}

export const axios = Axios.create({
  baseURL: getApiUrl(),
})

export const transformFullResponseBase = (
  data: any,
  headers: AxiosResponseHeaders,
  status: number | undefined,
  transformDataKey: string | false,
  transformFn: (data: any) => any,
  errorDescriptionKey?: string,
  errorValue?: any,
) => {
  if (status !== undefined) {
    if (status >= 400) {
      if (errorDescriptionKey) enqueueTranslatedErrorSnack([errorDescriptionKey, "error.retry_or_contact_support"])
      if (errorValue) headers.set(QUERY_THROW_ERROR_HEADER, "false")

      return errorValue ?? null
    }
  }

  let parsedData = JSON.parse(data)
  parsedData = parsedData.data ?? parsedData

  return transformResponseBase(transformDataKey ? parsedData[transformDataKey] : parsedData, transformFn, true)
}

const t = i18n.t
export const transformResponseBase = (data: any, transformFn: (data: any) => any, ignoreDataKey = false) => {
  if (isString(data)) data = JSON.parse(data)
  try {
    return transformFn(ignoreDataKey ? data : data.data ?? data)
  } catch (e) {
    // TODO: We shouldn't catch and return here, but I don't remember why I did this - should be checked out
    console.error("transformResponseBase: transformFn failed, returning untransformed parsedData")
    if (e) reportBug!(e as any)
    return data.data ? data.data : data
  }
}

axios.interceptors.request.use(authRequestInterceptor as any)
axios.interceptors.response.use(
  (response) => {
    return response.data?.data ? response.data.data : response.data
  },
  (error) => {
    if ([401, 403].includes(error.response?.status)) {
      console.error("Unauthorized")
      return Promise.reject(error)
    } else {
      if (shouldShowNotification(error)) {
        const code = error.response?.data?.error?.code || "default"
        const message = error.response?.data?.error?.message || error.message

        let snack

        if (KNOWN_ERRORS.includes(code)) {
          snack = t(`api.error.${code}`)
        } else {
          snack = t(`api.error.default`)
        }
        if (DEBUG) {
          snack += `\nMessage: ${message}`
        }
        enqueueSnack(snack, { variant: "error" })
      }

      return Promise.reject(error)
    }
  },
)
