import { ModuleGroupsT, ModulesT } from "@/types/modules"

import {
  BookDuotoneIcon,
  CalendarCheckLightIcon,
  CalendarDayLightIcon,
  FilePenLightIcon,
  InboxLightIcon,
  PeopleGroupLightIcon,
  WalletLightIcon,
} from "@/components/Icons"
import { FontAwesomeIconComponent } from "@/components/Icons/FontAwesomeSVGIcon"

const PATH_FUNCTIONS = ["grades", "addGradeColumn", "editGradeColumn"]

type ModulePathsT = {
  [key in ModulesT & string]: string
}

type ModulePathFunctionsT = {
  [key in (typeof PATH_FUNCTIONS)[number]]: (...params: any) => string
}

export const MODULE_PATHS: ModulePathsT = {
  grades: "/grades",
  attendance: "/attendance",
  classes: "/classes",
  groups: "/groups",
  messages: "/messages",
  documents: "/documents",
  settlements: "/settlements",
  students: "/students",
}

export const MODULE_PATHS_FUNCTIONS: ModulePathFunctionsT = {
  grades: (groupId?: number) => `/grades${groupId ? `/${groupId}` : ""}`,
  addGradeColumn: (groupId) => `/grades/${groupId}/add-column`,
  editGradeColumn: (groupId, columnId) => `/grades/${groupId}/edit-column/${columnId}`,
}

type ModuleIconsT = Record<ModulesT, FontAwesomeIconComponent>

export const MODULE_ICONS: ModuleIconsT = {
  classes: CalendarDayLightIcon,
  attendance: CalendarCheckLightIcon,
  groups: BookDuotoneIcon,
  grades: FilePenLightIcon,
  messages: InboxLightIcon,
  documents: FilePenLightIcon,
  settlements: WalletLightIcon,
  students: PeopleGroupLightIcon,
}

export const DEFAULT_APP_MODULES = ["grades", "attendance", "classes", "messages", "documents"] //, "students"]

export const SYSTEM_MODULES_APP_MODULES: ModuleGroupsT = {
  teacher_settlements: ["settlements"],
}

export function getModuleLabel(module: ModulesT): string {
  return `${module}.label`
}
