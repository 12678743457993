import { FontAwesomeSvgIcon, FontAwesomeSvgIconTypeProps } from "@/components/Icons/FontAwesomeSVGIcon"

import ListTimelineSVG from "@/assets/icons/fa/light/list-timeline.svg?react"
import ChartSimpleSVG from "@/assets/icons/fa/light/chart-simple.svg?react"
import NotesSVG from "@/assets/icons/fa/light/notes.svg?react"
import MemoCircleInfoSVG from "@/assets/icons/fa/light/memo-circle-info.svg?react"
import PeopleGroupSVG from "@/assets/icons/fa/light/people-group.svg?react"
import WalletSVG from "@/assets/icons/fa/light/wallet.svg?react"
import CircleCheckSVG from "@/assets/icons/fa/light/circle-check.svg?react"
import CircleSVG from "@/assets/icons/fa/light/circle.svg?react"
import UserSVG from "@/assets/icons/fa/light/user.svg?react"
import GraduationCapSVG from "@/assets/icons/fa/light/graduation-cap.svg?react"
import ClockRotateLeftSVG from "@/assets/icons/fa/light/clock-rotate-left.svg?react"
import IdCardSVG from "@/assets/icons/fa/light/id-card.svg?react"
import KeySVG from "@/assets/icons/fa/light/key.svg?react"
import FileContractSVG from "@/assets/icons/fa/light/file-contract.svg?react"
import NewspaperSVG from "@/assets/icons/fa/light/newspaper.svg?react"
import EllipsisVerticalSVG from "@/assets/icons/fa/light/ellipsis-vertical.svg?react"
import ReplySVG from "@/assets/icons/fa/light/reply.svg?react"
import PencilSVG from "@/assets/icons/fa/light/pencil.svg?react"
import TrashSVG from "@/assets/icons/fa/light/trash.svg?react"
import PaperPlaneTopSVG from "@/assets/icons/fa/light/paper-plane-top.svg?react"
import InboxSVG from "@/assets/icons/fa/light/inbox.svg?react"
import FilePenSVG from "@/assets/icons/fa/light/file-pen.svg?react"
import CalendarCheckSVG from "@/assets/icons/fa/light/calendar-check.svg?react"
import HouseChimneySVG from "@/assets/icons/fa/light/house-chimney.svg?react"
import TimerSVG from "@/assets/icons/fa/light/timer.svg?react"
import CalendarLightSVG from "@/assets/icons/fa/light/calendar.svg?react"
import CalendarDayLightSVG from "@/assets/icons/fa/light/calendar-day.svg?react"

export const CalendarLightIcon = (props: FontAwesomeSvgIconTypeProps) => (
  <FontAwesomeSvgIcon {...props} icon={CalendarLightSVG} />
)

export const CalendarDayLightIcon = (props: FontAwesomeSvgIconTypeProps) => (
  <FontAwesomeSvgIcon {...props} icon={CalendarDayLightSVG} />
)

export const TimerIcon = (props: FontAwesomeSvgIconTypeProps) => <FontAwesomeSvgIcon {...props} icon={TimerSVG} />

export const HouseChimneyLightIcon = (props: FontAwesomeSvgIconTypeProps) => (
  <FontAwesomeSvgIcon {...props} icon={HouseChimneySVG} />
)

export const CalendarCheckLightIcon = (props: FontAwesomeSvgIconTypeProps) => (
  <FontAwesomeSvgIcon {...props} icon={CalendarCheckSVG} />
)
export const FilePenLightIcon = (props: FontAwesomeSvgIconTypeProps) => (
  <FontAwesomeSvgIcon {...props} icon={FilePenSVG} />
)
export const InboxLightIcon = (props: FontAwesomeSvgIconTypeProps) => <FontAwesomeSvgIcon {...props} icon={InboxSVG} />
export const PaperPlaneTopLightIcon = (props: FontAwesomeSvgIconTypeProps) => (
  <FontAwesomeSvgIcon {...props} icon={PaperPlaneTopSVG} />
)
export const TrashLightIcon = (props: FontAwesomeSvgIconTypeProps) => <FontAwesomeSvgIcon {...props} icon={TrashSVG} />
export const PencilLightIcon = (props: FontAwesomeSvgIconTypeProps) => (
  <FontAwesomeSvgIcon {...props} icon={PencilSVG} />
)
export const ReplyLightIcon = (props: FontAwesomeSvgIconTypeProps) => <FontAwesomeSvgIcon {...props} icon={ReplySVG} />
export const EllipsisVerticalLightIcon = (props: FontAwesomeSvgIconTypeProps) => (
  <FontAwesomeSvgIcon {...props} icon={EllipsisVerticalSVG} />
)
export const NewspaperLightIcon = (props: FontAwesomeSvgIconTypeProps) => (
  <FontAwesomeSvgIcon {...props} icon={NewspaperSVG} />
)
export const FileContractLightIcon = (props: FontAwesomeSvgIconTypeProps) => (
  <FontAwesomeSvgIcon {...props} icon={FileContractSVG} />
)
export const KeyLightIcon = (props: FontAwesomeSvgIconTypeProps) => <FontAwesomeSvgIcon {...props} icon={KeySVG} />
export const IdCardLightIcon = (props: FontAwesomeSvgIconTypeProps) => (
  <FontAwesomeSvgIcon {...props} icon={IdCardSVG} />
)
export const ClockRotateLeftLightIcon = (props: FontAwesomeSvgIconTypeProps) => (
  <FontAwesomeSvgIcon {...props} icon={ClockRotateLeftSVG} />
)
export const GraduationCapLightIcon = (props: FontAwesomeSvgIconTypeProps) => (
  <FontAwesomeSvgIcon {...props} icon={GraduationCapSVG} />
)
export const UserLightIcon = (props: FontAwesomeSvgIconTypeProps) => <FontAwesomeSvgIcon {...props} icon={UserSVG} />
export const CircleLightIcon = (props: FontAwesomeSvgIconTypeProps) => (
  <FontAwesomeSvgIcon {...props} icon={CircleSVG} />
)
export const CircleCheckLightIcon = (props: FontAwesomeSvgIconTypeProps) => (
  <FontAwesomeSvgIcon {...props} icon={CircleCheckSVG} />
)
export const WalletLightIcon = (props: FontAwesomeSvgIconTypeProps) => (
  <FontAwesomeSvgIcon {...props} icon={WalletSVG} />
)
export const PeopleGroupLightIcon = (props: FontAwesomeSvgIconTypeProps) => (
  <FontAwesomeSvgIcon {...props} icon={PeopleGroupSVG} />
)

export const MemoCircleInfoLightIcon = (props: FontAwesomeSvgIconTypeProps) => (
  <FontAwesomeSvgIcon {...props} icon={MemoCircleInfoSVG} />
)
export const NotesLightIcon = (props: FontAwesomeSvgIconTypeProps) => <FontAwesomeSvgIcon {...props} icon={NotesSVG} />
export const ChartSimpleLightIcon = (props: FontAwesomeSvgIconTypeProps) => (
  <FontAwesomeSvgIcon {...props} icon={ChartSimpleSVG} />
)
export const ListTimelineLightIcon = (props: FontAwesomeSvgIconTypeProps) => (
  <FontAwesomeSvgIcon {...props} icon={ListTimelineSVG} />
)
