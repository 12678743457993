import Container from "@mui/material/Container"
import { Card, CardContent, CardHeader, CssBaseline } from "@mui/material"
import Stack from "@mui/material/Stack"
import LogotypeSVG from "@/assets/logo-blue.svg?react"
import * as React from "react"
import { AppVersion } from "@/features/app/components/AppVersion"

export const LoginBase = ({ title, children }: { title: string; children: React.ReactNode }) => {
  return (
    <Container component="main" maxWidth="xs" sx={{ paddingTop: `calc(16px + env(safe-area-inset-top))` }}>
      <CssBaseline />

      <Stack width="100%" height="100%" spacing={2} alignItems="center">
        <LogotypeSVG width="60%" style={{ marginBottom: "32px" }} />

        <Card sx={{ width: "100%" }}>
          <CardHeader title={title} />
          <CardContent>{children}</CardContent>
        </Card>
        <AppVersion />
      </Stack>
    </Container>
  )
}
