import * as React from "react"
import { loadableWithLinearProgress } from "@/lib/loadable"

const EditAttendanceDialog = loadableWithLinearProgress(
  () => import("@/features/attendance/components/EditAttendance/EditAttendanceDialog")
)

export const attendanceRouteChildren = [
  {
    id: "attendance--for-date",
    path: ":date",
    element: null,
  },
  {
    id: "attendance--for-date-groupModel",
    path: ":date/:groupId",
    element: null,
  },
  {
    id: "attendance--edit",
    path: "edit/:classId",
    element: <EditAttendanceDialog />,
  },
  {
    id: "attendance--edit-date",
    path: ":date/edit/:classId",
    element: <EditAttendanceDialog />,
  },
  {
    id: "attendance--edit-date-groupModel",
    path: ":date/:groupId/edit/:classId",
    element: <EditAttendanceDialog />,
  },
]
