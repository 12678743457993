import { clearDateRangeStore } from "@/features/app/stores/date-range-store"
import { clearGroupStore } from "@/features/groups/stores/group-store"
import { queryClient } from "@/lib/react-query"
import { APP_VERSION } from "@/config"

export const clearStoresCaches = () => {
  clearDateRangeStore()
  clearGroupStore()
  queryClient.clear()
}

export const checkVersionBump = () => {
  if (APP_VERSION !== localStorage.getItem("appVersion")) {
    clearStoresCaches()
    localStorage.removeItem("msw-db")
    localStorage.removeItem("appStore")
    localStorage.setItem("appVersion", APP_VERSION)
  }
}
