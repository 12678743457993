import * as React from "react"
import { BottomNavigation, BottomNavigationAction, Paper } from "@mui/material"
import MoreHorizIcon from "@mui/icons-material/MoreHoriz"
import { MenuItemLinkPropsT } from "@/types/menu"
import { SvgIconComponent } from "@mui/icons-material"
import { NavLink as BaseNavLink, NavLinkProps } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { useWindowSize } from "react-use"

const NavLink = React.forwardRef<HTMLAnchorElement, NavLinkProps & { activeClassName: string }>(
  ({ activeClassName, ...props }, ref) => {
    return (
      <BaseNavLink
        ref={ref}
        {...props}
        className={({ isActive }) => [props.className, isActive ? activeClassName : null].filter(Boolean).join(" ")}
      />
    )
  },
)

export function BottomAppNavigation({
  menuItems,
  toggleSidebar,
}: {
  menuItems: MenuItemLinkPropsT[]
  isSidebarOpen: boolean
  toggleSidebar: () => void
}) {
  const { t } = useTranslation()
  const { height } = useWindowSize()

  return height < 500 ? null : (
    <Paper
      sx={{
        position: "fixed",
        bottom: 0,
        left: 0,
        right: 0,
        pb: "env(safe-area-inset-bottom)",
        zIndex: 10,
      }}
      elevation={3}
    >
      <BottomNavigation showLabels={true}>
        {menuItems.map(({ label, to, icon }, index) => {
          const Icon = icon as SvgIconComponent
          return (
            <BottomNavigationAction
              key={index}
              component={NavLink}
              to={to}
              label={t(label)}
              icon={<Icon height="24px" />}
              activeClassName="Mui-selected"
            />
          )
        })}
        <BottomNavigationAction label={t("common.more")} onClick={toggleSidebar} icon={<MoreHorizIcon />} />
      </BottomNavigation>
    </Paper>
  )
}
