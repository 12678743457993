import { ThemeOptions } from "@mui/material/styles/createTheme"
import { LinkProps } from "@mui/material/Link"
import { blueGrey } from "@mui/material/colors"
import { baseTheme, shadows } from "./base"
import React from "react"
import { Link as RouterLink, LinkProps as RouterLinkProps } from "react-router-dom"

const LinkBehavior = React.forwardRef<HTMLAnchorElement, Omit<RouterLinkProps, "to"> & { href: RouterLinkProps["to"] }>(
  (props, ref) => {
    const { href, ...other } = props
    // Map href (MUI) -> to (react-router)
    return <RouterLink ref={ref} to={href} {...other} />
  }
)

export const components: ThemeOptions["components"] = {
  MuiAvatar: {
    styleOverrides: {
      root: {
        color: blueGrey[600],
        backgroundColor: blueGrey[100],
        borderRadius: baseTheme.spacing(0.75),
        width: baseTheme.spacing(6),
        height: baseTheme.spacing(6),
        fontSize: baseTheme.typography.body2.fontSize,
        fontWeight: 700,
      },
    },
  },
  MuiInputBase: {
    styleOverrides: {
      root: {
        backgroundColor: baseTheme.palette.background.paper,
        "& .MuiInputBase-input": {
          backgroundColor: baseTheme.palette.background.paper,
        },
      },
    },
  },
  MuiLink: {
    defaultProps: {
      component: LinkBehavior,
    } as LinkProps,
  },
  MuiButtonBase: {
    defaultProps: {
      LinkComponent: LinkBehavior,
    },
  },
  MuiButton: {
    styleOverrides: {
      root: {
        fontWeight: 700,
      },
      startIcon: {
        "& > svg": {
          width: ".75em",
          height: ".75em",
        },
      },
    },
  },
  MuiListItemText: {
    styleOverrides: {
      primary: {
        fontWeight: 600,
      },
    },
  },
  MuiDialogContent: {
    styleOverrides: {
      root: { paddingTop: `${baseTheme.spacing(1)}` },
    },
  },
  MuiChip: {
    styleOverrides: {
      root: {
        borderRadius: "0.25em",
        fontSize: "0.85rem",
      },
      label: {
        fontWeight: 700,
      },
      icon: {
        color: "inherit",
      },
    },
  },
  MuiCard: {
    variants: [
      {
        props: { variant: "inactive" },
        style: {
          background: blueGrey[50],
          boxShadow: shadows[1],
        },
      },
    ],
  },
  MuiCardContent: {
    styleOverrides: {
      root: {
        "&:last-child": {
          paddingBottom: baseTheme.spacing(2),
        },
      },
    },
  },
  MuiFab: {
    variants: [
      {
        props: { color: "default" },
        style: {
          backgroundColor: blueGrey[400],
        },
      },
    ],
  },
  MuiTabs: {
    styleOverrides: {
      scrollButtons: {
        width: "20px",
      },
      flexContainer: {
        "& > .MuiButtonBase-root > .MuiTab-iconWrapper": {
          marginRight: 12,
        },
      },
    },
  },
  MuiMenuItem: {
    styleOverrides: {
      root: {
        whiteSpace: "normal",
      },
    },
  },
}
