import { useMediaQuery } from "@mui/material"
import { theme } from "@/theme/theme"

export function useDeviceSize() {
  const isMobile = useMediaQuery(theme.breakpoints.down("tablet"))
  const isTablet = useMediaQuery(theme.breakpoints.between("tablet", "desktop"))
  const isDesktop = useMediaQuery(theme.breakpoints.up("desktop"))

  return {
    isMobile,
    isTablet,
    isDesktop,
  }
}
