import { faker } from "@faker-js/faker/locale/pl"
import dayjs from "dayjs"
import { RawGroupT } from "@/features/groups/types/queries"
import { getNextId } from "@/tests/utils/getNextId"
import { RawStudentT } from "@/features/students/types/studentsQueriesTypes"

export enum MockLanguagesEnum {
  english = "Angielski",
  french = "Francuski",
  spanish = "Hiszpański",
  german = "Niemiecki",
  russian = "Rosyjski",
  italian = "Włoski",
}

const LANGUAGE_IDS: Record<MockLanguagesEnum, number> = {
  [MockLanguagesEnum.english]: 0,
  [MockLanguagesEnum.french]: 0,
  [MockLanguagesEnum.spanish]: 0,
  [MockLanguagesEnum.german]: 0,
  [MockLanguagesEnum.russian]: 0,
  [MockLanguagesEnum.italian]: 0,
}

const getLanguageID = (language: MockLanguagesEnum) => {
  LANGUAGE_IDS[language] = LANGUAGE_IDS[language] + 1
  return `${language} ${LANGUAGE_IDS[language]} / ${dayjs().format("YYYY")}`
}

export const generateGroupMock = (
  { students = [], minLength = 1, maxLength = 5 }: { students?: RawStudentT[]; minLength?: number; maxLength?: number },
  overrides: Partial<RawGroupT> = {},
): RawGroupT => {
  return {
    id: getNextId("group"),
    name: overrides["name"] ? getLanguageID(faker.helpers.arrayElement(Object.values(MockLanguagesEnum))) : "",
    startDate: faker.date.past().toISOString(),
    endDate: faker.date.future().toISOString(),
    schoolId: 1,
    students: faker.helpers.arrayElements(
      students,
      faker.number.int({
        min: minLength,
        max: maxLength,
      }),
    ),
    isHomeworkEnabled: faker.datatype.boolean(),
    isLessonDetailsPublishingEnabled: faker.datatype.boolean(),
    isPlanned: false,
    pointSystemTypeName: faker.helpers.arrayElement(["percent", "normal"]),
    ...overrides,
  }
}
