import { Overrides } from "../generators"
import { faker } from "@faker-js/faker/locale/pl"
import { baseModel, BaseModelT } from "@/tests/server/db/models/baseModel"
import { RawUserT } from "@/features/users/types/userTypes"
import { getNextId } from "@/tests/utils/getNextId"

export enum UserTypesEnumT {
  student = "student",
  teacher = "teacher",
  admin = "admin",
}

export type UserTypesT = `${UserTypesEnumT}`

/* TODO: It could have groupId also, and it could be used when getting groups students, instead of saving fixed student list at start */
export type UserModelT = {
  type: UserTypesT
} & BaseModelT<RawUserT>

export const userModel = {
  ...baseModel,
  type: String,
}

export type RawModelUserT = {
  type: UserTypesT
} & RawUserT

export const serializeToUserModel = (user: RawModelUserT): UserModelT => {
  return {
    id: user.id,
    type: user.type,
    initialized: false,
    data: JSON.stringify(user),
  }
}

export const userGenerator = (
  types: UserTypesEnumT[] = [UserTypesEnumT.admin, UserTypesEnumT.student, UserTypesEnumT.teacher],
  overrides?: Overrides,
): RawModelUserT => {
  const [firstName, lastName] = [faker.person.firstName(), faker.person.lastName()]

  return {
    id: getNextId("user"),
    firstName,
    lastName,
    fullname: `${firstName} ${lastName}`,
    additionalData: {
      phone: faker.phone.number(),
      email: faker.internet.email(),
    },
    parentUser: null,
    type: faker.helpers.arrayElement(types),
    ...overrides,
  }
}
