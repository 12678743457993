import { useNavigationTab } from "@/hooks/useNavigationTab"
import { EditProfileTabsEnum } from "@/features/users/types/profileTypes"
import { NavigationParametersEnum } from "@/types/enums/SearchParametersEnums"

export const useCurrentProfileTab = () => {
  return useNavigationTab<EditProfileTabsEnum>(
    NavigationParametersEnum.editProfileTab,
    Object.values(EditProfileTabsEnum),
  )
}
