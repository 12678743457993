import * as React from "react"
import { loadableWithLinearProgress } from "@/lib/loadable"

const EditColumn = loadableWithLinearProgress(() => import("@/features/grades/routes/EditColumn"))
export const gradesRouteChildren = [
  {
    id: "grades--for-groupModel",
    path: ":groupId",
    element: null,
  },
  {
    id: "grades--add-column",
    path: ":groupId/add-column",
    element: <EditColumn />,
  },
  {
    id: "grades--edit-column",
    path: ":groupId/edit-column/:columnId",
    element: <EditColumn />,
  },
]
