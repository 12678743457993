import { axios } from "@/lib/axios"
import { useQuery } from "@tanstack/react-query"
import { KEYS_GROUPS } from "./keys"
import { GetGroupsParametersT, GetGroupsResponseT, UseGroupsDurationParameterT } from "@/features/groups/types/queries"
import { Dayjs } from "dayjs"
import dayjs from "@/lib/dayjs"
import { useEffect, useMemo, useRef } from "react"
import { GroupT } from "@/features/groups/types/group"
import { transformRawGroup } from "@/features/groups/api/transformers/groups-transformer"
import { DatesT } from "@/components/Elements/LLDateRangePicker"
import { UseQueryFunctionOptionsT } from "@/types/query"

export function getGroups({ studentId }: GetGroupsParametersT = {}): Promise<GetGroupsResponseT> {
  return axios.get("/schoolClasses", {
    params: { studentId },
    transformResponse: (data) => {
      const parsedData = JSON.parse(data)
      return parsedData.data.schoolClasses.map(transformRawGroup)
    },
  })
}

export function useGroupsQ({ studentId }: GetGroupsParametersT = {}, options: UseQueryFunctionOptionsT = {}) {
  const query = useQuery<GetGroupsResponseT>({
    queryKey: KEYS_GROUPS.getGroups({ studentId }),
    queryFn: () => getGroups({ studentId }),
    ...options,
  })
  const groups = useMemo(
    () => (query.data ?? []).filter((group) => !group.isPlanned).sort((a, b) => a.name.localeCompare(b.name)),
    [query.data],
  )

  return { ...query, groups, areGroupsFetched: query.isFetched }
}

export function useStudentGroupsQ({ studentId }: GetGroupsParametersT = {}, options: UseQueryFunctionOptionsT = {}) {
  const lastStudentId = useRef(studentId)

  useEffect(() => {
    if (lastStudentId.current !== studentId && studentId !== undefined) {
      lastStudentId.current = studentId
    }
  }, [studentId])

  return useGroupsQ({ studentId: studentId ?? lastStudentId.current }, options)
}

const getDurationFromGroups = (type: "startDate" | "endDate", groups: GroupT[]) => {
  let returnedDate: Dayjs | undefined = undefined
  for (const group of groups) {
    const date = dayjs(group[type])
    if (!returnedDate) returnedDate = date
    else
      returnedDate = (type === "startDate" ? date.isBefore(returnedDate) : date.isAfter(returnedDate))
        ? date
        : returnedDate
  }
  return returnedDate
}

export function useGroupsDuration({ groupId }: UseGroupsDurationParameterT): DatesT {
  const { data: groups } = useGroupsQ()

  return useMemo(() => {
    const filteredData = groupId !== undefined ? groups?.filter((group) => group.id === groupId) : groups

    return filteredData && filteredData.length > 0
      ? {
          from: getDurationFromGroups("startDate", filteredData) ?? null,
          to: getDurationFromGroups("endDate", filteredData) ?? null,
        }
      : { from: dayjs(), to: dayjs() }
  }, [groups, groupId])
}
