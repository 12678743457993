import React, { createContext, FormEvent, ReactNode, useCallback, useContext, useState } from "react"
import { useDeviceSize } from "@/hooks/useDeviceSize"
import {
  AppBar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  IconButton,
  Portal,
  Toolbar,
  Typography,
} from "@mui/material"
import { XMarkDuotoneIcon } from "@/components/Icons"
import LoadingButton from "@mui/lab/LoadingButton"
import { ActionT } from "@/types/layout"
import { t } from "i18next"
import { useNavigate } from "react-router"
import { LinearProgressTop } from "@/components/Elements/Fallbacks/LinearProgress"
import { useTrueFalse } from "@/hooks/useTrueFalse"

type FullScreenDialogT = {
  open: boolean
  onClose: () => void
  isProcessing?: boolean
  title?: string
  children: ReactNode
  primaryAction: ActionT
  secondaryAction?: ActionT
  disableEnter?: boolean
  disableCancel?: boolean
  closeInsteadOfCancel?: boolean
} & Omit<DialogProps, "open" | "onClose" | "fullScreen" | "fullWidth">

export function FullScreenDialogPage({
  closeRoute,
  children,
  ...dialogProps
}: { closeRoute: string } & Omit<FullScreenDialogT, "open" | "onClose">) {
  const [isOpen, setIsOpen] = useState(true)
  const navigate = useNavigate()

  const onClose = useCallback(() => {
    setIsOpen(false)
    navigate(closeRoute)
  }, [closeRoute, navigate])

  return (
    <FullScreenDialog open={isOpen} onClose={onClose} {...dialogProps}>
      {children}
    </FullScreenDialog>
  )
}

export type FullScreenDialogContextT = {
  processing: {
    isProcessing: boolean
    startProcessing: () => void
    stopProcessing: () => void
  }
  actions?: HTMLDivElement | null
}

export const FullScreenDialogContext = createContext<FullScreenDialogContextT>({
  processing: {
    isProcessing: false,
    startProcessing: () => {},
    stopProcessing: () => {},
  },
  actions: null,
})

export const useFullScreenDialogIsProcessing = () => {
  const {
    processing: { isProcessing },
  } = useContext(FullScreenDialogContext)
  return isProcessing
}

export const ClosableFullScreenDialog = (props: Omit<FullScreenDialogT, "primaryAction">) => {
  const { onClose } = props
  const primaryAction: ActionT = {
    label: t("common.close"),
    onClick: () => {
      onClose()
      return Promise.resolve()
    },
  }
  return <FullScreenDialog primaryAction={primaryAction} {...props} children={props.children} />
}

export const CustomActionsFullScreenDialog = ({ children }: { children: ReactNode }) => {
  const { actions } = useContext(FullScreenDialogContext)
  return <Portal container={actions}>{children}</Portal>
}

export function FullScreenDialog({
  open,
  onClose,
  isProcessing: isProcessingProp = false,
  title,
  children,
  primaryAction,
  secondaryAction,
  closeInsteadOfCancel = false,
  disableEnter = false,
  disableCancel = false,
  ...dialogProps
}: FullScreenDialogT) {
  const { isMobile } = useDeviceSize()
  const [innerIsProcessing, startInnerProcessing, stopInnerProcessing] = useTrueFalse()
  const isProcessing = isProcessingProp || innerIsProcessing
  const actionsRef = React.useRef<HTMLDivElement>(null)

  const innerOnClose = useCallback(() => {
    if (!isProcessing) {
      onClose()
    }
  }, [onClose, isProcessing])

  const actionFunc$ = useCallback(
    (actionFunc: ActionT["onClick"]) => (event: React.MouseEvent<HTMLButtonElement> | FormEvent) => {
      actionFunc(event)
        .then(() => {
          innerOnClose()
        })
        .catch((e) => {
          console.warn(e)
        })
    },
    [innerOnClose],
  )

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const primaryActionFunc = useCallback(actionFunc$(primaryAction.onClick), [primaryAction, actionFunc$])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const secondaryActionFunc = useCallback(secondaryAction ? actionFunc$(secondaryAction.onClick) : () => {}, [
    secondaryAction,
    actionFunc$,
  ])

  const primaryActionEnterFunc = useCallback(
    (event: React.MouseEvent<HTMLButtonElement> | FormEvent) => {
      if (!disableEnter) {
        event.preventDefault()
        return primaryActionFunc(event)
      }
    },
    [primaryActionFunc, disableEnter],
  )

  return (
    <Dialog open={open} onClose={innerOnClose} fullScreen={isMobile} fullWidth={true} {...dialogProps}>
      <FullScreenDialogContext.Provider
        value={{
          processing: { isProcessing, startProcessing: startInnerProcessing, stopProcessing: stopInnerProcessing },
          actions: actionsRef.current,
        }}
      >
        {isMobile && (
          <AppBar color="transparent" sx={{ position: "relative", mt: "env(safe-area-inset-top)" }}>
            {isProcessing && <LinearProgressTop />}
            <Toolbar>
              <IconButton edge="start" color="inherit" onClick={() => innerOnClose()} disabled={isProcessing}>
                <XMarkDuotoneIcon />
              </IconButton>
              <Box flexGrow={1}>
                {title && (
                  <Typography sx={{ ml: 2 }} variant="h6" component="div">
                    {title}
                  </Typography>
                )}
              </Box>
              <LoadingButton
                color={primaryAction.color ?? "primary"}
                variant={primaryAction.variant}
                disabled={primaryAction.disabled || isProcessing}
                onClick={primaryActionFunc}
              >
                {primaryAction.label}
              </LoadingButton>
            </Toolbar>
          </AppBar>
        )}
        {!isMobile && (
          <>
            {isProcessing && <LinearProgressTop />}
            {title && <DialogTitle>{title}</DialogTitle>}
          </>
        )}
        <DialogContent sx={{ pt: title ? undefined : 2 }}>
          <form onSubmit={primaryActionEnterFunc}>
            {children}
            <input type="submit" hidden />
          </form>
        </DialogContent>
        <DialogActions sx={{ mb: "env(safe-area-inset-bottom)" }}>
          {secondaryAction && (
            <LoadingButton
              color={secondaryAction.color}
              variant={secondaryAction.variant}
              disabled={secondaryAction.disabled || isProcessing}
              onClick={secondaryActionFunc}
              startIcon={secondaryAction.icon}
              loading={secondaryAction.loading}
            >
              {secondaryAction.label}
            </LoadingButton>
          )}
          <Box flexGrow={1} />
          <Box
            ref={actionsRef}
            sx={{
              "& > div:first-of-type": {
                display: "none",
              },
              "& > div:last-of-type": {
                display: "inherit",
              },
            }}
          >
            <Box component="div">
              {!disableCancel && (
                <Button color="subtle" disabled={isProcessing} onClick={() => innerOnClose()}>
                  {closeInsteadOfCancel ? t("common.close") : t("common.cancel")}
                </Button>
              )}
              <LoadingButton
                color={primaryAction.color ?? "primary"}
                variant={primaryAction.variant ?? "contained"}
                disabled={primaryAction.disabled || isProcessing}
                startIcon={primaryAction.icon}
                onClick={primaryActionFunc}
                loading={primaryAction.loading || isProcessing}
              >
                {primaryAction.label}
              </LoadingButton>
            </Box>
          </Box>
        </DialogActions>
      </FullScreenDialogContext.Provider>
    </Dialog>
  )
}
