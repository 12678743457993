import { primaryKey } from "@mswjs/data"
import { Overrides } from "../generators"
import { faker } from "@faker-js/faker/locale/pl"
import { RawStudentT } from "@/features/students/types/studentsQueriesTypes"
import { userGenerator, UserTypesEnumT } from "@/tests/server/db/models/userModel"
import { RawUserT } from "@/features/users/types/userTypes"
import { getNextId } from "@/tests/utils/getNextId"

/* TODO: It could have groupsId and userId also, and it could be used when getting groups students and their users,
    instead of saving fixed student list at start */
export type StudentModelT = {
  id: number
  data: string
}

export const studentModel = {
  id: primaryKey(Number),
  data: String,
}

export const studentWithUserGenerator = (overrides?: Overrides): [RawStudentT, RawUserT] => {
  const user = userGenerator([UserTypesEnumT.student])

  return [
    {
      id: getNextId("student"),
      user,
      isAdult: faker.datatype.boolean(),
      isActive: faker.datatype.boolean(),
      ...overrides,
    },
    user,
  ]
}
