import { AppBar, IconButton, Menu, styled, Theme, Toolbar, Typography } from "@mui/material"
import MenuIcon from "@mui/icons-material/Menu"
import MoreVertIcon from "@mui/icons-material/MoreVert"
import * as React from "react"
import { drawerWidth } from "./vars"
import { MenuItemT } from "@/types/menu"
import { MenuItems } from "@/components/Layout/Menu"
import { useTranslation } from "react-i18next"
import { createPortal } from "react-dom"

const APP_BAR_SUBTOOLBAR_ID = "app-bar-subtoolbar"

const BAR_SIZE_STYLE = (theme: Theme) => ({
  [theme.breakpoints.down("tablet")]: {
    minHeight: "56px",
  },
  [theme.breakpoints.up("tablet")]: {
    minHeight: "64px",
  },
})

const AppBarOffset = styled("div")(({ theme }) => ({
  ...theme.mixins.toolbar,
  ...BAR_SIZE_STYLE(theme),
  width: "100%",
}))

const MainAppBarBase = styled(AppBar)(({ theme }) => ({
  boxShadow: "0 1px 4px rgb(0 0 0 / 15%)",
  "& .MuiToolbar-root": {
    ...BAR_SIZE_STYLE(theme),
  },
}))

export const MainAppSubToolbar = ({ children }: { children: React.ReactNode }) => {
  const subToolbarElement = document.getElementById(APP_BAR_SUBTOOLBAR_ID)

  return subToolbarElement ? createPortal(<Toolbar>{children}</Toolbar>, subToolbarElement) : null
}

export function MainAppBar({
  title,
  actions = [],
  toggleSidebar,
}: {
  title: string
  actions: MenuItemT[]
  toggleSidebar: () => void
}) {
  const { t } = useTranslation()
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <MainAppBarBase
        id="main-app-bar"
        color={"inherit"}
        elevation={1}
        sx={{
          width: { md: `calc(100% - ${drawerWidth}px)` },
          ml: { md: `${drawerWidth}px` },
          pt: "env(safe-area-inset-top)",
        }}
      >
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            sx={{ mr: 2, display: { md: "none" } }}
            onClick={toggleSidebar}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" component="h1" fontWeight={700} sx={{ flexGrow: 1 }}>
            {t(title)}
          </Typography>
          <IconButton
            size="large"
            id="menu-appbar"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleMenu}
            color="inherit"
          >
            <MoreVertIcon />
          </IconButton>
          {actions && (
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorEl)}
              onClose={handleClose}
              onBlur={handleClose}
            >
              <MenuItems items={actions} />
            </Menu>
          )}
        </Toolbar>
        <div id={APP_BAR_SUBTOOLBAR_ID}></div>
      </MainAppBarBase>
      <AppBarOffset />
    </>
  )
}
