import * as React from "react"
import { CircularProgress } from "@mui/material"

export function Spinner() {
  return (
    <div className="flex items-center justify-center w-screen h-screen">
      <CircularProgress />
    </div>
  )
}
