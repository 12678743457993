import { FontAwesomeSvgIcon, FontAwesomeSvgIconTypeProps } from "@/components/Icons/FontAwesomeSVGIcon"

import NoteStickySVG from "@/assets/icons/fa/duotone/note-sticky.svg?react"
import UserSVG from "@/assets/icons/fa/duotone/user.svg?react"
import FileContractSVG from "@/assets/icons/fa/duotone/file-contract.svg?react"
import CalendarCheckSVG from "@/assets/icons/fa/duotone/calendar-check.svg?react"
import LanguageSVG from "@/assets/icons/fa/duotone/language.svg?react"
import CirclePlusIconSVG from "@/assets/icons/fa/duotone/circle-plus.svg?react"
import CircleXMarkIconSVG from "@/assets/icons/fa/duotone/circle-xmark.svg?react"
import MagnifyingGlassSVG from "@/assets/icons/fa/duotone/magnifying-glass.svg?react"
import ChevronsUpSVG from "@/assets/icons/fa/duotone/chevrons-up.svg?react"
import ChevronsDownSVG from "@/assets/icons/fa/duotone/chevrons-down.svg?react"
import XMarkSVG from "@/assets/icons/fa/duotone/xmark.svg?react"
import XMarkLargeSVG from "@/assets/icons/fa/duotone/xmark-large.svg?react"
import PenSVG from "@/assets/icons/fa/duotone/pen.svg?react"
import DeleteLeftSVG from "@/assets/icons/fa/duotone/delete-left.svg?react"
import TrashSVG from "@/assets/icons/fa/duotone/trash.svg?react"
import FilePenSVG from "@/assets/icons/fa/duotone/file-pen.svg?react"
import BookSVG from "@/assets/icons/fa/duotone/book.svg?react"
import CalendarDaysSVG from "@/assets/icons/fa/duotone/calendar-days.svg?react"
import HouseChimneySVG from "@/assets/icons/fa/duotone/house-chimney.svg?react"
import RightFromBracketSVG from "@/assets/icons/fa/duotone/right-from-bracket.svg?react"

export const CirclePlusDuotoneIcon = (props: FontAwesomeSvgIconTypeProps) => (
  <FontAwesomeSvgIcon {...props} icon={CirclePlusIconSVG} />
)

export const CircleXMarkDuotoneIcon = (props: FontAwesomeSvgIconTypeProps) => (
  <FontAwesomeSvgIcon {...props} icon={CircleXMarkIconSVG} />
)

export const MagnifyingGlassDuotoneIcon = (props: FontAwesomeSvgIconTypeProps) => (
  <FontAwesomeSvgIcon {...props} icon={MagnifyingGlassSVG} />
)

export const ChevronsUpDuotoneIcon = (props: FontAwesomeSvgIconTypeProps) => (
  <FontAwesomeSvgIcon {...props} icon={ChevronsUpSVG} />
)

export const ChevronsDownDuotoneIcon = (props: FontAwesomeSvgIconTypeProps) => (
  <FontAwesomeSvgIcon {...props} icon={ChevronsDownSVG} />
)

export const XMarkDuotoneIcon = (props: FontAwesomeSvgIconTypeProps) => (
  <FontAwesomeSvgIcon {...props} icon={XMarkSVG} />
)

export const XMarkLargeDuotoneIcon = (props: FontAwesomeSvgIconTypeProps) => (
  <FontAwesomeSvgIcon {...props} icon={XMarkLargeSVG} />
)

export const PenDuotoneIcon = (props: FontAwesomeSvgIconTypeProps) => <FontAwesomeSvgIcon {...props} icon={PenSVG} />

export const DeleteLeftDuotoneIcon = (props: FontAwesomeSvgIconTypeProps) => (
  <FontAwesomeSvgIcon {...props} icon={DeleteLeftSVG} />
)

export const TrashDuotoneIcon = (props: FontAwesomeSvgIconTypeProps) => (
  <FontAwesomeSvgIcon {...props} icon={TrashSVG} />
)

export const CalendarCheckDuotoneIcon = (props: FontAwesomeSvgIconTypeProps) => (
  <FontAwesomeSvgIcon {...props} icon={CalendarCheckSVG} />
)

export const FilePenDuotoneIcon = (props: FontAwesomeSvgIconTypeProps) => (
  <FontAwesomeSvgIcon {...props} icon={FilePenSVG} />
)

export const BookDuotoneIcon = (props: FontAwesomeSvgIconTypeProps) => <FontAwesomeSvgIcon {...props} icon={BookSVG} />

export const CalendarDaysDuotoneIcon = (props: FontAwesomeSvgIconTypeProps) => (
  <FontAwesomeSvgIcon {...props} icon={CalendarDaysSVG} />
)

export const HouseChimneyDuotoneIcon = (props: FontAwesomeSvgIconTypeProps) => (
  <FontAwesomeSvgIcon {...props} icon={HouseChimneySVG} />
)

export const RightFromBracketDuotoneIcon = (props: FontAwesomeSvgIconTypeProps) => (
  <FontAwesomeSvgIcon {...props} icon={RightFromBracketSVG} />
)

export const LanguageDuotoneIcon = (props: FontAwesomeSvgIconTypeProps) => (
  <FontAwesomeSvgIcon {...props} icon={LanguageSVG} />
)

export const FileContractDuotoneIcon = (props: FontAwesomeSvgIconTypeProps) => (
  <FontAwesomeSvgIcon {...props} icon={FileContractSVG} />
)
export const UserDuotoneIcon = (props: FontAwesomeSvgIconTypeProps) => <FontAwesomeSvgIcon {...props} icon={UserSVG} />

export const NoteStickyDuotoneIcon = (props: FontAwesomeSvgIconTypeProps) => (
  <FontAwesomeSvgIcon {...props} icon={NoteStickySVG} />
)
