import { PatchUserParametersT } from "@/features/users/types/userQueriesTypes"
import { axios } from "@/lib/axios"
import { useMutation } from "@tanstack/react-query"

export const patchUser = ({ oldPassword, newPassword, repeatPassword }: PatchUserParametersT) => {
  return axios.patch("/user", { oldPassword, password: newPassword, password2: repeatPassword })
}

export const useChangePasswordQ = () => {
  const mutation = useMutation({
    mutationFn: (params: PatchUserParametersT) => patchUser(params),
  })

  return {
    ...mutation,
    changePassword: mutation.mutate,
    changePasswordAsync: mutation.mutateAsync,
  }
}
