import { RawStudentNoteT, RawStudentT } from "@/features/students/types/studentsQueriesTypes"
import dayjs from "@/lib/dayjs"
import {
  usersStudentGeneratedMockData,
  usersStudentPredefinedMockData,
} from "@/features/users/tests/mock/usersMockData"
import mapValues from "lodash/mapValues"
import { generateMockStudent } from "@/features/students/tests/generators/studentMockGenerator"
import { EMPTY_USER } from "@/features/users/tests/mock/generators/userMockGenerator"
import keyBy from "lodash/keyBy"

export type PredefinedMockStudentTypesT =
  | "noPhoneNoEmail"
  | "hasEmailNoPhone"
  | "hasPhoneNoEmail"
  | "hasPhoneHasEmail"
  | "isAdult"
  | "isNotAdultNoParent"
  | "isNotAdultHasParent"
  | "isInactive"

const DEFAULT_STUDENT = {
  isAdult: true,
  isActive: true,
  user: EMPTY_USER,
}

const studentsPredefinedMockDataOverrides: Record<PredefinedMockStudentTypesT, Partial<RawStudentT>> = {
  noPhoneNoEmail: {
    ...DEFAULT_STUDENT,
    user: usersStudentPredefinedMockData.noPhoneNoEmail,
  },
  hasEmailNoPhone: {
    ...DEFAULT_STUDENT,
    user: usersStudentPredefinedMockData.hasEmailNoPhone,
  },
  hasPhoneNoEmail: {
    ...DEFAULT_STUDENT,
    user: usersStudentPredefinedMockData.hasPhoneNoEmail,
  },
  hasPhoneHasEmail: {
    ...DEFAULT_STUDENT,
    user: usersStudentPredefinedMockData.hasPhoneHasEmail,
  },
  isAdult: {
    ...DEFAULT_STUDENT,
    isAdult: true,
    user: usersStudentPredefinedMockData.isAdult,
  },
  isNotAdultNoParent: {
    ...DEFAULT_STUDENT,
    isAdult: false,
    user: usersStudentPredefinedMockData.isNotAdultNoParent,
  },
  isNotAdultHasParent: {
    ...DEFAULT_STUDENT,
    isAdult: false,
    user: usersStudentPredefinedMockData.isNotAdultHasParent,
  },
  isInactive: {
    ...DEFAULT_STUDENT,
    isActive: false,
    user: usersStudentPredefinedMockData.isInactive,
  },
}

export const studentsPredefinedMockData: Record<PredefinedMockStudentTypesT, RawStudentT> = mapValues(
  studentsPredefinedMockDataOverrides,
  (overrides) => generateMockStudent({}, overrides),
)

export const studentsMockData: RawStudentT[] = [
  ...Object.values(studentsPredefinedMockData),
  ...usersStudentGeneratedMockData.map((user) => generateMockStudent({ user })),
]

export const rawStudentNotesTestDataById: Record<number, RawStudentNoteT> = {
  1: {
    id: 1,
    noteType: {
      id: 1,
      name: "Notatka",
      color: "#000000",
    },
    studentId: studentsMockData[0].id,
    schoolClassId: 1,
    date: dayjs().add(-1, "day").toISOString(),
    content: "To jest testowa notatka. Nie może jej zobaczyć kursant, ani nie jest widoczna na opinii opisowej.",
    isStudentAllowed: false,
    isReportAllowed: false,
  },
  2: {
    id: 2,
    noteType: {
      id: 1,
      name: "Ocena",
      color: "#FF0000",
    },
    studentId: studentsMockData[0].id,
    schoolClassId: 1,
    date: dayjs().add(-2, "day").toISOString(),
    content: "To jest ocena opisowa. Widzi ją kursant, jest także widoczna na opinii opisowej.",
    isStudentAllowed: true,
    isReportAllowed: true,
  },
  3: {
    id: 3,
    noteType: {
      id: 1,
      name: "Opis",
      color: "#00FF00",
    },
    studentId: studentsMockData[0].id,
    schoolClassId: 1,
    date: dayjs().add(-3, "day").toISOString(),
    content: "A to jest uwaga. Widzi ją kursant, ale nie wchodzi w skład opinii opisowej.",
    isStudentAllowed: true,
    isReportAllowed: false,
  },
}

export const studentsMockDataById = keyBy(studentsMockData, "id")
