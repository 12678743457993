import { AppDataT, PasswordValidatorsT, RawAppDataT, RawPasswordValidatorsT } from "@/features/app/types/queries"
import { toColor, toString } from "@/utils/requestDataConverters"
export const transformPasswordValidators = (passwordValidators: RawPasswordValidatorsT): PasswordValidatorsT => ({
  minLength: passwordValidators.minLength?.length ?? 1,
  specialChar: passwordValidators.specialChar === null,
  numeric: passwordValidators.numeric === null,
  upperCase: passwordValidators.upperCase === null,
  lowerCase: passwordValidators.lowerCase === null,
})

export const transformAppData = (appData: RawAppDataT): AppDataT => ({
  ...appData,
  user: {
    ...appData.user,
    password: {
      ...appData.user.password,
      validators: transformPasswordValidators(appData.user.password.validators),
    },
  },
  teacher: {
    id: appData.teacher.id,
    color: toColor(appData.teacher.color),
  },
  locales: {
    currency: {
      code: toString(appData.locales.currencyCode),
      symbol: toString(appData.locales.currencySymbol),
    },
  },
})
