import { DefaultOptions, QueryClient } from "@tanstack/react-query"

export const QUERY_THROW_ERROR_HEADER = "X-Query-Throw-Error"

const queryConfig: DefaultOptions = {
  queries: {
    useErrorBoundary: (error: any) => {
      return error?.response?.headers[QUERY_THROW_ERROR_HEADER] !== "false"
    },
    refetchOnWindowFocus: false,
    retry: false,
    staleTime: 5 * 1000, // 5 seconds
  },
  // mutations: {
  //   useErrorBoundary: (error: any) => {
  //     return error?.response?.headers[QUERY_THROW_ERROR_HEADER] !== "false"
  //   },
  // },
}

export const queryClient = new QueryClient({ defaultOptions: queryConfig })
