import { Box, ListItemIcon, ListItemText, MenuList, Skeleton } from "@mui/material"
import { MenuItems } from "@/components/Layout/Menu"
import { MenuItemT } from "@/types/menu"
import { theme } from "@/theme/theme"
import { useMemo } from "react"
import { useNewCountsQ } from "@/features/app/api/getNewCounts"

interface MainMenuProps {
  items: MenuItemT[]
  onClick?: () => void
}

function MainMenuItemSkeleton() {
  return (
    <Skeleton sx={{ minWidth: "100%" }}>
      <Box>
        <ListItemIcon />
        <ListItemText>Loading...</ListItemText>
      </Box>
    </Skeleton>
  )
}

function MainMenuSkeleton() {
  return (
    <Box p={2}>
      <MenuList sx={{ display: "flex", flexDirection: "column" }}>
        <MainMenuItemSkeleton />
        <MainMenuItemSkeleton />
        <MainMenuItemSkeleton />
      </MenuList>
    </Box>
  )
}

export function MainMenu({ items, onClick = () => {} }: MainMenuProps) {
  const { counts } = useNewCountsQ()
  const itemsWithCount = useMemo(
    () =>
      (items ?? []).map((item) => {
        if (item.type === "nav") {
          const count: number | undefined = (counts as Record<any, any>)[item.id ?? ""]
          return { ...item, ...(count ? { count } : {}) }
        } else {
          return item
        }
      }),
    [counts, items]
  )
  if (!items || items.length === 0) {
    return <MainMenuSkeleton />
  }

  return (
    <MenuList
      sx={{
        "& .MuiMenuItem-root": {
          padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
          textTransform: "uppercase",
          fontSize: "0.95rem",
        },
        "& .MuiListItemText-root .MuiTypography-root": {
          fontSize: "14px",
        },
      }}
      onClick={onClick}
    >
      <MenuItems items={itemsWithCount} />
    </MenuList>
  )
}
