import { proxy, ref } from "valtio"
import { AlertDialogParametersI } from "@/components/Dialogs/AlertDialog"

type AlertStoreT = {
  visible: boolean
} & AlertDialogParametersI

const DEFAULT_VALUES: AlertStoreT = {
  visible: false,
  title: "",
  description: "",
  acceptLabel: "common.close",
  canCancel: false,
  isDangerous: false,
  onAccept: ref(() => {}),
  onReject: ref(() => {}),
}

export const alertStore = proxy<AlertStoreT>({
  ...DEFAULT_VALUES,
})

export function showAlert({
  description = DEFAULT_VALUES.description,
  title = DEFAULT_VALUES.title,
  acceptLabel = DEFAULT_VALUES.acceptLabel,
  canCancel = DEFAULT_VALUES.canCancel,
  isDangerous = DEFAULT_VALUES.isDangerous,
  onAccept = () => {},
  onReject = () => {},
}: Partial<Omit<AlertStoreT, "visible">>) {
  alertStore.visible = true
  alertStore.description = description
  alertStore.title = title
  alertStore.acceptLabel = acceptLabel
  alertStore.canCancel = canCancel
  alertStore.isDangerous = isDangerous
  alertStore.onAccept = ref(onAccept)
  alertStore.onReject = ref(onReject)
}

export function hideAlert() {
  alertStore.visible = false
}
