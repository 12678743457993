import { Model } from "@/tests/server/db"

const CURRENT_IDS: { [key in Model]?: number } = {}
const CURRENT_CUSTOM_IDS: { [key: string]: number } = {}
export const getNextId = (model: Model) => {
  if (!CURRENT_IDS[model]) {
    CURRENT_IDS[model] = 1
  }

  return CURRENT_IDS[model]!++
}
export const getCustomNextId = (type: string) => {
  if (!CURRENT_CUSTOM_IDS[type]) {
    CURRENT_CUSTOM_IDS[type] = 1
  }

  return CURRENT_CUSTOM_IDS[type]!++
}
