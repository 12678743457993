import { primaryKey } from "@mswjs/data"
import { faker } from "@faker-js/faker/locale/pl"
import { RawClassT } from "@/features/classes/types/classes-queries"
import { RawGroupT } from "@/features/groups/types/queries"
import { getNextId } from "@/tests/utils/getNextId"

export type AttendanceModelT = {
  id: number
  classId: number
  attendanceChecked: boolean
  homeworkChecked: boolean
  attendanceTable: string
}

export const attendance = {
  id: primaryKey(Number),
  classId: Number,
  attendanceChecked: Boolean,
  homeworkChecked: Boolean,
  attendanceTable: String,
}

export const attendanceGenerator = ({ class_, group }: { class_: RawClassT; group: RawGroupT }): AttendanceModelT => {
  // const groupStatuses = [
  //   // ...class_.studentsAbsenceStatus,
  //   // ...(class_.studentStatus.noHomework ? ["noHomework"] : []),
  // ]
  const { students } = group
  let attendanceTable: { [key: number]: { [key: string]: boolean } } | string = {}

  for (const student of students!) {
    attendanceTable[student.id] = {}
    // for (const status of groupStatuses) {
    //   attendanceTable[student.id][status[0]] = faker.datatype.boolean()
    // }
    attendanceTable[student.id]["noHomework"] = faker.datatype.boolean()
  }

  attendanceTable = JSON.stringify(attendanceTable)

  return {
    id: getNextId("attendance"),
    classId: class_.id,
    attendanceChecked: faker.datatype.boolean(),
    homeworkChecked: faker.datatype.boolean(),
    attendanceTable,
  }
}
