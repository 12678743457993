import { useTranslation } from "react-i18next"
import { useMap, useMount } from "react-use"
import { useCallback, useRef } from "react"
import Webcam from "react-webcam"
import { Alert, Button, CircularProgress, Stack } from "@mui/material"
import { RectangularBox } from "@/components/Layout/RectangularBox"
import { isAndroid } from "@/utils/IS_CORDOVA"

export const ProfileAvatarWebcam = ({
  cancel,
  takePhoto,
}: {
  cancel: () => void
  takePhoto: (photo: string | null) => void
}) => {
  const { t } = useTranslation()
  const [{ error, isInitialised, isPermitted }, { set }] = useMap({
    isPermitted: false,
    isInitialised: false,
    error: "",
  })
  const webcamRef = useRef<Webcam>(null)
  const takeScreenshot = useCallback(() => {
    const imageSrc = webcamRef.current?.getScreenshot()
    takePhoto(imageSrc ?? null)
  }, [takePhoto, webcamRef])

  useMount(() => {
    if (isAndroid())
      cordova.plugins.permissions.requestPermission(
        cordova.plugins.permissions.CAMERA,
        () => set("isPermitted", true),
        () => set("isPermitted", true),
      )
    else set("isPermitted", true)
  })

  return (
    <Stack>
      <RectangularBox width="100%" maxWidth={{ mobile: 480 }} alignSelf="center">
        {!isInitialised && !error && <CircularProgress />}
        {error && <Alert severity="error">{error}</Alert>}
        {isPermitted && (
          <Webcam
            ref={webcamRef}
            style={{ display: !isInitialised ? "none" : undefined }}
            width="100%"
            mirrored
            screenshotFormat="image/jpeg"
            onUserMedia={() => set("isInitialised", true)}
            onUserMediaError={(error) => set("error", error.toString())}
            videoConstraints={{
              width: 600,
              height: 600,
              facingMode: "user",
            }}
          />
        )}
      </RectangularBox>

      <Button size="large" disabled={!isInitialised} onClick={() => takeScreenshot()}>
        {t("profile.takePhoto")}
      </Button>
      <Button size="small" color="subtle" onClick={() => cancel()}>
        {t("profile.goBackWithoutPhoto")}
      </Button>
    </Stack>
  )
}
