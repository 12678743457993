import { FontAwesomeSvgIcon, FontAwesomeSvgIconTypeProps } from "@/components/Icons/FontAwesomeSVGIcon"
import { IconMotionDiv } from "@/components/Icons"

import ListTimelineSVG from "@/assets/icons/fa/solid/list-timeline.svg?react"
import ChartSimpleSVG from "@/assets/icons/fa/solid/chart-simple.svg?react"
import MemoCircleInfoSVG from "@/assets/icons/fa/solid/memo-circle-info.svg?react"
import MinusSVG from "@/assets/icons/fa/solid/minus.svg?react"
import FilePenSVG from "@/assets/icons/fa/solid/file-pen.svg?react"
import LeftSVG from "@/assets/icons/fa/solid/left.svg?react"
import FileSlashSVG from "@/assets/icons/fa/solid/file-slash.svg?react"
import FileLinesSVG from "@/assets/icons/fa/solid/file-lines.svg?react"
import SquarePollHorizontalSVG from "@/assets/icons/fa/solid/square-poll-horizontal.svg?react"
import NoteStickySVG from "@/assets/icons/fa/solid/note-sticky.svg?react"
import CircleExclamationSVG from "@/assets/icons/fa/solid/circle-exclamation.svg?react"
import PlusSVG from "@/assets/icons/fa/solid/plus.svg?react"
import CirclePlusSVG from "@/assets/icons/fa/solid/circle-plus.svg?react"
import NoteSVG from "@/assets/icons/fa/solid/note.svg?react"
import PhoneSVG from "@/assets/icons/fa/solid/phone.svg?react"
import ScreenUsersSVG from "@/assets/icons/fa/solid/screen-users.svg?react"
import FileInvoiceSVG from "@/assets/icons/fa/solid/file-invoice.svg?react"
import FileMagnifyingGlassSVG from "@/assets/icons/fa/solid/file-magnifying-glass.svg?react"
import FileContractSVG from "@/assets/icons/fa/solid/file-contract.svg?react"
import DownloadSVG from "@/assets/icons/fa/solid/download.svg?react"
import EnvelopeSVG from "@/assets/icons/fa/solid/envelope.svg?react"
import PaperclipSVG from "@/assets/icons/fa/solid/paperclip.svg?react"
import InboxSVG from "@/assets/icons/fa/solid/inbox.svg?react"
import ClockRotateLeftSVG from "@/assets/icons/fa/solid/clock-rotate-left.svg?react"
import XmarkSVG from "@/assets/icons/fa/solid/xmark.svg?react"
import BookUserSVG from "@/assets/icons/fa/solid/book-user.svg?react"
import CaretDownSVG from "@/assets/icons/fa/solid/caret-down.svg?react"
import CaretRightSVG from "@/assets/icons/fa/solid/caret-right.svg?react"
import ShareSVG from "@/assets/icons/fa/solid/share.svg?react"
import TrashSVG from "@/assets/icons/fa/solid/trash.svg?react"
import EllipsisVerticalSVG from "@/assets/icons/fa/solid/ellipsis-vertical.svg?react"
import ReplySVG from "@/assets/icons/fa/solid/reply.svg?react"
import RightToBracketSVG from "@/assets/icons/fa/solid/right-to-bracket.svg?react"
import EyeSlashSVG from "@/assets/icons/fa/solid/eye-slash.svg?react"
import EyeSVG from "@/assets/icons/fa/solid/eye.svg?react"
import CalendarXmarkSVG from "@/assets/icons/fa/solid/calendar-xmark.svg?react"
import CalendarCheckSVG from "@/assets/icons/fa/solid/calendar-check.svg?react"
import CalendarPenSVG from "@/assets/icons/fa/solid/calendar-pen.svg?react"
import ArrowRightArrowLeftSVG from "@/assets/icons/fa/solid/arrow-right-arrow-left.svg?react"
import BallotCheckSVG from "@/assets/icons/fa/solid/ballot-check.svg?react"
import FilesSVG from "@/assets/icons/fa/solid/files.svg?react"
import BookSVG from "@/assets/icons/fa/solid/book.svg?react"
import BanSVG from "@/assets/icons/fa/solid/ban.svg?react"
import ArrowRightToBracketSVG from "@/assets/icons/fa/solid/arrow-right-to-bracket.svg?react"
import PencilSVG from "@/assets/icons/fa/solid/pencil.svg?react"
import CheckSVG from "@/assets/icons/fa/solid/check.svg?react"
import CameraWebSVG from "@/assets/icons/fa/solid/camera-web.svg?react"
import UserSVG from "@/assets/icons/fa/solid/user.svg?react"
import CalendarSVG from "@/assets/icons/fa/solid/calendar.svg?react"
import LocationPinSVG from "@/assets/icons/fa/solid/location-pin.svg?react"
import BookmarkSVG from "@/assets/icons/fa/solid/bookmark.svg?react"
import AngleDownSVG from "@/assets/icons/fa/solid/angle-down.svg?react"

export const CollapseIcon = ({ collapsed, ...props }: { collapsed: boolean } & FontAwesomeSvgIconTypeProps) => (
  <IconMotionDiv animate={{ rotate: collapsed ? 0 : 180 }}>
    <FontAwesomeSvgIcon {...props} fontSize="inherit" icon={AngleDownSVG} />
  </IconMotionDiv>
)

export const PlusSolidIcon = (props: FontAwesomeSvgIconTypeProps) => <FontAwesomeSvgIcon {...props} icon={PlusSVG} />

export const BookmarkSolidIcon = (props: FontAwesomeSvgIconTypeProps) => (
  <FontAwesomeSvgIcon {...props} icon={BookmarkSVG} />
)
export const LocationPinSolidIcon = (props: FontAwesomeSvgIconTypeProps) => (
  <FontAwesomeSvgIcon {...props} icon={LocationPinSVG} />
)
export const CalendarSolidIcon = (props: FontAwesomeSvgIconTypeProps) => (
  <FontAwesomeSvgIcon {...props} icon={CalendarSVG} />
)
export const UserSolidIcon = (props: FontAwesomeSvgIconTypeProps) => <FontAwesomeSvgIcon {...props} icon={UserSVG} />
export const CameraWebSolidIcon = (props: FontAwesomeSvgIconTypeProps) => (
  <FontAwesomeSvgIcon {...props} icon={CameraWebSVG} />
)
export const CheckSolidIcon = (props: FontAwesomeSvgIconTypeProps) => <FontAwesomeSvgIcon {...props} icon={CheckSVG} />
export const PencilSolidIcon = (props: FontAwesomeSvgIconTypeProps) => (
  <FontAwesomeSvgIcon {...props} icon={PencilSVG} />
)
export const ArrowRightToBracketSolidIcon = (props: FontAwesomeSvgIconTypeProps) => (
  <FontAwesomeSvgIcon {...props} icon={ArrowRightToBracketSVG} />
)
export const BanSolidIcon = (props: FontAwesomeSvgIconTypeProps) => <FontAwesomeSvgIcon {...props} icon={BanSVG} />
export const BookSolidIcon = (props: FontAwesomeSvgIconTypeProps) => <FontAwesomeSvgIcon {...props} icon={BookSVG} />
export const FilesSolidIcon = (props: FontAwesomeSvgIconTypeProps) => <FontAwesomeSvgIcon {...props} icon={FilesSVG} />
export const BallotCheckSolidIcon = (props: FontAwesomeSvgIconTypeProps) => (
  <FontAwesomeSvgIcon {...props} icon={BallotCheckSVG} />
)
export const ArrowRightArrowLeftSolidIcon = (props: FontAwesomeSvgIconTypeProps) => (
  <FontAwesomeSvgIcon {...props} icon={ArrowRightArrowLeftSVG} />
)
export const CalendarPenSolidIcon = (props: FontAwesomeSvgIconTypeProps) => (
  <FontAwesomeSvgIcon {...props} icon={CalendarPenSVG} />
)
export const CalendarCheckSolidIcon = (props: FontAwesomeSvgIconTypeProps) => (
  <FontAwesomeSvgIcon {...props} icon={CalendarCheckSVG} />
)
export const CalendarXmarkSolidIcon = (props: FontAwesomeSvgIconTypeProps) => (
  <FontAwesomeSvgIcon {...props} icon={CalendarXmarkSVG} />
)

export const EyeSolidIcon = (props: FontAwesomeSvgIconTypeProps) => <FontAwesomeSvgIcon {...props} icon={EyeSVG} />
export const EyeSlashSolidIcon = (props: FontAwesomeSvgIconTypeProps) => (
  <FontAwesomeSvgIcon {...props} icon={EyeSlashSVG} />
)
export const RightToBracketSolidIcon = (props: FontAwesomeSvgIconTypeProps) => (
  <FontAwesomeSvgIcon {...props} icon={RightToBracketSVG} />
)
export const ReplySolidIcon = (props: FontAwesomeSvgIconTypeProps) => <FontAwesomeSvgIcon {...props} icon={ReplySVG} />
export const EllipsisVerticalSolidIcon = (props: FontAwesomeSvgIconTypeProps) => (
  <FontAwesomeSvgIcon {...props} icon={EllipsisVerticalSVG} />
)
export const TrashSolidIcon = (props: FontAwesomeSvgIconTypeProps) => <FontAwesomeSvgIcon {...props} icon={TrashSVG} />
export const ShareSolidIcon = (props: FontAwesomeSvgIconTypeProps) => <FontAwesomeSvgIcon {...props} icon={ShareSVG} />
export const CaretRightSolidIcon = (props: FontAwesomeSvgIconTypeProps) => (
  <FontAwesomeSvgIcon {...props} icon={CaretRightSVG} />
)
export const CaretDownSolidIcon = (props: FontAwesomeSvgIconTypeProps) => (
  <FontAwesomeSvgIcon {...props} icon={CaretDownSVG} />
)
export const BookUserSolidIcon = (props: FontAwesomeSvgIconTypeProps) => (
  <FontAwesomeSvgIcon {...props} icon={BookUserSVG} />
)
export const XmarkSolidIcon = (props: FontAwesomeSvgIconTypeProps) => <FontAwesomeSvgIcon {...props} icon={XmarkSVG} />
export const ClockRotateLeftSolidIcon = (props: FontAwesomeSvgIconTypeProps) => (
  <FontAwesomeSvgIcon {...props} icon={ClockRotateLeftSVG} />
)
export const InboxSolidIcon = (props: FontAwesomeSvgIconTypeProps) => <FontAwesomeSvgIcon {...props} icon={InboxSVG} />
export const PaperclipSolidIcon = (props: FontAwesomeSvgIconTypeProps) => (
  <FontAwesomeSvgIcon {...props} icon={PaperclipSVG} />
)
export const EnvelopeSolidIcon = (props: FontAwesomeSvgIconTypeProps) => (
  <FontAwesomeSvgIcon {...props} icon={EnvelopeSVG} />
)
export const DownloadSolidIcon = (props: FontAwesomeSvgIconTypeProps) => (
  <FontAwesomeSvgIcon {...props} icon={DownloadSVG} />
)
export const FileContractSolidIcon = (props: FontAwesomeSvgIconTypeProps) => (
  <FontAwesomeSvgIcon {...props} icon={FileContractSVG} />
)
export const FileMagnifyingGlassSolidIcon = (props: FontAwesomeSvgIconTypeProps) => (
  <FontAwesomeSvgIcon {...props} icon={FileMagnifyingGlassSVG} />
)
export const FileInvoiceSolidIcon = (props: FontAwesomeSvgIconTypeProps) => (
  <FontAwesomeSvgIcon {...props} icon={FileInvoiceSVG} />
)
export const ScreenUsersSolidIcon = (props: FontAwesomeSvgIconTypeProps) => (
  <FontAwesomeSvgIcon {...props} icon={ScreenUsersSVG} />
)

export const PhoneSolidIcon = (props: FontAwesomeSvgIconTypeProps) => <FontAwesomeSvgIcon {...props} icon={PhoneSVG} />
export const NoteSolidIcon = (props: FontAwesomeSvgIconTypeProps) => <FontAwesomeSvgIcon {...props} icon={NoteSVG} />
export const CirclePlusSolidIcon = (props: FontAwesomeSvgIconTypeProps) => (
  <FontAwesomeSvgIcon {...props} icon={CirclePlusSVG} />
)

export const CircleExclamationSolidIcon = (props: FontAwesomeSvgIconTypeProps) => (
  <FontAwesomeSvgIcon {...props} icon={CircleExclamationSVG} />
)
export const NoteStickySolidIcon = (props: FontAwesomeSvgIconTypeProps) => (
  <FontAwesomeSvgIcon {...props} icon={NoteStickySVG} />
)
export const SquarePollHorizontalSolidIcon = (props: FontAwesomeSvgIconTypeProps) => (
  <FontAwesomeSvgIcon {...props} icon={SquarePollHorizontalSVG} />
)
export const FileLinesSolidIcon = (props: FontAwesomeSvgIconTypeProps) => (
  <FontAwesomeSvgIcon {...props} icon={FileLinesSVG} />
)
export const FileSlashSolidIcon = (props: FontAwesomeSvgIconTypeProps) => (
  <FontAwesomeSvgIcon {...props} icon={FileSlashSVG} />
)
export const LeftSolidIcon = (props: FontAwesomeSvgIconTypeProps) => <FontAwesomeSvgIcon {...props} icon={LeftSVG} />
export const FilePenSolidIcon = (props: FontAwesomeSvgIconTypeProps) => (
  <FontAwesomeSvgIcon {...props} icon={FilePenSVG} />
)
export const MinusSolidIcon = (props: FontAwesomeSvgIconTypeProps) => <FontAwesomeSvgIcon {...props} icon={MinusSVG} />
export const MemoCircleInfoSolidIcon = (props: FontAwesomeSvgIconTypeProps) => (
  <FontAwesomeSvgIcon {...props} icon={MemoCircleInfoSVG} />
)
export const ChartSimpleSolidIcon = (props: FontAwesomeSvgIconTypeProps) => (
  <FontAwesomeSvgIcon {...props} icon={ChartSimpleSVG} />
)
export const ListTimelineSolidIcon = (props: FontAwesomeSvgIconTypeProps) => (
  <FontAwesomeSvgIcon {...props} icon={ListTimelineSVG} />
)
