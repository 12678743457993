import { useSearchParams } from "react-router-dom"

export const useUpdateSearchParams = () => {
  const [params, setSearchParams] = useSearchParams()

  const updateParams = (updatedParams: Record<string, string | null>) => {
    const newParams = new URLSearchParams(params)

    for (const [key, value] of Object.entries(updatedParams)) {
      if (value === null) {
        newParams.delete(key)
      } else {
        newParams.set(key, value)
      }
    }

    setSearchParams(newParams)
  }

  return {
    params,
    updateParams,
  }
}

export const setSearchParam$ = (key: string, value: string) => (currentParams: URLSearchParams) => {
  const newParams = new URLSearchParams(currentParams)
  newParams.set(key, value)
  return newParams
}

export const setSearchParams$ = (params: [key: string, value: string][]) => (currentParams: URLSearchParams) => {
  const newParams = new URLSearchParams(currentParams)
  for (const [key, value] of params) newParams.set(key, value)
  return newParams
}

export const removeSearchParam$ = (keys: string | string[]) => (currentParams: URLSearchParams) => {
  if (typeof keys === "string") {
    keys = [keys]
  }

  const newParams = new URLSearchParams(currentParams)
  for (const key of keys) {
    newParams.delete(key)
  }
  return newParams
}
