import { faker } from "@faker-js/faker/locale/pl"
import { GradesTypeMappingsT, GradesTypeSpecialMappingsT } from "@/features/grades/types/grades"
import { primaryKey } from "@mswjs/data"
import { getNextId } from "@/tests/utils/getNextId"

export type RawGradesTypeT = {
  id: number
  availableMarks: GradesTypeMappingsT
  availableSpecialMarks: GradesTypeSpecialMappingsT
}

export type GradesTypeModelT = {
  id: number
  groupId: number
  data: string | RawGradesTypeT
}

export const groupGradesTypeModel = {
  id: primaryKey(Number),
  groupId: Number,
  data: String,
}

const MAPPINGS: GradesTypeMappingsT[] = [
  {
    1: 1,
    "1+": 1.5,
    "2": 2,
    "2+": 2.5,
    "2-": 1.75,
    "3": 3,
    "3+": 3.5,
    "3-": 2.75,
    "4": 4,
    "4+": 4.5,
    "4-": 3.75,
    "5": 5,
    "5+": 5.5,
    "5-": 4.75,
    "6": 6,
    "6-": 5.75,
  },
  {
    1: 1,
    "2": 2,
    "3": 3,
    "4": 4,
    "5": 5,
    "6": 6,
  },
]

const SPECIAL_MAPPINGS: GradesTypeSpecialMappingsT[] = [
  {
    A: "6",
    B: "5",
    C: "4",
    D: "3",
    E: "2",
    F: "1",
  },
]
export function getMappings() {
  return faker.helpers.arrayElement(MAPPINGS)
}

export function getSpecialMappings() {
  return faker.datatype.boolean() ? faker.helpers.arrayElement(SPECIAL_MAPPINGS) : {}
}

export const groupGradesTypeGenerator = (): RawGradesTypeT => {
  return {
    id: getNextId("grades_type"),
    availableMarks: getMappings(),
    availableSpecialMarks: getSpecialMappings(),
  }
}
