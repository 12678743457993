import { Box, Button, FormLabel, Stack } from "@mui/material"
import { useTranslation } from "react-i18next"
import { useEditProfileAvatarDialog } from "@/features/users/hooks/useEditProfileAvatarDialog"

import { EditProfileAvatarSettingsDialog } from "@/features/users/components/profile/avatar/EditProfileAvatarSettingsDialog"
import { useGetProfileAvatarQ } from "@/features/users/api/getProfileAvatar"

export const ProfileAvatarSettings = () => {
  const { t } = useTranslation()
  const { editProfileAvatar } = useEditProfileAvatarDialog()
  const { avatar } = useGetProfileAvatarQ()

  return (
    <>
      <EditProfileAvatarSettingsDialog />
      <Stack gap={1} width={{ mobile: "100%", tablet: "200px" }}>
        <FormLabel>{t("profile.profilePhoto")}</FormLabel>
        <Box
          bgcolor={(theme) => theme.palette.grey["100"]}
          width={{ mobile: "calc(100vw - 48px)", tablet: "100%" }}
          height={{ mobile: "calc(100vw - 48px)", tablet: "auto" }}
          minHeight={{ tablet: "200px" }}
          maxHeight="200px"
          maxWidth="200px"
        >
          {avatar && avatar.image && (
            <img style={{ display: "block", width: "100%" }} src={avatar.image} alt={t("profile.profilePhoto")} />
          )}
        </Box>
        <Button variant="contained" color="primary" onClick={() => editProfileAvatar()}>
          {t("profile.changePhoto")}
        </Button>
      </Stack>
    </>
  )
}
