import { useTranslation } from "react-i18next"
import { useToS } from "@/features/app/api/getTos"
import { useAppQ } from "@/features/app/api/getApp"
import { Button, ButtonProps, Dialog, DialogActions, DialogContent, Tooltip } from "@mui/material"
import { LanguagesShortT } from "@/types/languages"
import { LANGUAGE_CODE_LONG_TO_SHORT } from "@/types/enums/LANGUAGES_LONG"
import { useUpdateProfileQ } from "@/features/users/api/patchProfile"
import { LinearProgressTop } from "@/components/Elements/Fallbacks/LinearProgress"
import React from "react"
import { useBoolean } from "react-use"
import { useLogout } from "@/features/auth/hooks/useLogout"

export const ShowToSButton = (props: ButtonProps) => {
  const { t } = useTranslation()
  const [open, toggle] = useBoolean(false)

  return (
    <>
      <ToSDialog open={open} onClose={() => toggle(false)} />
      <Button {...props} onClick={() => toggle()}>
        {t("tos.show")}
      </Button>
    </>
  )
}

export const ToSDialog = ({
  open = true,
  isAccepted = true,
  onAccept = () => {},
  onDecline = () => {},
  onClose = () => {},
}: {
  open?: boolean
  isAccepted?: boolean
  onAccept?: () => void
  onDecline?: () => void
  onClose?: () => void
}) => {
  const { t, i18n } = useTranslation()
  const { data: tosData, isFetched } = useToS()

  const language: LanguagesShortT = i18n.language as LanguagesShortT
  const tosContent: string = tosData?.tos[LANGUAGE_CODE_LONG_TO_SHORT[language]] || ""

  return (
    <Dialog open={open} fullWidth maxWidth="md" onClose={isAccepted ? onClose : undefined}>
      {!isFetched && <LinearProgressTop />}
      <DialogContent>
        <div dangerouslySetInnerHTML={{ __html: tosContent }} />
      </DialogContent>
      {!isAccepted && (
        <DialogActions>
          <Tooltip arrow placement="top" title={t("tos.decliningResult")}>
            <Button disabled={!isFetched} size="large" onClick={() => onDecline()}>
              {t("common.decline")}
            </Button>
          </Tooltip>
          <Button disabled={!isFetched} size="large" onClick={() => onAccept()}>
            {t("common.accept")}
          </Button>
        </DialogActions>
      )}
      {isAccepted && (
        <DialogActions>
          <Button size="large" onClick={() => onClose()}>
            {t("common.close")}
          </Button>
        </DialogActions>
      )}
    </Dialog>
  )
}

export function ToSAcceptance() {
  const { data: appData, isLoading: isAppLoading } = useAppQ()
  const { acceptToS } = useUpdateProfileQ()
  const logout = useLogout()

  if (isAppLoading || appData?.user?.tosAccepted) {
    return null
  }

  return <ToSDialog isAccepted={false} onAccept={acceptToS} onDecline={logout} />
}
