import { APP_VERSION } from "@/config"
import { Box, Typography } from "@mui/material"
import * as React from "react"
import { useTranslation } from "react-i18next"

export const AppVersion = () => {
  const { t } = useTranslation()
  return (
    <Box display="flex" width="100%" justifyContent="center">
      <Typography variant="subtitle2">
        {t("common.version_abbr")} {APP_VERSION}
      </Typography>
    </Box>
  )
}
