import { Box, BoxProps } from "@mui/material"

export const RectangularBox = ({ children, ...props }: BoxProps) => (
  <Box {...props}>
    <Box width="100%" paddingBottom="100%" position="relative">
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        {children}
      </Box>
    </Box>
  </Box>
)
