import { ElementType, FC, forwardRef } from "react"
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon"
import { ElementColorT } from "@/types/enums/ELEMENT_COLORS"

export type FontAwesomeSvgIconProps = {
  icon: ElementType<any>
  customColor?: ElementColorT
  size?: string
} & SvgIconProps

export type FontAwesomeSvgIconTypeProps = Omit<FontAwesomeSvgIconProps, "icon">

export type FontAwesomeIconComponent = FC<Omit<FontAwesomeSvgIconProps, "icon">>
export const FontAwesomeSvgIcon = forwardRef<SVGSVGElement, FontAwesomeSvgIconProps>(
  ({ icon, customColor, size = "1em", sx, ...other }, ref) => {
    return (
      <SvgIcon
        ref={ref}
        {...other}
        component={icon}
        inheritViewBox={true}
        sx={[
          (theme) => ({
            height: size,
            width: size,
            color: customColor ? theme.palette.elements[customColor].icon : undefined,
          }),
          ...(Array.isArray(sx) ? sx : [sx]),
        ]}
      />
    )
  }
)
