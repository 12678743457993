import { LOGIN_URL } from "@/config"
import { Navigate } from "react-router-dom"
import * as React from "react"

export function RedirectToLogin() {
  if (LOGIN_URL[0] === "/") {
    return <Navigate to={LOGIN_URL} />
  } else {
    window.location.href = LOGIN_URL
    return null
  }
}

export function RedirectToInstanceSelection() {
  return <Navigate to="/instance" />
}
