import PLIconSVG from "@/assets/icons/flags/pl.svg?react"
import GBIconSVG from "@/assets/icons/flags/gb.svg?react"
import { SvgIconProps } from "@mui/material/SvgIcon"
import { FontAwesomeSvgIcon } from "../FontAwesomeSVGIcon"
import { LanguagesLongT } from "@/types/languages"
import { ElementType } from "react"

export const FlagPLIcon = (props: SvgIconProps) => <FontAwesomeSvgIcon {...props} icon={PLIconSVG} />

export const FlagGBIcon = (props: SvgIconProps) => <FontAwesomeSvgIcon {...props} icon={GBIconSVG} />

type FlagComponentsT = {
  [key in LanguagesLongT]: ElementType<SvgIconProps>
}

export const FLAG_COMPONENTS: FlagComponentsT = {
  pl_PL: FlagPLIcon,
  en_GB: FlagGBIcon,
}
