import { primaryKey } from "@mswjs/data"
import { Overrides } from "@/tests/server/db/generators"
import { faker } from "@faker-js/faker/locale/pl"

import { RawGradesColumnT, RawGradeT } from "@/features/grades/types/grades-queries"
import { RawGroupT } from "@/features/groups/types/queries"
import { RawStudentT } from "@/features/students/types/studentsQueriesTypes"
import { toNumber } from "@/utils/requestDataConverters"
import dayjs from "@/lib/dayjs"
import { DATE_FORMAT } from "@/types/data"
import { RawGradesTypeT } from "@/tests/server/db/models/gradesTypeModel"
import { getNextId } from "@/tests/utils/getNextId"

export type GradeModelT = {
  id: number
  groupId: number
  columnId: number
  studentId: number
  data: string | RawGradesColumnT
}

export const gradeModel = {
  id: primaryKey(Number),
  groupId: Number,
  columnId: Number,
  studentId: Number,
  data: String,
}

function getPercentageGrade(max: number): [string, number] {
  const grade = faker.number.int({ min: 0, max })
  return [grade.toString(), grade]
}

function getGrade(type: RawGradesTypeT): [string, number] {
  const marks = Object.keys(type.availableMarks)
  const specialMarks = Object.keys(type.availableSpecialMarks)
  if (specialMarks.length > 0 && faker.datatype.boolean()) {
    const mark = faker.helpers.arrayElement(specialMarks)
    return [mark, type.availableMarks[type.availableSpecialMarks[mark]]]
  } else {
    const mark = faker.helpers.arrayElement(marks)
    return [mark, type.availableMarks[mark]]
  }
}

export const gradeGenerator = (
  {
    group,
    gradesType,
    column,
    student,
  }: {
    group: RawGroupT
    gradesType: RawGradesTypeT
    column: RawGradesColumnT
    student: RawStudentT
  },
  overrides?: Overrides,
): RawGradeT => {
  const [grade, value] =
    group.pointSystemTypeName === "percent" ? getPercentageGrade(toNumber(column.max)) : getGrade(gradesType)

  return {
    id: getNextId("grade"),
    value,
    valueFormatted: grade,
    student,
    markGroup: column,
    date: dayjs(faker.date.recent({ days: 30 })).format(DATE_FORMAT),
    ...overrides,
  }
}
