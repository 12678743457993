import loadable from "@loadable/component"
import React from "react"
import { LinearProgressFallback } from "@/components/Elements/Fallbacks/LinearProgress"
import { SpinnerFallback } from "@/components/Elements/Fallbacks/Spinner"

export const loadableWithSpinner = (loadFn: any, options?: any) => {
  return loadable(loadFn, {
    fallback: <SpinnerFallback />,
    ...options,
  })
}

export const loadableWithLinearProgress = (loadFn: any, options?: any) => {
  return loadable(loadFn, {
    fallback: <LinearProgressFallback />,
    ...options,
  })
}
