import { ProfileT, RawProfileT } from "@/features/users/types/profileTypes"
import { toBoolean } from "@/utils/requestDataConverters"

export const transformProfile = (rawProfile: RawProfileT): ProfileT => ({
  login: rawProfile.login,
  firstName: rawProfile.firstName,
  lastName: rawProfile.lastName,
  phone: rawProfile.phone ?? "",
  email: rawProfile.mail ?? "",
  tosAccepted: toBoolean(rawProfile.tosAccepted),
})
