import { PasswordValidatorsT, PasswordValidatorTypesT } from "@/features/app/types/queries"
import { Chip, Stack, StackProps } from "@mui/material"
import { useTranslation } from "react-i18next"
import { useValidatePassword } from "@/features/users/hooks/useValidatePassword"
import { CheckSolidIcon, XmarkSolidIcon } from "@/components/Icons"

export const PasswordRequirements = ({
  password,
  validators,
  ...props
}: { password: string; validators: PasswordValidatorsT } & StackProps) => {
  const { t } = useTranslation()
  const { errors } = useValidatePassword(password, validators)

  return (
    <Stack useFlexGap direction="row" spacing={1} alignSelf="center" flexWrap="wrap" {...props}>
      {Object.entries(validators)
        .filter(([, value]) => !!value)
        .map(([type, value]) => {
          const isValid = !errors[type as PasswordValidatorTypesT]
          return (
            <Chip
              key={type}
              icon={isValid ? <CheckSolidIcon fontSize="small" /> : <XmarkSolidIcon fontSize="small" />}
              color={isValid ? "success" : "error"}
              // t("form.validators.minLength", { value: 8 })
              // t("form.validators.specialChar")
              // t("form.validators.numeric")
              // t("form.validators.upperCase")
              // t("form.validators.lowerCase")
              label={t(`form.validators.${type}`, { value })}
              variant={isValid ? "filled" : "outlined"}
            />
          )
        })}
    </Stack>
  )
}
