import { useSearchParams } from "react-router-dom"
import { NavigationParametersEnum } from "@/types/enums/SearchParametersEnums"
import { usePrevious } from "react-use"

export function useNavigationTab<T extends string>(
  navigationParameter: NavigationParametersEnum,
  values: T[],
  options: {
    cleanParams: string[]
  } = {
    cleanParams: [],
  },
): {
  currentTab: T
  changeTab: (newTab: T) => void
} {
  const [params, setSearchParams] = useSearchParams()
  const changeCurrentTab = (newTab: T) =>
    setSearchParams((currentParams) => {
      const newParams = new URLSearchParams(currentParams)
      newParams.set(navigationParameter, newTab)
      for (const param of options.cleanParams) {
        newParams.delete(param)
      }
      return newParams
    })

  const param = params.get(navigationParameter)
  const paramTab = param && values.includes(param as T) ? (param as T) : undefined
  const prevTab = usePrevious(paramTab)
  const currentTab = paramTab ?? prevTab ?? values[0]

  return {
    currentTab,
    changeTab: changeCurrentTab,
  }
}
