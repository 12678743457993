import { RawGroupT } from "@/features/groups/types/queries"
import keyBy from "lodash/keyBy"
import { generateGroupMock, MockLanguagesEnum } from "@/features/groups/tests/groupsMockGenerator"
import { studentsMockData, studentsPredefinedMockData } from "@/features/students/tests/studentsMockData"
import { range } from "lodash"
import { MOCK_API_ONLY_PREDEFINED } from "@/config"

export const MOCK_GROUPS_COUNT = 20

export const DEFAULT_GROUP: Partial<RawGroupT> = {
  startDate: "2023-01-01",
  endDate: "2023-12-31",
  schoolId: 1,
  pointSystemTypeName: "normal",
  isHomeworkEnabled: true,
  isLessonDetailsPublishingEnabled: true,
  isPlanned: false,
  students: [],
}

export const groupsPredefinedMockData: RawGroupT[] = [
  generateGroupMock(
    {},
    {
      ...DEFAULT_GROUP,
      name: `${MockLanguagesEnum.english} 0`,
      students: [
        studentsPredefinedMockData.noPhoneNoEmail,
        studentsPredefinedMockData.hasEmailNoPhone,
        studentsPredefinedMockData.hasPhoneNoEmail,
      ],
    },
  ),
  generateGroupMock(
    {},
    {
      ...DEFAULT_GROUP,
      name: `${MockLanguagesEnum.german} 0`,
      students: [
        studentsPredefinedMockData.hasPhoneHasEmail,
        studentsPredefinedMockData.isAdult,
        studentsPredefinedMockData.isNotAdultNoParent,
      ],
    },
  ),
  generateGroupMock(
    {},
    {
      ...DEFAULT_GROUP,
      name: `${MockLanguagesEnum.italian} 0`,
      students: [
        studentsPredefinedMockData.isNotAdultNoParent,
        studentsPredefinedMockData.isNotAdultHasParent,
        studentsPredefinedMockData.isInactive,
      ],
    },
  ),
]

export const groupsMockData: RawGroupT[] = [
  ...groupsPredefinedMockData,
  ...(MOCK_API_ONLY_PREDEFINED
    ? []
    : range(MOCK_GROUPS_COUNT).map(() =>
        generateGroupMock({
          students: studentsMockData,
        }),
      )),
]

export const groupsMockDataById: Record<number, RawGroupT> = keyBy(groupsMockData, "id")
