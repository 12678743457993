import * as React from "react"
import { Box, Container, CssBaseline } from "@mui/material"
import { useToggle } from "react-use"
import { Sidebar } from "./Sidebar"
import { ProfileBarProps } from "@/components/Layout/ProfileBar"
import { MainAppBar } from "@/components/Layout/MainAppBar"
import { BottomAppNavigation } from "@/components/Layout/BottomAppNavigation"
import { MenuItemLinkPropsT, MenuItemT } from "@/types/menu"
import { Helmet } from "react-helmet-async"
import { useTranslation } from "react-i18next"
import { useDeviceSize } from "@/hooks/useDeviceSize"
import { drawerWidth } from "./vars"

interface MainLayoutI {
  children: React.ReactNode
  title: string
  bottomMenuItems: MenuItemLinkPropsT[]
  topMenuItems: MenuItemT[]
  mainMenuItems: MenuItemT[]
  profile: ProfileBarProps
}

export function MainLayout({
  children,
  title,
  bottomMenuItems,
  topMenuItems,
  mainMenuItems,
  profile,
}: MainLayoutI) {
  const { t } = useTranslation()
  const { isMobile } = useDeviceSize()
  const [isSidebarOpen, toggleSidebar] = useToggle(false)

  return (
    <Box className="MainLayout" minHeight="100vh">
      <Helmet>
        <title>{t(title)}</title>
      </Helmet>
      <CssBaseline />
      <Sidebar
        menuItems={mainMenuItems}
        profile={profile}
        isOpen={isSidebarOpen}
        toggle={toggleSidebar}
      />
      <MainAppBar
        title={title}
        toggleSidebar={toggleSidebar}
        actions={topMenuItems}
      />
      <Box
        sx={{
          ml: { tablet: `${drawerWidth}px` },
          mt: "env(safe-area-inset-top)",
        }}
      >
        <Container
          disableGutters
          sx={{
            pl: { mobile: 1, tablet: 2 },
            pr: { mobile: 1, tablet: 2 },
            pt: { mobile: 4 },
            pb: {
              mobile: `calc(56px + env(safe-area-inset-bottom))`,
              tablet: 0,
            },
          }}
        >
          {children}
        </Container>
      </Box>
      {isMobile && (
        <BottomAppNavigation
          menuItems={bottomMenuItems}
          toggleSidebar={toggleSidebar}
          isSidebarOpen={isSidebarOpen}
        />
      )}
    </Box>
  )
}
