import { factory, primaryKey } from "@mswjs/data"
import { gradeModel } from "./models/gradeModel"
import { studentModel } from "./models/studentModel"
import { groupModel } from "./models/groupModel"
import { generateData } from "@/tests/server/db/generators"
import { teacherModel } from "@/tests/server/db/models/teacherModel"
import { classModel } from "@/tests/server/db/models/classModel"
import { attendance } from "@/tests/server/db/models/attendance"
import { groupGradesTypeModel } from "@/tests/server/db/models/gradesTypeModel"
import { gradesColumnModel } from "@/tests/server/db/models/gradeColumnModel"
import { userModel } from "@/tests/server/db/models/userModel"
import { messageModel } from "@/tests/server/db/models/messageModel"
import { DEBUG } from "@/config"

const models = {
  app: {
    id: primaryKey(String),
    user: {
      tosAccepted: Boolean,
    },
  },
  student: studentModel,
  user: userModel,
  message: messageModel,
  group: groupModel,
  grades_type: groupGradesTypeModel,
  grades_column: gradesColumnModel,
  grade: gradeModel,
  teacher: teacherModel,
  class_: classModel,
  attendance,
}

export const database = factory(models)
export const DATE_FORMAT = "YYYY-MM-DD"
export type Model = keyof typeof models

export const loadData = () => {
  if (window.localStorage.getItem("msw-db"))
    return Object.assign(JSON.parse(window.localStorage.getItem("msw-db") ?? "{}"))
  else return generateData()
}

export const persistDb = () => {
  if (DEBUG) return
  const data = loadData()
  for (const model of Object.keys(models)) {
    // @ts-ignore
    data[model] = database[model].getAll()
  }

  window.localStorage.setItem("msw-db", JSON.stringify(data))
}

export const initializeDb = () => {
  const data = loadData()

  Object.entries(database).forEach(([key, model]) => {
    const dataEntries = data[key]
    if (dataEntries) {
      dataEntries?.forEach((entry: Record<string, any>) => {
        model.create(entry)
      })
    }
  })

  persistDb()
}

export const resetDb = () => {
  window.localStorage.removeItem("msw-db")
}
