import { useTranslation } from "react-i18next"
import { useCallback } from "react"
import { fileToBase64 } from "@/utils/fileToBase64"
import { useDropzone } from "react-dropzone"
import { Box, Typography } from "@mui/material"

const IMAGE_ACCEPTED_TYPES = {
  "image/jpeg": [],
  "image/png": [],
  "image/gif": [],
}

export const ProfileAvatarDropzone = ({ setImage }: { setImage: (image: string) => void }) => {
  const { t } = useTranslation()
  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      if (acceptedFiles.length > 0) {
        const file = acceptedFiles[0]
        // debugger
        setImage(await fileToBase64(file))
      }
    },
    [setImage]
  )
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    multiple: false,
    onDrop,
    accept: IMAGE_ACCEPTED_TYPES,
  })

  return (
    <Box {...getRootProps()} border="4px dashed" borderColor="subtle.main" width="100%" p={2}>
      <input {...getInputProps()} />
      <Typography color={"subtle.main"} textAlign="center">
        {isDragActive ? t("profile.dragHere") : t("profile.dragOrClickHere")}
      </Typography>
    </Box>
  )
}
