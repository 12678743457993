import { MenuItemActionPropsT, MenuItemLinkPropsT, MenuItemT, MenuItemTypesT } from "@/types/menu"
import { Link, NavLink } from "react-router-dom"
import {
  BoxProps,
  Chip,
  Divider,
  IconButton,
  IconButtonProps,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem as MUIMenuItem,
} from "@mui/material"
import React, { FunctionComponent, ReactElement } from "react"
import { useTranslation } from "react-i18next"
import { useToggle } from "react-use"
import { ChangeLanguageModal } from "@/components/Elements/LanguageSelector/ChangeLanguage"
import { EllipsisVerticalSolidIcon, LanguageDuotoneIcon } from "../Icons"

function isHomepageLink(to: string) {
  return to === "/"
}

function MenuItemCommon({
  label,
  icon,
  count,
  selected = false,
  onClick = () => {},
}: Omit<MenuItemLinkPropsT, "to"> & {
  selected?: boolean
  onClick?: () => void
}) {
  const { t } = useTranslation()
  const Icon = icon

  return (
    <MUIMenuItem onClick={onClick} selected={selected}>
      {Icon && (
        <ListItemIcon>
          <Icon color={selected ? "primary" : undefined} />
        </ListItemIcon>
      )}
      <ListItemText>{t(label)}</ListItemText>
      {count && <Chip size="small" label={count} color="primary" />}
    </MUIMenuItem>
  )
}

function MenuItemNav({ label, to, icon, count }: MenuItemLinkPropsT) {
  return (
    <NavLink to={to} end={isHomepageLink(to)} style={{ textDecoration: "inherit", color: "inherit" }}>
      {({ isActive }) => <MenuItemCommon label={label} icon={icon} selected={isActive} count={count} />}
    </NavLink>
  )
}

function MenuItemLink({ label, to, icon }: MenuItemLinkPropsT) {
  return (
    <Link to={to} style={{ textDecoration: "inherit", color: "inherit" }}>
      <MenuItemCommon label={label} icon={icon} />
    </Link>
  )
}

function MenuItemAction({ label, onClick, icon }: MenuItemActionPropsT) {
  return <MenuItemCommon onClick={onClick} label={label} icon={icon} />
}

function MenuLanguage() {
  const [modalOpen, toggleModalOpen] = useToggle(false)

  return (
    <>
      <MenuItemCommon
        onClick={() => {
          toggleModalOpen()
        }}
        // t("common.changeLanguage")
        label="common.changeLanguage"
        icon={LanguageDuotoneIcon}
      />
      <ChangeLanguageModal open={modalOpen} onClose={toggleModalOpen} />
    </>
  )
}

function MenuItemSeparator() {
  return <Divider />
}

type MenuItemComponentsT = {
  [key in MenuItemTypesT]: FunctionComponent<any>
}

const MENU_ITEM_COMPONENTS: MenuItemComponentsT = {
  nav: MenuItemNav,
  link: MenuItemLink,
  action: MenuItemAction,
  separator: MenuItemSeparator,
  language: MenuLanguage,
}

export function MenuItem({ item }: { item: MenuItemT } & BoxProps) {
  const { type, ...params } = item
  const Component = MENU_ITEM_COMPONENTS[type]
  return <Component {...params} />
}

export const MenuIcon = ({
  icon = <EllipsisVerticalSolidIcon />,
  items,
  ...props
}: {
  icon?: ReactElement
  items: MenuItemT[]
} & IconButtonProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }
  return (
    <>
      <IconButton onClick={handleMenu} {...props}>
        {icon}
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={!!anchorEl}
        onClose={handleClose}
        onBlur={handleClose}
      >
        <MenuItems items={items} />
      </Menu>
    </>
  )
}

export function MenuItems({ items }: { items: MenuItemT[] }) {
  return (
    <>
      {items.map((item, index) => {
        return <MenuItem key={index} item={item} />
      })}
    </>
  )
}
