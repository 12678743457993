const storagePrefix = "teacher_"

type storageKeyT = "access_token" | "refresh_token" | "instance" | "firebase_token"
let token: string | null = null

function getItem(name: storageKeyT) {
  return window.localStorage.getItem(`${storagePrefix}${name}`)
}

function setItem(name: storageKeyT, value: string) {
  return window.localStorage.setItem(`${storagePrefix}${name}`, value)
}

function removeItem(name: storageKeyT) {
  window.localStorage.removeItem(`${storagePrefix}${name}`)
}

const storage = {
  getAccessToken: () => {
    if (!token) {
      token = getItem("access_token")
    }
    return token
  },
  setAccessToken: (newToken: string) => {
    token = newToken
    setItem("access_token", newToken)
  },
  removeAccessToken: () => {
    token = null
    removeItem("access_token")
  },
  getRefreshToken: () => {
    return getItem("refresh_token")
  },
  setRefreshToken: (token: string) => {
    setItem("refresh_token", token)
  },
  removeRefreshToken: () => {
    removeItem("refresh_token")
  },
  getInstance: () => {
    return getItem("instance")
  },
  setInstance: (instance: string) => {
    setItem("instance", instance)
  },
  removeInstance: () => {
    removeItem("instance")
  },
  getFirebaseToken: () => {
    return getItem("firebase_token")
  },
  setFirebaseToken: (instance: string) => {
    setItem("firebase_token", instance)
  },
  removeFirebaseToken: () => {
    removeItem("firebase_token")
  },
}

export default storage
