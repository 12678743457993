import { Box, Stack } from "@mui/material"
import { AvatarImages } from "@/images/avatars/avatars"

export const ProfileAvatarChooser = ({ choose }: { choose: (image: string) => void }) => {
  return (
    <Stack direction="row" flexWrap="wrap">
      {Object.values(AvatarImages).map((avatar, index) => (
        <Box
          key={index}
          display="flex"
          justifyContent="center"
          alignItems="center"
          width={{ mobile: "33.33%", tablet: "16.66%" }}
          p={0.5}
          onClick={() => choose(avatar)}
          sx={{
            cursor: "pointer",
          }}
        >
          <img
            src={avatar}
            alt="avatar"
            style={{
              width: "100%",
              height: "100%",
            }}
          />
        </Box>
      ))}
    </Stack>
  )
}
