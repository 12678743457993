import { nullable, primaryKey } from "@mswjs/data"
import dayjs from "dayjs"
import { faker } from "@faker-js/faker/locale/pl"
import { TeacherModelT } from "@/tests/server/db/models/teacherModel"
import { DATE_FORMAT } from "@/tests/server/db"
import { memoizeUnique } from "@/tests/utils/memoizeUnique"
import { RawClassT } from "@/features/classes/types/classes-queries"
import { DATE_TIME_FORMAT } from "@/types/data"
import { RawGroupT } from "@/features/groups/types/queries"
import { getNextId } from "@/tests/utils/getNextId"

export type ClassModelT = {
  id: number
  startDate: string | null
  endDate: string | null
  startTime: string | null
  endTime: string | null
  groupId: number
  data: string | RawClassT
}

export const classModel: Record<keyof ClassModelT, any> = {
  id: primaryKey(Number),
  groupId: Number,
  startDate: nullable(String),
  endDate: nullable(String),
  startTime: nullable(String),
  endTime: nullable(String),
  data: String,
}

const ROOMS = ["Berlin", "Lisbon", "London", "Paris", "Rome", "Tokyo"]
const BETWEEN_DATES: { from: Date; to: Date } = {
  from: dayjs().add(-180, "days").toDate(),
  to: dayjs().add(180, "days").toDate(),
}
export const ABSENCE_STATUSES = [
  [
    ["Nieobecność", "error"],
    ["Spóźnienie", "primary"],
    ["Usprawiedliwione", "success"],
  ],
  [
    ["Nieobecność", "error"],
    ["Spóźnienie", "primary"],
  ],
  [["Nieobecność", "error"]],
]

function getDateTime() {
  const day = dayjs(faker.date.between(BETWEEN_DATES)).format(DATE_FORMAT)
  const hour = faker.helpers.arrayElement(["08", "09", "10", "11", "12", "13", "14", "15", "16", "17", "18"])

  return `${day}###${hour}`
}

export const classGenerator = ({ groups, teachers }: { groups: RawGroupT[]; teachers: TeacherModelT[] }): RawClassT => {
  const group = faker.helpers.arrayElement(groups)
  const name = (group.name ?? "").split(" ")[0]
  const length = faker.helpers.arrayElement([30, 45, 60])
  const minutes = faker.helpers.arrayElement(length === 60 ? ["00"] : length === 45 ? ["00", "15"] : ["00", "15", "30"])
  const dateTime = memoizeUnique(getDateTime)().split("###")

  const startDate = `${dateTime[0]} ${dateTime[1]}:${minutes}:00`
  const endDate = dayjs(startDate).add(length, "minutes").format(DATE_TIME_FORMAT)
  const id = getNextId("class_")

  return {
    id,
    name,
    schoolClass: {
      id: group.id,
      name: group.name,
      schoolId: 1, // FIXME: SchoolId in generator
    },
    classroom: { name: faker.helpers.arrayElement(ROOMS) },
    startDate,
    endDate,
    breakLength: 0,
    length,
    newLessonTeachers: [{ id: 1, user: { fullname: faker.helpers.arrayElement(teachers).name } }],
    attendanceChecked: faker.datatype.boolean(),
    homeworkChecked: faker.datatype.boolean(),
    lessonObjectAddedAt: faker.datatype.boolean() ? dayjs().format(DATE_TIME_FORMAT) : null,
    isFileAdded: faker.datatype.boolean(),
    isTestAdded: faker.datatype.boolean(),
    isOnline: faker.datatype.boolean(),
    isVisible: faker.datatype.boolean(),
    lessonObjects: [],
    lessonStatus: null,
  }
}
