import React, { ReactNode } from "react"
import { baseOptions, baseTheme, shadows } from "./base"
import { deepmerge } from "@mui/utils"
import { createTheme, ThemeProvider } from "@mui/material"
import { ThemeOptions } from "@mui/material/styles/createTheme"
import { useTranslation } from "react-i18next"
import { enUS, plPL } from "@mui/material/locale"
import { enUS as enUSDate, plPL as plPLDate } from "@mui/x-date-pickers"
import merge from "lodash/merge"
import { blueGrey } from "@mui/material/colors"
import { THEME_ELEMENT_COLORS } from "@/theme/element-colors"
import "./theme.d"
import { components } from "./components"
import { typography } from "./typography"

const additionalOptions: ThemeOptions = {
  breakpoints: {
    values: {
      ...baseTheme.breakpoints.values,
      mobile: 0,
      tablet: baseTheme.breakpoints.values.md,
      desktop: baseTheme.breakpoints.values.lg,
    },
  },
  typography,
  palette: {
    grey: blueGrey,
    neutral: {
      main: blueGrey[700],
      contrastText: "#fff",
    },
    subtle: {
      light: blueGrey[400],
      main: blueGrey[400],
      contrastText: "#fff",
    },
    background: {
      default: baseTheme.palette.grey[100],
    },
    text: {
      primary: "#37474f",
    },
    elements: THEME_ELEMENT_COLORS,
  },
  shadows: shadows,
  components: components,
}

const locales: any = {
  pl_PL: merge(plPL, plPLDate),
  en_GB: merge(enUS, enUSDate),
}

export const theme = createTheme(deepmerge(baseOptions, additionalOptions))

export function AppThemeProvider({ children }: { children: ReactNode }) {
  const { i18n } = useTranslation()
  const themeWithLocale = React.useMemo(() => createTheme(theme, locales[i18n.language]), [i18n.language])
  return <ThemeProvider theme={themeWithLocale}>{children}</ThemeProvider>
}
