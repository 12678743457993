import { toBoolean, toDate } from "@/utils/requestDataConverters"
import { GroupT } from "@/features/groups/types/group"
import { RawGroupT } from "@/features/groups/types/queries"
import { transformStudent } from "@/features/students/api/transformers/transformStudent"

export const transformRawGroup = (rawGroup: RawGroupT): GroupT => ({
  id: rawGroup.id,
  name: rawGroup.name || "",
  startDate: toDate(rawGroup.startDate)?.toISOString() || "",
  endDate: toDate(rawGroup.endDate)?.toISOString() || "",
  schoolId: rawGroup.schoolId,
  gradesType: rawGroup.pointSystemTypeName,
  isHomeworkEnabled: toBoolean(rawGroup.isHomeworkEnabled),
  isLessonDetailsPublishingEnabled: toBoolean(rawGroup.isLessonDetailsPublishingEnabled),
  isPlanned: toBoolean(rawGroup.isPlanned),
  students: rawGroup.students?.map(transformStudent) ?? [],
})
